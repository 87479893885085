import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import { StorageEnums } from "../../enums/storageEnums";
import { ApiService } from "../../services/apiServices";
import { StorageService } from "../../services/storage.service";
import { handleError } from "../../utils/utils";
import { confirmPasswordSchema } from "./confirmPassword.schema";

const ConfirmEmail = () => {
  const { email } = useParams();
  const [loading, setLoading] = useState<boolean>(false);
  const [sendingOtp, setSendingOtp] = useState(false);
  const apiService = new ApiService();
  const storageService = new StorageService();
  const methods = useForm({
    resolver: yupResolver(confirmPasswordSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = methods;
  const navigate = useNavigate();
  const handleConfirmEmail = async (data: FieldValues) => {
    try {
      setLoading(true);
      let obj = {
        params: { email: email, otp: data.confirmationCode },
        url: "auth/verify-account",
      };
      let res = await apiService.post(obj);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
      navigate(`/dashboard`);
    } catch (e: any) {
      toast.error(handleError(e));
    } finally {
      setLoading(false);
    }
  };
  const resendOtp = async () => {
    try {
      if (sendingOtp) {
        return;
      }
      setSendingOtp(true);
      let obj = {
        data: { email: email },
        url: "auth/request-verification-otp",
      };
      await apiService.post(obj);
      toast.success("Confirmation code sent to your email.");
    } catch (e: any) {
      toast.error(e.response.data.message[0]);
    } finally {
      setSendingOtp(false);
    }
  };
  return (
    <div className={style.auth}>
      <div className={style.auth__wrap}>
        <h1>Confirm your email</h1>
        <p>
          We've just sent a verification code to <strong>{email}</strong>.
          Please enter the code below to complete the confirmation process.
        </p>
        <form onSubmit={handleSubmit(handleConfirmEmail)} noValidate>
          <Input
            type="number"
            label="Confirmation Code"
            placeholder="Confirmation Code"
            register={register}
            registerName="confirmationCode"
            errorMessage={errors?.confirmationCode?.message}
          />

          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            buttonClass="isFull"
            label={"Submit"}
          />
        </form>
        <div className={style.auth__infoText}>
          <a onClick={() => navigate("/login")}>Cancel</a>
        </div>
        <div className={style.auth__infoText}>
          <a onClick={resendOtp}>Resend confirmation code</a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmEmail;
