import { ProjectUserRoleEnum } from "../../enums/storageEnums";
import { DropDownOption } from "../../interfaces/interface";

export const registryList: DropDownOption[] = [
  { label: "Verra", value: "Verra" },
  { label: "Gold Standard", value: "Gold Standard", disabled: true },
  {
    label: "Climate Action Reserve",
    value: "Climate Action Reserve",
    disabled: true,
  },
  {
    label: "American Carbon Registry",
    value: "American Carbon Registry",
    disabled: true,
  },
  { label: "Puro.earth", value: "Puro.earth" , disabled: true },
];
export const methodologyList: DropDownOption[] = [
  { label: "ACM0001", value: "ACM0001" },
  { label: "ACM0002", value: "ACM0002" },
  { label: "ACM0003", value: "ACM0003" },
  { label: "ACM0006", value: "ACM0006" },
  { label: "ACM0008", value: "ACM0008" },
  { label: "ACM0009", value: "ACM0009" },
  { label: "ACM0010", value: "ACM0010" },
  { label: "ACM0012", value: "ACM0012" },
  { label: "ACM0014", value: "ACM0014" },
  { label: "ACM0017", value: "ACM0017" },
  { label: "ACM0022", value: "ACM0022" },
  { label: "AM0009", value: "AM0009" },
  { label: "AM0010", value: "AM0010" },
  { label: "AM0014", value: "AM0014" },
  { label: "AM0023", value: "AM0023" },
  { label: "AM0028", value: "AM0028" },
  { label: "AM0034", value: "AM0034" },
  { label: "AM0057", value: "AM0057" },
  { label: "AM0072", value: "AM0072" },
  { label: "AM0073", value: "AM0073" },
  { label: "AM0080", value: "AM0080" },
  { label: "AM0090", value: "AM0090" },
  { label: "AM0110", value: "AM0110" },
  { label: "AMS-I.A.", value: "AMS-I.A." },
  { label: "AMS-I.B.", value: "AMS-I.B." },
  { label: "AMS-I.C.", value: "AMS-I.C." },
  { label: "AMS-I.D.", value: "AMS-I.D." },
  { label: "AMS-I.E.", value: "AMS-I.E." },
  { label: "AMS-I.F.", value: "AMS-I.F." },
  { label: "AMS-I.J", value: "AMS-I.J" },
  { label: "AMS-I.L.", value: "AMS-I.L." },
  { label: "AMS-II.C.", value: "AMS-II.C." },
  { label: "AMS-II.E.", value: "AMS-II.E." },
  { label: "AMS-II.F.", value: "AMS-II.F." },
  { label: "AMS-II.G.", value: "AMS-II.G." },
  { label: "AMS-II.L.", value: "AMS-II.L." },
  { label: "AMS-II.S.", value: "AMS-II.S." },
  { label: "AMS-III.AF.", value: "AMS-III.AF." },
  { label: "AMS-III.AJ.", value: "AMS-III.AJ." },
  { label: "AMS-III.AO.", value: "AMS-III.AO." },
  { label: "AMS-III.AQ", value: "AMS-III.AQ" },
  { label: "AMS-III.AR", value: "AMS-III.AR" },
  { label: "AMS-III.AU", value: "AMS-III.AU" },
  { label: "AMS-III.AV.", value: "AMS-III.AV." },
  { label: "AMS-III.B.", value: "AMS-III.B." },
  { label: "AMS-III.BA", value: "AMS-III.BA" },
  { label: "AMS-III.BG", value: "AMS-III.BG" },
  { label: "AMS-III.BM", value: "AMS-III.BM" },
  { label: "AMS-III.C.", value: "AMS-III.C." },
  { label: "AMS-III.D.", value: "AMS-III.D." },
  { label: "AMS-III.E.", value: "AMS-III.E." },
  { label: "AMS-III.F.", value: "AMS-III.F." },
  { label: "AMS-III.G.", value: "AMS-III.G." },
  { label: "AMS-III.H.", value: "AMS-III.H." },
  { label: "AMS-III.J.", value: "AMS-III.J." },
  { label: "AMS-III.L.", value: "AMS-III.L." },
  { label: "AMS-III.O.", value: "AMS-III.O." },
  { label: "AMS-III.Q", value: "AMS-III.Q" },
  { label: "AMS-III.R.", value: "AMS-III.R." },
  { label: "AMS-III.S.", value: "AMS-III.S." },
  { label: "AMS-III.Y", value: "AMS-III.Y" },
  { label: "AMS-III.Z", value: "AMS-III.Z" },
  { label: "AR-ACM0001", value: "AR-ACM0001" },
  { label: "AR-ACM0003", value: "AR-ACM0003" },
  { label: "AR-AM0003", value: "AR-AM0003" },
  { label: "AR-AM0014", value: "AR-AM0014" },
  { label: "AR-AMS0003", value: "AR-AMS0003" },
  { label: "AR-AMS0007", value: "AR-AMS0007" },
  { label: "VM0003", value: "VM0003" },
  { label: "VM0005", value: "VM0005" },
  { label: "VM0006", value: "VM0006" },
  { label: "VM0007", value: "VM0007" },
  { label: "VM0008", value: "VM0008" },
  { label: "VM0009", value: "VM0009" },
  { label: "VM0010", value: "VM0010" },
  { label: "VM0012", value: "VM0012" },
  { label: "VM0015", value: "VM0015" },
  { label: "VM0016", value: "VM0016" },
  { label: "VM0017", value: "VM0017" },
  { label: "VM0018", value: "VM0018" },
  { label: "VM0021", value: "VM0021" },
  { label: "VM0022", value: "VM0022" },
  { label: "VM0026", value: "VM0026" },
  { label: "VM0032", value: "VM0032" },
  { label: "VM0033", value: "VM0033" },
  { label: "VM0034", value: "VM0034" },
  { label: "VM0036", value: "VM0036" },
  { label: "VM0037", value: "VM0037" },
  { label: "VM0038", value: "VM0038" },
  { label: "VM0039", value: "VM0039" },
  { label: "VM0041", value: "VM0041" },
  { label: "VM0042", value: "VM0042" },
  { label: "VM0043", value: "VM0043" },
  { label: "VM0044", value: "VM0044" },
  { label: "VM0045", value: "VM0045" },
  { label: "VM0046", value: "VM0046" },
  { label: "VM0047", value: "VM0047" },
  { label: "VM0048", value: "VM0048" },
  { label: "VMR0003", value: "VMR0003" },
  { label: "VMR0006", value: "VMR0006" },
];
export const templateList: DropDownOption[] = [
  {
    label: "VCS Project Description Template, v4.3",
    value: "VCS Project Description Template, v4.3",
  },
];
export const projectUserRolesList: DropDownOption[] = [
  { label: "Editor", value: ProjectUserRoleEnum.EDITOR },
  { label: "Viewer", value: ProjectUserRoleEnum.VIEWER },
];
export const projectUserRolesListWithRemove: DropDownOption[] = [
  { label: "Editor", value: ProjectUserRoleEnum.EDITOR },
  { label: "Viewer", value: ProjectUserRoleEnum.VIEWER },
  { label: "Remove", value: ProjectUserRoleEnum.Remove },
];
export const avtivitiesList = [
  {
    value: "Project Developer",
    label: "Project Developer",
  },
];
