import { Editor } from "@tiptap/react";
import { useCallback } from "react";
import BoldIcon from "../../../../assets/images/bold.svg";
import BulletListIcon from "../../../../assets/images/bullet-list.svg";
import ItalicIcon from "../../../../assets/images/italic.svg";
import LinkIcon from "../../../../assets/images/link.svg";
import NumberListIcon from "../../../../assets/images/number-list.svg";
import StrikethroughIcon from "../../../../assets/images/strikethrough.svg";
import UnderlineIcon from "../../../../assets/images/underline.svg";
import editorStyle from "../../../../assets/scss/editorMenu.module.scss";
import ChangeTextType from "./ChangeTextType";
import EditorButton from "./EditorButton";

export interface EditorMenuProps {
  editor: Editor | null;
  setShowLinkModal: React.Dispatch<React.SetStateAction<boolean>>;
}
const EditorMenu = ({ editor, setShowLinkModal }: EditorMenuProps) => {
  const onLinkClick = useCallback(() => {
    editor?.commands.focus();
    setTimeout(() => {
      setShowLinkModal((prev: boolean) => !prev);
    }, 50);
  }, [editor?.commands, setShowLinkModal]);

  if (!editor) {
    return null;
  }

  return (
    <div className={`${editorStyle.editorMenu} flex-wrap`}>
      <ChangeTextType editor={editor} />

      <div className={`${editorStyle.editorMenu__group} flex`}>
        <EditorButton
          onClick={() => editor.chain().focus().toggleItalic().run()}
          disabled={!editor.can().chain().focus().toggleItalic().run()}
          isActive={editor.isActive("italic")}
          icon={ItalicIcon}
          name={"Italic"}
          shortCutMac={"⌘+I"}
          shortCutWindows={"Ctrl+I"}
        />

        <EditorButton
          onClick={() => editor.chain().focus().toggleBold().run()}
          disabled={!editor.can().chain().focus().toggleBold().run()}
          isActive={editor.isActive("bold")}
          icon={BoldIcon}
          name={"Bold"}
          shortCutMac={"⌘+B"}
          shortCutWindows={"Ctrl+B"}
        />

        <EditorButton
          onClick={() => editor.chain().focus().toggleUnderline().run()}
          disabled={!editor.can().chain().focus().toggleUnderline().run()}
          isActive={editor.isActive("underline")}
          icon={UnderlineIcon}
          name={"Underline"}
          shortCutMac={"⌘+U"}
          shortCutWindows={"Ctrl+U"}
        />

        <EditorButton
          onClick={() => editor.chain().focus().toggleStrike().run()}
          disabled={!editor.can().chain().focus().toggleStrike().run()}
          isActive={editor.isActive("strike")}
          icon={StrikethroughIcon}
          name={"Strike through"}
          shortCutMac={"⌘+Shift+X"}
          shortCutWindows={"Ctrl+Shift+X"}
        />
      </div>
      <div className={`${editorStyle.editorMenu__group} flex`}>
        <EditorButton
          onClick={() => editor.chain().focus().toggleOrderedList().run()}
          disabled={false}
          isActive={editor.isActive("orderedList")}
          icon={NumberListIcon}
        />

        <EditorButton
          onClick={() => editor.chain().focus().toggleBulletList().run()}
          disabled={false}
          isActive={editor.isActive("bulletList")}
          icon={BulletListIcon}
        />
      </div>

      <div className={`${editorStyle.editorMenu__group} flex`}>
        <EditorButton
          onClick={onLinkClick}
          disabled={false}
          isActive={true}
          icon={LinkIcon}
          name={"Link"}
          shortCutMac={"⌘+K"}
          shortCutWindows={"Ctrl+K"}
        />
      </div>
    </div>
  );
};
export default EditorMenu;
