import { NodeViewContent, NodeViewWrapper } from "@tiptap/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import "../../../../assets/scss/tiptap.scss";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";
import { getProjectById } from "../../../../store/projectSlice";
import AddMenu from "../../Components/AddMenu/AddMenu";
import DragMenu from "../../Components/DragMenu/DragMenu";
import { MODULES } from "../../editor.constants";
import { ModuleType } from "../../editor.enum";
import style from "./CustomNodeView.module.scss";
import ModuleInstructionBox from "./ModuleInstructionBox";

export const CustomNodeView = ({ node, getPos, editor, ...props }: any) => {
  const { id } = useParams();
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const createTextBlock = () => {
    const pos = getPos() + node.nodeSize;
    editor?.commands.insertContentAt(pos, MODULES[ModuleType.TEXT]);
    editor?.commands.focus(pos + 2);
  };

  const createTableBlock = () => {
    const pos = getPos() + node.nodeSize;
    editor?.commands.insertContentAt(pos, MODULES[ModuleType.TABLE]);
  };

  const createTaskList = () => {
    const pos = getPos() + node.nodeSize;
    editor?.commands.insertContentAt(pos, MODULES[ModuleType.SURVEY]);
    editor?.commands.focus(pos + 2);
  };

  const duplicateBlock = () => {
    const pos = getPos() + node.nodeSize;
    editor?.commands.insertContentAt(pos, {
      type: "customBlock",
      attrs: { ...node.attrs },
      content: node?.content?.toJSON() ?? [],
    });
    editor?.commands.focus(pos + 2);
  };
  const [showOption, setShowOption] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const addImageModalHandler = (state: boolean) => {
    setShowMenu(false);
    const pos = getPos() + node.nodeSize;
    const imageModule: any = { ...MODULES[ModuleType.IMAGE] };
    editor?.commands.insertContentAt(pos, imageModule);
  };

  const dragClick = () => {
    if (!editor?.state.selection.empty) {
      editor?.commands.setTextSelection(getPos());
    }
  };
  const calculateActiveSateOfNode = () => {
    const { from, to } = editor.state.selection;

    const nodeFrom = getPos();
    const nodeTo = nodeFrom + node.nodeSize;
    setIsActive(nodeFrom <= from && to <= nodeTo);
  };

  useEffect(() => {
    editor.on("selectionUpdate", calculateActiveSateOfNode);

    setTimeout(calculateActiveSateOfNode, 100);

    return () => {
      editor.off("selectionUpdate", calculateActiveSateOfNode);
    };
  });

  return (
    <NodeViewWrapper
      as="div"
      className={`${style.customNodeView} ${isActive ? "active-node" : ""}`}
      contentEditable={
        showOption || showMenu || project.role !== ProjectUserRoleEnum.VIEWER
      }
      suppressContentEditableWarning={true}
    >
      {node.attrs.prefixInstruction && (
        <ModuleInstructionBox instruction={node.attrs.prefixInstruction} />
      )}

      <div className={style.customNodeView__content} draggable={false}>
        {project.role !== ProjectUserRoleEnum.VIEWER && (
          <button
            className={`${style.customNodeView__action} ${
              showMenu || showOption ? style.active : ""
            } flex align-center`}
            contentEditable={false}
          >
            <AddMenu
              showMenu={showMenu}
              setShowMenu={setShowMenu}
              createTextBlock={createTextBlock}
              createTableBlock={createTableBlock}
              createTaskList={createTaskList}
              addImageModalHandler={addImageModalHandler}
            />

            <DragMenu
              showOption={showOption}
              setShowOption={setShowOption}
              duplicateBlock={duplicateBlock}
              deleteBlock={props.deleteNode}
              handleClick={dragClick}
            />
          </button>
        )}
        <NodeViewContent />
      </div>
      {node.attrs.postfixInstruction && (
        <ModuleInstructionBox instruction={node.attrs.postfixInstruction} />
      )}
    </NodeViewWrapper>
  );
};
