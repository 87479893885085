export enum ChatModelEnum {
  USER = "user",
  SYSTEM = "system",
}
export class ChatModel {
  createdAt: string = "";
  id: string = "";
  text: string = "";
  thread: string = "";
  type: string = "";
  userId: string | null = "";
  isVisible: boolean = false;

  constructor(data: any) {
    this.createdAt = data.createdAt;
    this.id = data.id;
    this.text = data.text;
    this.thread = data.thread;
    this.type = data.type;
    this.userId = data.userId;
    this.isVisible = data.isVisible;
  }
}
