import { useState } from "react";
import AddIcon from "../../../../assets/images/add.svg";
import GalleryAddIcon from "../../../../assets/images/gallery-add.svg";
import TableIcon from "../../../../assets/images/table.svg";
import TextalignIcon from "../../../../assets/images/textalign.svg";
import TickSquareIcon from "../../../../assets/images/tick-square.svg";
import DropdownMenu from "../../../../atoms/DropdownMenu/DropdownMenu";
import style from "../../../../assets/scss/actionMenu.module.scss";

export interface AddMenuProps {
  createTextBlock: () => void;
  createTableBlock: () => void;
  createTaskList: () => void;
  showMenu: any;
  setShowMenu: any;
  addImageModalHandler: any;
}
const AddMenu = ({
  createTextBlock,
  createTableBlock,
  createTaskList,
  showMenu,
  setShowMenu,
  addImageModalHandler,
}: AddMenuProps) => {
  return (
    <>
      <div
        className={style.actionMenu__add}
        onClick={() => setShowMenu(!showMenu)}
      >
        <img src={AddIcon} alt="Add" />
        {!showMenu && (
          <div className={`${style.actionMenu__info} ${style.isAdda}`}>
            <p>
              <strong>Click</strong>
              <span>to add below</span>
            </p>
            {/* <p>
              <strong>Option-click</strong>
              <span>to add above</span>
            </p> */}
          </div>
        )}
      </div>

      {showMenu && (
        <DropdownMenu onClose={() => setShowMenu(false)}>
          <div
            className={style.actionMenu__dropwdown}
            onClick={() => setShowMenu(false)}
            suppressContentEditableWarning={true}
          >
            {/* ADD TEXT BLOCK */}
            <button type="button" onClick={createTextBlock}>
              <span>
                <img src={TextalignIcon} alt="-" />
              </span>
              <aside>
                <strong>Text</strong>
                <p>Add a text block</p>
              </aside>
            </button>

            {/* Add Table */}
            <button type="button" onClick={createTableBlock}>
              <span>
                <img src={TableIcon} alt="Table" />
              </span>
              <aside>
                <strong>Table</strong>
                <p>Organize data in a table</p>
              </aside>
            </button>

            {/* ADD Image */}
            <button type="button" onClick={() => addImageModalHandler(true)}>
              <span>
                <img src={GalleryAddIcon} alt="Add media" />
              </span>
              <aside>
                <strong>Image Upload</strong>
                <p>Upload or add with a link</p>
              </aside>
            </button>

            {/* Add questionnaire */}
            <button type="button" onClick={createTaskList}>
              <span>
                <img src={TickSquareIcon} alt="Tick" />
              </span>
              <aside>
                <strong>Questionnaire</strong>
                <p>Restore default questionnaires</p>
              </aside>
            </button>
          </div>
        </DropdownMenu>
      )}
    </>
  );
};
export default AddMenu;
