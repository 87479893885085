import { useCallback, useEffect, useMemo, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import Button from "../../atoms/Button/Button";
import { ApiService } from "../../services/apiServices";
import CustomModal from "../CustomModal/CustomModal";
import SkeletonLoader from "../Skeleton/Skeleton";
import Table, { ActionType, TableDataType } from "../Table/Table";
import TableLoader from "../Table/TableLoader";
import style from "./Projects.module.scss";
import { PROJECTS_HEADINGS } from "./projects.constants";

const Projects: React.FC<any> = ({ modalShow, setModalShow, getProjects }) => {
  const [loading, setLoading] = useState<boolean>(false);

  const apiService = new ApiService();
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const [projectId, setProjectId] = useState<string | number>(-1);
  const [projectData, setProjectData] = useState<{
    projects: Array<any>;
    count: number;
    page: number;
  }>({ projects: [], count: 0, page: 1 });
  const navigate = useNavigate();

  async function fetchProjects() {
    try {
      if (projectData.projects.length === 0) setLoading(true);
      let obj = {
        url: `projects?page=${projectData.page}&limit=20`,
      };
      let res = await apiService.get(obj);
      setProjectData((prevData) => ({
        projects: [...prevData.projects, ...res?.data.projects],
        count: res?.data.count,
        page: prevData.page + 1,
      }));
    } catch (e: any) {
      console.log(
        "error in fetch fetch projects",
        e?.response?.message?.data ?? e?.message
      );
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  }

  async function removeProjects(data: any) {
    setConfirmationModalVisible(true);
    setProjectId(data?.id);
  }
  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };
  const handleConfirmDelete = async () => {
    setConfirmationModalVisible(false);
    try {
      setLoading(true);
      let obj = {
        url: `projects/${projectId}`,
      };
      await apiService.delete(obj);
      setProjectData({
        projects: [],
        count: 0,
        page: 1,
      });
    } catch (e: any) {
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };
  const handleProjectDetails = useCallback(
    (data: any) => {
      navigate(`/dashboard/details/${data.id}`);
    },
    [navigate]
  );
  const projectHeading = useMemo(() => {
    return PROJECTS_HEADINGS.map((el) => {
      if (el.type === TableDataType.ACTIONS) {
        el?.actionType?.forEach((e) => {
          if (e.name === ActionType.DELETE) {
            e.onClick = removeProjects;
          }
        });
      } else {
        el.onClick = handleProjectDetails;
      }
      return el;
    });
  }, [handleProjectDetails]);

  useEffect(() => {
    if (projectData.page === 1 && projectData.projects.length === 0) {
      fetchProjects();
    }
  }, [projectData.page, projectData.count, projectData.projects.length]);
  useEffect(() => {
    if (getProjects)
      setProjectData({
        projects: [],
        count: 0,
        page: 1,
      });
  }, [getProjects]);
  return (
    <div className={style.projects}>
      {loading ? (
        <TableLoader
          rows={10}
          headings={projectHeading}
          columns={projectHeading.length}
        />
      ) : (
        <InfiniteScroll
          dataLength={projectData?.projects.length}
          next={fetchProjects}
          hasMore={projectData?.projects.length < projectData?.count}
          loader={<SkeletonLoader />}
        >
          <Table
            headings={projectHeading}
            tableData={projectData?.projects}
            dataType="projects"
            noDataMessage={
              <>
                No projects yet. Click <strong>New Project</strong> to get
                started!
              </>
            }
            onClick={handleProjectDetails}
          />
        </InfiniteScroll>
      )}

      <CustomModal
        maxWidth="480px"
        title="Delete Project"
        modalVisible={isConfirmationModalVisible}
        onClose={handleCloseConfirmationModal}
        footerNode={
          <div className={`${style.projects__action} flex justify-center`}>
            <Button
              buttonClass="sm outline"
              onClick={handleCloseConfirmationModal}
            >
              Cancel
            </Button>
            <Button buttonClass="sm" onClick={handleConfirmDelete}>
              Confirm
            </Button>
          </div>
        }
      >
        <p>Are you sure you want to delete this Project?</p>
      </CustomModal>
    </div>
  );
};
export default Projects;
