import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CommentModel } from "../models/comment.model";

interface CommentsState {
  comments: CommentModel[];
  loading: boolean;
  error: string | null;
  count: number;
  sectionId: string;
  emitDeleteComment: boolean;
  moduleId?: string;
}

const initialState: CommentsState = {
  comments: [],
  loading: false,
  error: null,
  count: 0,
  sectionId: "",
  emitDeleteComment: false,
  moduleId: "",
};

const commentsSlice = createSlice({
  name: "comments",
  initialState,
  reducers: {
    setComments: (state, action: PayloadAction<any>) => {
      return {
        ...state,
        comments: action.payload?.comments ?? [],
        count: action.payload?.count ?? 0,
        sectionId: action.payload?.sectionId ?? "",
        moduleId: action.payload?.moduleId ?? "",
      };
    },
    addComment: (state, action: PayloadAction<any>) => {
      if (
        (state.moduleId &&
          action.payload.comment.moduleId !== state.moduleId) ||
        (state.sectionId &&
          action.payload.comment.sectionId !== state.sectionId)
      ) {
        return { ...state };
      }
      const comments = [...state.comments, action.payload.comment];
      comments.length < state.count + 1 && comments.pop();
      return {
        ...state,
        comments: comments,
        count: state.count + 1,
      };
    },
    emitDeleteComment: (state, action: PayloadAction<any>) => {
      const index = state.comments.findIndex(
        (comment) => comment.id === action.payload
      );
      return {
        ...state,
        emitDeleteComment: index > -1,
      };
    },
    updateComment: (state, action: PayloadAction<any>) => {
      const comments = [...state.comments];
      const index = comments.findIndex(
        (comment: any) => action.payload.comment?.id === comment?.id
      );
      comments[index] = action.payload.comment;
      return {
        ...state,
        comments: comments,
      };
    },
  },
});

export const { addComment, emitDeleteComment, setComments, updateComment } =
  commentsSlice.actions;
export const getCommentsState = (state: { comments: any }) =>
  state.comments.comments;
export const getCommentsCount = (state: { comments: any }) =>
  state.comments.count;
export const deleteCommentState = (state: { comments: any }) =>
  state.comments.emitDeleteComment;
export default commentsSlice.reducer;
