import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import style from "./FallbackModal.module.scss";
import { ProjectFallbackType } from "../../editor.enum";
import {
  getSectionList,
  getSocketFallBack,
  resetFallbackState,
  setSectionList,
} from "../../../../store/projectSlice";
import CustomModal from "../../../CustomModal/CustomModal";
import Button from "../../../../atoms/Button/Button";
import {
  FALLBACK_BUTTON_LABEL,
  FALLBACK_MESSAGE,
} from "../../editor.constants";
import { SectionService } from "../../../../services/section.service";
import { ProjectService } from "../../../../services/project.service";

const projectService = new ProjectService();

const FallbackModal = () => {
  const socketFallback = useSelector(getSocketFallBack);
  const [message, setMessage] = useState<string>("");
  const navigate = useNavigate();
  const { id, sectionId } = useParams();
  const dispatch = useDispatch();
  const sectionList = useSelector((state: any) =>
    getSectionList(state, id ?? "")
  );
  useEffect(() => {
    if (socketFallback.showFallback) {
      switch (socketFallback.fallbackType) {
        case ProjectFallbackType.SECTION_REMOVED: {
          if (
            socketFallback.sectionId === sectionId &&
            socketFallback.fallbackType === ProjectFallbackType.SECTION_REMOVED
          ) {
            setMessage(FALLBACK_MESSAGE[socketFallback.fallbackType]);
          } else {
            getProjectSectionList(id ?? "");
            dispatch(resetFallbackState({}))
          }
          break;
        }
        default: {
          setMessage(FALLBACK_MESSAGE[socketFallback.fallbackType]);
        }
      }
    }
  }, [socketFallback]);

  const getProjectSectionList = useCallback(
    async (projectId: string) => {
      try {
        const res = await projectService.fetchProjectSectionList(projectId);
        let list = SectionService.formatSectionList(res?.data?.sections || []);
        dispatch(setSectionList({ id: projectId ?? "", list: list }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  const handleFallBackButtonClick = () => {
    if (socketFallback) {
      switch (socketFallback.fallbackType) {
        case ProjectFallbackType.USER_ROLE_UPDATE: {
          window.location.reload();
          break;
        }
        case ProjectFallbackType.USER_REMOVED_FROM_PROJECT: {
          navigate("/dashboard/projects");
          break;
        }
        case ProjectFallbackType.SECTION_REMOVED: {
          navigate(`/dashboard/details/${id}/section/${sectionList?.[0]?.id}`);
          getProjectSectionList(id ?? "");
          break;
        }
      }
      dispatch(resetFallbackState({}));
    }
  };

  return (
    <CustomModal
      maxWidth="480px"
      title=""
      modalVisible={socketFallback.showFallback&&message.length>0}
    >
      <div className={style.socketFallback}>
        <p>{message}</p>
        <Button
          label={FALLBACK_BUTTON_LABEL[socketFallback.fallbackType]}
          buttonClass="sm"
          onClick={handleFallBackButtonClick}
        />
      </div>
    </CustomModal>
  );
};

export default FallbackModal;
