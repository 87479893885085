import { FieldValues, useForm } from "react-hook-form";
import Input from "../../../../atoms/Input/Input";
import CustomModal from "../../../CustomModal/CustomModal";
import { yupResolver } from "@hookform/resolvers/yup";
import { appendixSchema } from "./appendix.schema";
import Button from "../../../../atoms/Button/Button";
import { SectionService } from "../../../../services/section.service";
import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { TEXT_MODULE } from "../../editor.constants";
import style from "./AddEditAppendixModal.module.scss";
const sectionService = new SectionService();

const AddEditAppendixModal = ({
  onClose,
  open,
  onAddSection,
  appendixTitle,
  onEditSection,
}: {
  onClose: (id: string) => void;
  onAddSection: (id: string) => void;
  open: boolean;
  appendixTitle?: string;
  onEditSection: () => void;
}) => {
  const {
    register,
    formState: { errors ,isDirty},
    handleSubmit,
  } = useForm({
    resolver: yupResolver(appendixSchema),
    defaultValues: { name: appendixTitle ?? "" },
  });
  const { id, sectionId } = useParams();
  const [loading, setLoading] = useState(false);
  const addEditSection = async (data: FieldValues) => {
    if (!id) return;
    try {
      setLoading(true);
      if (appendixTitle) {
        const res = await sectionService.updateSectionStatus(
          id,
          sectionId ?? "",
          data
        );
        await onEditSection();
      } else {
        const payload = { ...data, template: TEXT_MODULE };
        const res = await sectionService.createAppendixSection(id, payload);
        await onAddSection(res.data.projectSection.id ?? "");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <CustomModal
      onClose={() => onClose(sectionId ?? "")}
      modalVisible={open}
      title={`${appendixTitle ? "Edit" : "Add"} Appendix Section`}
      maxWidth="500px"
      headerSeparator={true}
      footerNode={
        <Button
          label={"Submit"}
          onClick={handleSubmit(addEditSection)}
          loading={loading}
          disabled={!isDirty}
        ></Button>
      }
    >
      <div className={style.addEditAppendix}>
        <Input
          type="text"
          placeholder="Section Title"
          register={register}
          registerName="name"
          errorMessage={errors?.name?.message}
          maxlength={200}
        />
      </div>
    </CustomModal>
  );
};
export default AddEditAppendixModal;
