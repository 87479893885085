import { Editor } from "@tiptap/core";

export function isValidTextApp(text: string) {
  const regex = /^(?!\s)(?!.*\s$)(?!.{21,}$)\s*\S+(\s+\S+)*\s*$/;
  return regex.test(text);
}

export function getCookie(name: string) {
  const cookies = document.cookie.split("; ");

  for (const cookie of cookies) {
    const [cookieName, cookieValue] = cookie.split("=");
    if (cookieName === name) {
      return decodeURIComponent(cookieValue);
    }
  }

  return null;
}

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function timeConverter(dateString: string | Date) {
  const date = new Date(dateString);

  const day = String(date.getDate());
  const month = date.toLocaleString("default", { month: "long" });
  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  return `${month} ${day}, ${year} at ${hours}:${minutes}`;
}
export const getFormattedDueDate = (dueDate: string | undefined) => {
  if (!dueDate) {
    return "Select date";
  } else {
    const date = new Date(dueDate);
    const day = String(date.getDate());
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
};

export const convertTaskDueDate = (dateString: string) => {
  if (!dateString) {
    return "";
  } else {
    const date = new Date(dateString);
    const day = String(date.getDate());
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();
    return `${month} ${day}, ${year}`;
  }
};

export function handleError(error: any) {
  const errorMessage = error?.response?.data?.message;
  if (errorMessage && typeof errorMessage === "string") {
    return errorMessage;
  } else if (
    errorMessage &&
    typeof errorMessage === "object" &&
    errorMessage.length > 0
  ) {
    return errorMessage[0];
  }
  return (
    error?.response?.data?.message ?? error?.message ?? "Something went wrong"
  );
}
export function checkIsBlockEditable(editor: Editor) {
  const {
    selection: { from, to },
    doc,
  } = editor.state;
  let res = false;
  doc.descendants((node, pos) => {
    const [nodeFrom, nodeTo] = [pos, pos + node.nodeSize];
    if (nodeFrom <= from && to <= nodeTo) {
      if (node?.attrs?.contenteditable) {
        res = false;
      } else if (node?.attrs?.contenteditable === false) {
        res = true;
      }
    }
  });
  return res;
}

export const getTablecell = (data: any): any => {
  if (!data && !data?.type) {
    return undefined;
  }
  if (["tableCell", "tableHeader"].includes(data.type)) {
    return data;
  } else {
    return getTablecell(data?.content?.[0]);
  }
};