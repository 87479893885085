import { useCallback, useEffect, useRef } from "react";

export interface DropdownMenuPorps {
  children: any;
  onClose: Function;
}

const DropdownMenu = ({ children, onClose }: DropdownMenuPorps) => {
  const wrapper = useRef<any>(null);
  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const handleOutsideClick = useCallback((event: any) => {
    if (wrapper.current && !wrapper.current.contains(event.target)) {
      onClose();
    }
  }, []);

  return (
    <div ref={wrapper} onClick={(e) => e.stopPropagation()}>
      {children}
    </div>
  );
};
export default DropdownMenu;
