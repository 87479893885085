import {
  ModuleType,
  ProjectFallbackType,
  ProjectSectionStatus,
} from "./editor.enum";

export const TABLE_MODULE = {
  type: "customBlock",
  attrs: {
    childModule: ModuleType.TABLE,
  },
  content: [
    {
      type: "table",
      content: [
        {
          type: "tableRow",
          attrs: {
            selectable: false,
            contenteditable: "true",
            class: null,
          },
          content: [
            {
              type: "tableHeader",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableHeader",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableHeader",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          attrs: {
            selectable: false,
            contenteditable: "true",
            class: null,
          },
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
          ],
        },
        {
          type: "tableRow",
          attrs: {
            selectable: false,
            contenteditable: "true",
            class: null,
          },
          content: [
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
            {
              type: "tableCell",
              attrs: {
                colspan: 1,
                rowspan: 1,
                colwidth: [295],
              },
              content: [
                {
                  type: "paragraph",
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};
export const IMAGE_MODULE = {
  type: "customBlock",
  attrs: { childModule: ModuleType.IMAGE },
  content: [
    {
      type: "image",
      attrs: {
        alt: null,
        src: null,
        title: null,
      },
    },
  ],
};
export const SURVEY_MODULE = {
  type: "customBlock",
  attrs: { childModule: ModuleType.SURVEY },
  content: [
    {
      type: "taskListBlock",
      content: [
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "sample survey",
            },
          ],
        },
        {
          type: "taskList",
          content: [
            {
              type: "taskItem",
              attrs: {
                checked: false,
              },
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "option 1",
                    },
                  ],
                },
              ],
            },
            {
              type: "taskItem",
              attrs: {
                checked: false,
              },
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "option 2",
                    },
                  ],
                },
              ],
            },
            {
              type: "taskItem",
              attrs: {
                checked: false,
              },
              content: [
                {
                  type: "paragraph",
                  content: [
                    {
                      type: "text",
                      text: "option 3",
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          type: "paragraph",
          content: [
            {
              type: "text",
              text: "sample survey",
            },
          ],
        },
      ],
    },
  ],
};
export const TEXT_MODULE = {
  type: "customBlock",
  attrs: {
    childModule: ModuleType.TEXT,
  },
  content: [
    {
      type: "textBlock",
      content: [
        {
          type: "paragraph",
        },
      ],
    },
  ],
};
export const MODULES = {
  [ModuleType.TABLE]: TABLE_MODULE,
  [ModuleType.IMAGE]: IMAGE_MODULE,
  [ModuleType.SURVEY]: SURVEY_MODULE,
  [ModuleType.TEXT]: TEXT_MODULE,
};

export const SECTION_STATUS = [
  { value: ProjectSectionStatus.OPEN, label: "Open" },
  { value: ProjectSectionStatus.IN_PROGRESS, label: "In Progress" },
  { value: ProjectSectionStatus.COMPLETED, label: "Completed" },
];

export const STATUS_COLOR: any = {
  [ProjectSectionStatus.OPEN]: "180,198,199",
  [ProjectSectionStatus.IN_PROGRESS]: "179, 214, 125",
  [ProjectSectionStatus.COMPLETED]: "120,179,121",
};
export const StaticTemplate = {
  type: "doc",
  content: [
    {
      type: "paragraph",
      content: [
        {
          text: "Provide a summary description of the project to enable an understanding of the nature of the project and its implementation, including the following (no more than one page):",
          type: "text",
          marks: [{ type: "italic", attrs: {} }],
        },
      ],
    },
    {
      type: "bulletList",
      content: [
        {
          type: "listItem",
          attrs: { color: "" },
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "A summary description of the technologies/measures to be implemented by the project.",
                  type: "text",
                  marks: [{ type: "italic", attrs: {} }],
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          attrs: { color: "" },
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "The location of the project.",
                  type: "text",
                  marks: [{ type: "italic", attrs: {} }],
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          attrs: { color: "" },
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "An explanation of how the project is expected to generate GHG emission reductions or removals.",
                  type: "text",
                  marks: [{ type: "italic", attrs: {} }],
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          attrs: { color: "" },
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "A brief description of the scenario existing prior to the implementation of the project.",
                  type: "text",
                  marks: [{ type: "italic", attrs: {} }],
                },
              ],
            },
          ],
        },
        {
          type: "listItem",
          attrs: { color: "" },
          content: [
            {
              type: "paragraph",
              content: [
                {
                  text: "An estimate of annual average and total GHG emission reductions and removals.",
                  type: "text",
                  marks: [{ type: "italic", attrs: {} }],
                },
              ],
            },
          ],
        },
      ],
    },
  ],
};

export const FALLBACK_BUTTON_LABEL: any = {
  [ProjectFallbackType.SECTION_REMOVED]: "Reload",
  [ProjectFallbackType.USER_REMOVED_FROM_PROJECT]: "Go Back",
  [ProjectFallbackType.USER_ROLE_UPDATE]: "Reload",
};
export const FALLBACK_MESSAGE: any = {
  [ProjectFallbackType.SECTION_REMOVED]:
    "This section has been removed from this project please reload the page.",
  [ProjectFallbackType.USER_REMOVED_FROM_PROJECT]:
    "You have been removed from the project.",
  [ProjectFallbackType.USER_ROLE_UPDATE]:
    "Your role has been updated please reload the page",
};
