import axios from "axios";
import { ApiDataObject } from "../interfaces/interface";

const baseurl = process.env.REACT_APP_BASE_URL;
export class ApiService {
  async get(obj: ApiDataObject) {
    return await axios.get(`${baseurl}${obj.url}`, {
      params: obj?.data,
    });
  }
  async post(obj: ApiDataObject) {
    return await axios.post(`${baseurl}${obj.url}`, obj?.data, {
      params: obj.params,
    });
  }

  async delete(obj: ApiDataObject) {
    return await axios.delete(`${baseurl}${obj.url}`, {
      params: obj?.data,
    });
  }
  async put(obj: ApiDataObject) {
    return await axios.put(`${baseurl}${obj.url}`, obj?.data, {});
  }
  async patch(obj: ApiDataObject) {
    return await axios.patch(`${baseurl}${obj.url}`, obj?.data, {});
  }
}
