export enum StorageEnums {
  CREDENTIALS = "CREDENTIALS",
  THREAD_ID = "THREAD_ID",
  INVITE_CODE='INVITE_CODE'
}
export enum ProjectUserRoleEnum {
  OWNER = 'owner',
  VIEWER = 'viewer',
  EDITOR = 'editor',
  Remove='remove'
}
export enum tabsEnum {
 PROJECTS="Projects",
 MY_TASKS="My tasks"
}
