import { EditorContent } from "@tiptap/react";
import { useCallback, useEffect, useMemo, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";
import useEditorCustom from "../../../../hooks/useEditorCustom";
import { ChatModel } from "../../../../models/chatModel";
import { SectionModel } from "../../../../models/project_section.model";
import { OpenAiService } from "../../../../services/openAIService";
import { addChat, showChatModal } from "../../../../store/chatSlice";
import {
  getFirstEdited,
  getProjectById,
  getProjectLogsIsLoading,
  setFirstEdited,
} from "../../../../store/projectSlice";
import { getSectionDetails } from "../../../../store/sectionSlice";
import SkeletonLoader from "../../../Skeleton/Skeleton";
import AddLink from "../AddLink/AddLink";
import BubbleMenuWrapper from "../BubbleMenuWrapper/BubbleMenuWrapper";
import EditorMenu from "../EditorMenu/EditorMenu";
import style from "./Editor.module.scss";

const openAiService = new OpenAiService();
export interface EditorProps {
  handleText: (id: string, text: string) => void;
  id: string;
  template?: Record<string, any> | null;
  color: string;
  handleStatusUpdate: (sectionId: string) => void;
}

const Editor = ({
  handleText,
  id,
  template,
  color,
  handleStatusUpdate,
}: EditorProps) => {
  const linkRef = useRef<any>();
  const { id: projectId, sectionId } = useParams();
  const project = useSelector((state: any) =>
    getProjectById(state, projectId ?? "")
  );
  const dispatch = useDispatch();
  const firstEdited = useSelector(getFirstEdited);
  const projectLogsIsLoading = useSelector(getProjectLogsIsLoading);
  const handleTextChange = useCallback(
    (text: string) => {
      handleText(id, text);
    },
    [id, handleText]
  );
  const {
    editor,
    showMenu,
    showLinkModal,
    setShowLinkModal,
    connectionLoading,
  } = useEditorCustom(handleTextChange, id, template ?? null, color,handleStatusUpdate);

  const addLinkToEditor = (link: string) => {
    editor?.commands.setLink({ href: link });
    setShowLinkModal(false);
  };
  const sectionDetails: SectionModel | undefined = useSelector((state: any) =>
    getSectionDetails(state, sectionId ?? "")
  );
  const sectionName = useMemo(() => {
    if (sectionDetails?.name) {
      let nameArray = sectionDetails?.name.split(" ");
      let sectionNumber = nameArray.shift();
      return `${sectionNumber}: ${nameArray.join(" ")}`;
    }
    return "";
  }, [sectionDetails]);

  const openChatBot = useCallback(async () => {
    try {
      if (!projectId || firstEdited || projectLogsIsLoading) {
        return;
      }
      dispatch(setFirstEdited(new Date().toISOString()));
      const message = `Context: A team member is beginning work on a new section of our carbon offset project application. Each section requires understanding specific criteria and information. The project is in ${project?.location} and follows methodology ${project?.methodology}.
        Prompt: Give a brief overview of the Section ${sectionName}, including its importance in the application process and key points to consider when completing it.
        Output: Start your response with "Heading into the Section ${sectionName}, huh? Here’s what you need to know:" followed by a line break and "This section is crucial because:" followed by your bulleted response. Keep it under 250 words. Conclude with "Got questions or need pointers on this section? I’m here to support you."`;
      const payload = {
        message: message,
        prompt: message,
        isVisible: false,
      };
      const res = await openAiService.sendMessage(payload);
      dispatch(showChatModal(true));
      const messages = (res.data.messages ?? []).map(
        (_: ChatModel) => new ChatModel(_)
      );
      dispatch(addChat(messages));
    } catch (error) {}
  }, [dispatch, projectId, firstEdited]);

  useEffect(() => {
    if (showLinkModal && editor?.state.selection.empty) {
      setShowLinkModal(false);
    }
  }, [editor?.state.selection.empty]);

  useEffect(() => {
    if (showLinkModal) {
      setTimeout(() => {
        linkRef.current?.focus();
      }, 300);
    }
  }, [showLinkModal]);

  if (!editor) {
    return <></>;
  }

  return (
    <div className={style.editor}>
      <BubbleMenuWrapper editor={editor} placement={"top"}>
        {!showLinkModal &&
        showMenu.current &&
        !editor?.state.selection.empty &&
        project.role !== ProjectUserRoleEnum.VIEWER ? (
          <EditorMenu editor={editor} setShowLinkModal={setShowLinkModal} />
        ) : (
          <></>
        )}
      </BubbleMenuWrapper>
      <BubbleMenuWrapper editor={editor} placement={"bottom"}>
        {showLinkModal && project.role !== ProjectUserRoleEnum.VIEWER ? (
          <AddLink linkRef={linkRef} addLinkToEditor={addLinkToEditor} />
        ) : (
          <></>
        )}
      </BubbleMenuWrapper>

      {connectionLoading ? (
        <div className={style.editor__loader}>
          <SkeletonLoader width="60%" margin="0 0 12px" />
          <SkeletonLoader width="80%" margin="0 0 12px" />
          <SkeletonLoader width="40%" margin="0 0 12px" />
          <SkeletonLoader width="70%" margin="0 0 12px" />
          <SkeletonLoader width="90%" />
        </div>
      ) : (
        <div
          className={`${style.editor__content} ${
            project.role === ProjectUserRoleEnum.VIEWER ? style.notEditable : ""
          }`}
        >
          <EditorContent
            editor={editor}
            
            onKeyDownCapture={(e) => {
              if (project.role !== ProjectUserRoleEnum.VIEWER) {
                // openChatBot();
              } else {
                e.preventDefault();
              }
            }}
          />
        </div>
      )}
    </div>
  );
};
export default Editor;
