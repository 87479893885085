import * as yup from "yup";

export const signUpSchema = yup.object().shape({
  fullName: yup.string().required("Name is required").max(100,'Maximum length can be 100').trim(),
  password: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Password must contain at least 8 characters, a number, a special character, a lowercase character, and an uppercase character"
    ),
  confirmPassword: yup
    .string()
    .required("Password is required")
    .oneOf([yup.ref("password")], "Passwords do not match"),
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required")
    .trim(),
  company: yup.string().required("Company name is required").max(100,'Maximum length can be 100').trim(),
  activity: yup.object().required("Primary activity is required"),
  location: yup.object().required("Country is required"),
  inviteCode: yup.string().nullable(),
});
