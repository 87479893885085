import React from "react";
import { useNavigate } from "react-router";
import Button from "../../atoms/Button/Button";
import style from "./NotFoundPage.module.scss";

const NotFoundPage = () => {
    const navigate = useNavigate();
    return (
        <div className={`${style.NotFoundPage} flex-wrap align-center`}>
            <article>
                <strong>404</strong>
                <h1>Page Not Found</h1>
                <p>
                    Please check the URL or click the button below to go back.
                </p>
                <Button label="Go Back" onClick={() => navigate("login")} />
            </article>
        </div>
    );
};

export default NotFoundPage;
