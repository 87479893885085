import SkeletonLoader from "../../components/Skeleton/Skeleton";
import style from "./Comments.module.scss";
const CommentsLoader = () => {
  return (
    <div className={`${style.comments__item} flex`}>
      <SkeletonLoader width="32px" height="32px" circle={true} />
      <div className={style.comments__itemContent}>
        <div className={`${style.comments__profile}`}>
          <SkeletonLoader width="80px" height="8px" margin="0 0 5px" />
          <SkeletonLoader width="100px" height="6px" />
        </div>
        <p>
          <SkeletonLoader width="90%" height="6px" margin="10px 0 0" />
          <SkeletonLoader width="70%" height="6px" margin="5px 0 0" />
          <SkeletonLoader width="40%" height="6px" margin="5px 0 0" />
          <SkeletonLoader width="60%" height="6px" margin="5px 0 0" />
        </p>
      </div>
    </div>
  );
};
export default CommentsLoader;
