import { ApiDataObject } from "../interfaces/interface";
import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class ProfileService {
  updateUserProfile(payload: any) {
    const obj: ApiDataObject = {
      url: `users/profile`,
      data: payload,
    };
    return apiService.put(obj);
  }
}
