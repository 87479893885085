import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./authSlice";
import chatReducer from "./chatSlice";
import commentsReducer from "./commentsSlice";
import loginReducer from "./loginSlice";
import notificationReducer from "./notificationSlice";
import projectReducer from "./projectSlice";
import userDetailsReducer from "./userDetailsSlice";
import userRoleReducer from "./userRoleSlice";
import userReducer from "./userSlice";
import sectionReducer from "./sectionSlice";
const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    login: loginReducer,
    user: userReducer,
    auth: authReducer,
    userDetails: userDetailsReducer,
    userRole: userRoleReducer,
    notifications: notificationReducer,
    comments: commentsReducer,
    project: projectReducer,
    chat: chatReducer,
    section: sectionReducer,
  },
});

export default store;
