import { FC } from "react";
import style from "./TabButtons.module.scss";

interface TabButtonsProps {
  setActiveTab: (tab: string) => void;
  activeTab: string;
  fillMode?: boolean;
}

const TabButtons: FC<TabButtonsProps> = ({
  setActiveTab,
  activeTab,
  fillMode = false,
}) => {
  return (
    <div className={`${style.tabButton} ${fillMode && style.tabButtonFill}`}>
      <div className={style.tabButton__wrap}>
        <div
          className={`${style.tabButton__item} ${
            activeTab == "projects" && style.active
          }`}
          onClick={() => setActiveTab("projects")}
        >
          My Projects
        </div>
        <div
          className={`${style.tabButton__item} ${
            activeTab == "tasks" && style.active
          }`}
          onClick={() => setActiveTab("tasks")}
        >
          My Tasks
        </div>
      </div>
    </div>
  );
};

export default TabButtons;
