import * as yup from "yup";

export const confirmPasswordSchema = yup.object().shape({
  confirmationCode: yup.lazy((value) =>
  value === ""
    ? yup.string().required("Code must be a number")
    : yup
        .number()
        .required("Code is required")
        .typeError("Code must be a number")
),
});
