import { BubbleMenu, Editor } from "@tiptap/react";

const BubbleMenuWrapper = ({
  children,
  editor,
  placement,
}: {
  children: JSX.Element;
  editor: Editor | null;
  placement: any;
}) => {
  if (!editor) {
    return <></>;
  }
  return (
    <BubbleMenu
      editor={editor}
      tippyOptions={{
        duration: 100,
        showOnCreate: true,
        placement: placement,
      }}
    >
      {children}
    </BubbleMenu>
  );
};
export default BubbleMenuWrapper;
