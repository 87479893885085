import { NodeViewProps, NodeViewWrapper } from "@tiptap/react";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import ImagePlaceholder from "../../../../assets/images/image-placeholder.svg";
import DropdownMenu from "../../../../atoms/DropdownMenu/DropdownMenu";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";
import { getProjectById } from "../../../../store/projectSlice";
import AddImageModal from "../../Components/AddImageModal/AddImageModal";
import style from "./ResizableMediaNodeView.module.scss";
let lastClientX: number;

export const ResizableMediaNodeView = ({
  node,
  updateAttributes,
  deleteNode,
}: NodeViewProps) => {
  const { id: projectId } = useParams();
  const project = useSelector((state: any) =>
    getProjectById(state, projectId ?? "")
  );
  const [aspectRatio, setAspectRatio] = useState(0);

  const [proseMirrorContainerWidth, setProseMirrorContainerWidth] = useState(0);

  const resizableImgRef = useRef<HTMLImageElement | HTMLVideoElement | null>(
    null
  );
  const [showAddImageModal, setShowAddImageModal] = useState<boolean>(false);
  const mediaSetupOnLoad = () => {
    // ! TODO: move this to extension storage
    const proseMirrorContainerDiv = document.querySelector(".ProseMirror");

    if (proseMirrorContainerDiv)
      setProseMirrorContainerWidth(proseMirrorContainerDiv?.clientWidth);

    // When the media has loaded
    if (!resizableImgRef.current) return;

    resizableImgRef.current.onload = () => {
      const ratio =
        ((resizableImgRef.current as HTMLImageElement)?.naturalWidth || 1) /
        ((resizableImgRef.current as HTMLImageElement)?.naturalHeight || 1);
      setAspectRatio(ratio);
    };
  };

  const setLastClientX = (x: number) => {
    lastClientX = x;
  };

  useEffect(() => {
    mediaSetupOnLoad();
  });

  const [isHorizontalResizeActive, setIsHorizontalResizeActive] =
    useState(false);

  interface WidthAndHeight {
    width: number;
    height: number;
  }

  const limitWidthOrHeightToFiftyPixels = ({ width, height }: WidthAndHeight) =>
    width < 40 || height < 40;

  const documentHorizontalMouseMove = (e: MouseEvent) => {
    setTimeout(() => onHorizontalMouseMove(e));
  };

  const startHorizontalResize = (e: { clientX: number }) => {
    setIsHorizontalResizeActive(true);
    lastClientX = e.clientX;

    setTimeout(() => {
      document.addEventListener("mousemove", documentHorizontalMouseMove);
      document.addEventListener("mouseup", stopHorizontalResize);
    });
  };

  const stopHorizontalResize = () => {
    setIsHorizontalResizeActive(false);
    lastClientX = -1;

    document.removeEventListener("mousemove", documentHorizontalMouseMove);
    document.removeEventListener("mouseup", stopHorizontalResize);
  };

  const onHorizontalResize = (
    directionOfMouseMove: "right" | "left",
    diff: number
  ) => {
    if (!resizableImgRef.current) {
      console.error("Media ref is undefined|null", {
        resizableImg: resizableImgRef.current,
      });
      return;
    }

    const currentMediaDimensions = {
      width: resizableImgRef.current?.width,
      height: resizableImgRef.current?.height,
    };

    const newMediaDimensions = {
      width: -1,
      height: -1,
    };

    if (directionOfMouseMove === "left") {
      newMediaDimensions.width = currentMediaDimensions.width - Math.abs(diff);
    } else {
      newMediaDimensions.width = currentMediaDimensions.width + Math.abs(diff);
    }

    if (newMediaDimensions.width > proseMirrorContainerWidth)
      newMediaDimensions.width = proseMirrorContainerWidth;

    newMediaDimensions.height = newMediaDimensions.width / aspectRatio;

    if (limitWidthOrHeightToFiftyPixels(newMediaDimensions)) return;

    updateAttributes(newMediaDimensions);
  };

  const onHorizontalMouseMove = (e: MouseEvent) => {
    if (lastClientX === -1) return;

    const { clientX } = e;

    const diff = lastClientX - clientX;

    if (diff === 0) return;

    const directionOfMouseMove: "left" | "right" = diff > 0 ? "left" : "right";

    setTimeout(() => {
      onHorizontalResize(directionOfMouseMove, Math.abs(diff));
      lastClientX = clientX;
    });
  };

  const [isFloat, setIsFloat] = useState<boolean>();

  useEffect(() => {
    setIsFloat(node.attrs.dataFloat);
  }, [node.attrs]);

  const [isAlign, setIsAlign] = useState<boolean>();

  useEffect(() => {
    setIsAlign(node.attrs.dataAlign);
  }, [node.attrs]);

  const afterAddingImage = ({ src, height, width }: any) => {
    setShowAddImageModal(false);
    updateAttributes({ src, height, width });
  };

  const handlePaste = (event: any) => {
    var items = (event.clipboardData || event.originalEvent.clipboardData)
      .items;
    for (var index in items) {
      var item = items[0];
      if (item.kind === "file") {
        var blob = item.getAsFile();
        var reader = new FileReader();
        reader.onload = function (event) {
          console.log(event.target?.result); // data url!
          updateAttributes({ src: event.target?.result });
        };
        reader.readAsDataURL(blob);
      }
    }
  };
  return (
    <NodeViewWrapper
      as="div"
      className={`${style.imageBlock}
        ${(isFloat && `f-${node.attrs.dataFloat}`) || ""}
        ${(isAlign && `justify-${node.attrs.dataAlign}`) || ""}
      `}
    >
      {node.attrs.src ? (
        <div className={`${style.imageBlock__wrap}`}>
          <img
            src={node.attrs.src}
            ref={resizableImgRef as any}
            className="rounded-lg"
            alt={node.attrs.src}
            width={node.attrs.width}
            height={node.attrs.height}
          />
          {project.role !== ProjectUserRoleEnum.VIEWER && (
            <div
              className={`${style.imageBlock__resize} isResize`}
              title="Resize"
              onClick={({ clientX }) => setLastClientX(clientX)}
              onMouseDown={startHorizontalResize}
              onMouseUp={stopHorizontalResize}
            />
          )}
        </div>
      ) : (
        <div className={style.imageBlock__uploadWrap}>
          <div
            className={`${style.imageBlock__upload} flex align-center`}
            onClick={() => setShowAddImageModal(true)}
            onPaste={handlePaste}
          >
            <img src={ImagePlaceholder} alt="Image Placeholder" />
            Image Upload
          </div>
          {showAddImageModal && (
            <DropdownMenu onClose={()=>setShowAddImageModal(false)}>
              <AddImageModal
                open={showAddImageModal}
                setOpen={setShowAddImageModal}
                onAddImage={afterAddingImage}
                updateAttributes={updateAttributes}
              />
            </DropdownMenu>
          )}
        </div>
      )}
    </NodeViewWrapper>
  );
};
