import { ReactNode } from "react";
import DeleteIcon from "../../assets/images/delete-outline.svg";
import style from "./Table.module.scss";

export interface TableHeaderProps extends TableActionType {
  name: string;
  type: TableDataType;
  actionType?: TableActionType[];
  format?: string;
  title?: string;
  handleSort?: (columnName: string) => void;
}

export enum TableDataType {
  TEXT = "text",
  NUMBER = "number",
  ACTIONS = "action",
}

export enum ActionType {
  DELETE = "DELETE",
  ADD = "ADD",
  VIEW = "VIEW",
  EDIT = "EDIT",
}

export interface TableActionType {
  icon?: string;
  name: ActionType | string;
  onClick?: (rowData: any) => void;
  onChange?: (rowData: any, value: string, keyThatIsChanged: string) => void;
  customClass?: string;
  customStyle?: React.CSSProperties;
  disabled?: boolean;
}

export interface TableProps {
  headings: TableHeaderProps[];
  tableData?: any[];
  dataType?: string;
  noDataMessage?: string | ReactNode;
  onClick?: (rowData: any) => void;
}
const getStatusClassName = (status: string) => {
  switch (status) {
    case "open":
      return `${style.table__status} ${style.isOpen}`;
    case "in progress":
      return `${style.table__status} ${style.isProgress}`;
    case "completed":
      return `${style.table__status} ${style.isCompleted}`;
    default:
      return "";
  }
};

const Table: React.FC<TableProps> = ({
  headings,
  tableData,
  dataType,
  noDataMessage,
  onClick,
}) => {
  const renderCell = (row: any, column: TableHeaderProps) => {
    const {
      name,
      type,
      customClass,
      customStyle,
      actionType,
      onClick = () => {},
    } = column;

    if (name === "status") {
      const statusClassName = getStatusClassName(row[name]);
      return (
        <td>
          <span className={statusClassName}>{row[name]}</span>
        </td>
      );
    }

    switch (type) {
      case TableDataType.ACTIONS:
        return actionType?.map((action, actionIndex) => {
          if (action.name === ActionType.DELETE && row.role === "owner") {
            return (
              <>
                <td key={actionIndex}>
                  <img
                    onClick={(e) => {
                      if (action.onClick) {
                        action.onClick(row);
                      }
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    className={style.actionBtn}
                    src={DeleteIcon}
                    alt="Delete"
                  />
                </td>
              </>
            );
          } else {
            return <td></td>;
          }
        });
      default:
        return (
          <td
            key={name}
            className={
              row["status"] === "open"
                ? style.isOpen
                : row["status"] === "completed"
                ? style.isCompleted
                : row["status"] === "in progress"
                ? style.isProgress
                : ""
            }
            style={customStyle}
            onClick={() => onClick(row)}
          >
            {name === "name" || name === "projectName" ? (
              <a>{row[name]}</a>
            ) : (
              row[name] ?? "-"
            )}
          </td>
        );
    }
  };
  return (
    <div className={style.table}>
      {tableData?.length !== 0 ? (
        <table>
          <thead>
            <tr>
              {headings.map((header, index) => (
                <th key={index}>
                  <div className="flex align-center">
                    <span>{header?.title}</span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData?.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => {
                  if (onClick) {
                    onClick(row);
                  }
                }}
              >
                {headings.map((column) => renderCell(row, column))}
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <div className={style.table__nodata}>
          {noDataMessage ? noDataMessage : `No ${dataType} yet`}
        </div>
      )}
    </div>
  );
};
export default Table;
