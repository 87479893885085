import { Node, mergeAttributes } from "@tiptap/core";

const getTextBlockContent = (json: any): any => {
  if (json.type === "taskListBlock") {
    return json.content;
  }
  if (!json.content?.[0]) {
    return [];
  }
  return getTextBlockContent(json.content[0]);
};
export const TaskListBlock = Node.create({
  name: "taskListBlock",

  priority: 1000,

  group: "block",

  content: "((block+) (taskList+) (block*))+",

  draggable: true,

  selectable: false,

  inline: false,

  isolating: true,

  addAttributes() {
    return {
      class: { default: "task-list" },
    };
  },
  addOptions: () => {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: "div" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes), 0];
  },
  addKeyboardShortcuts() {
    return {
      Backspace: ({ editor }) => {
        const {
          selection: { from, to },
          doc,
        } = editor.state;
        let res = false;
        if (editor.state.selection.content().content.toJSON()?.length > 1) {
          return true;
        }
        doc.descendants((node, pos) => {
          const [nodeFrom, nodeTo] = [pos, pos + node.nodeSize];
          if (nodeFrom <= from && to <= nodeTo) {
            if (node.attrs.contenteditable) {
              const textBlockContent = getTextBlockContent(node.toJSON());
              res =
                node.textContent.length < 1 && textBlockContent.length === 1;
            } else {
              res = true;
            }
          }
          return false;
        });
        return res;
      },
    };
  },
});
