import { Editor } from "@tiptap/react";
import { useState } from "react";
import editorStyle from "../../../../assets/scss/editorMenu.module.scss";
import DropdownMenu from "../../../../atoms/DropdownMenu/DropdownMenu";

interface ChangeTextTypeProps {
  editor: Editor | null;
}

const ChangeTextType = ({ editor }: ChangeTextTypeProps) => {
  const [showType, setShowTypo] = useState(false);

  if (!editor) {
    return null;
  }

  return (
    <div className={editorStyle.editorMenu__typo}>
      <button onClick={() => setShowTypo(!showType)}>Aa</button>

      {showType && (
        <DropdownMenu onClose={()=>setShowTypo(false)}>
          <div
            className={editorStyle.editorMenu__dropdown}
            onClick={() => setShowTypo(false)}
          >
            <button
              onClick={() => editor.chain().focus().setParagraph().run()}
              className={
                editor.isActive("paragraph") ? editorStyle.isActive : ""
              }
              style={{ fontSize: "90%" }}
            >
              Paragraph
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 1 }).run()
              }
              className={
                editor.isActive("heading", { level: 1 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "110%" }}
            >
              Heading 1
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 2 }).run()
              }
              className={
                editor.isActive("heading", { level: 2 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "100%" }}
            >
              Heading 2
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 3 }).run()
              }
              className={
                editor.isActive("heading", { level: 3 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "95%" }}
            >
              Heading 3
            </button>
            {/* <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 4 }).run()
              }
              className={
                editor.isActive("heading", { level: 4 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "85%" }}
            >
              Heading 4
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 5 }).run()
              }
              className={
                editor.isActive("heading", { level: 5 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "75%" }}
            >
              Heading 5
            </button>
            <button
              onClick={() =>
                editor.chain().focus().toggleHeading({ level: 6 }).run()
              }
              className={
                editor.isActive("heading", { level: 6 })
                  ? editorStyle.isActive
                  : ""
              }
              style={{ fontSize: "65%" }}
            >
              Heading 6
            </button>
            <button
              onClick={() => editor.chain().focus().toggleBlockquote().run()}
              className={
                editor.isActive("blockquote") ? editorStyle.isActive : ""
              }
            >
              blockquote
            </button> */}
          </div>
        </DropdownMenu>
      )}
    </div>
  );
};
export default ChangeTextType;
