export enum ModuleType {
  TEXT = "textBlock",
  IMAGE = "image",
  SURVEY = "taskListBlock",
  TABLE = "table",
}

export enum ProjectSectionStatus {
  OPEN = "open",
  IN_PROGRESS = "in progress",
  COMPLETED = "completed",
}
export interface TeamMemberDetails {
  role: string;
  userId: string;
  email: string;
  name: string;
}
export enum ProjectFallbackType {
  NONE = "",
  USER_ROLE_UPDATE = "role_update",
  USER_REMOVED_FROM_PROJECT = "user_removed_from_project",
  SECTION_REMOVED = "section_removed",
}