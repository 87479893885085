import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import BellIcon from "../../assets/images/bell.svg";
import CloseIcon from "../../assets/images/close.svg";
import SkeletonLoader from "../../components/Skeleton/Skeleton";
import { NotificationTypeEnum } from "../../enums/Enums";
import { NotificationService } from "../../services/notification.service";
import {
  addNotification,
  clearNotifications,
  deleteNotification,
  updateTotalCount,
  updateUnreadCount,
} from "../../store/notificationSlice";
import { timeConverter } from "../../utils/utils";
import style from "./Notifications.module.scss";
import Button from "../../atoms/Button/Button";
import GlobalLoader from "../../components/GlobalLodaer/GlobalLoader";
const notificationService = new NotificationService();
const Notifications = () => {
  const [loading, setLoading] = useState(false);
  const [paginationLoading, setPaginationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [isDeleting,setIsDeleting] = useState(false);
  const { allNotifications, unreadCount, totalCount } = useSelector(
    (store: any) => store.notifications
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleLoading = (page: number, state: boolean) => {
    if (page > 1) {
      setPaginationLoading(state);
    } else {
      setLoading(state);
    }
  };
  const getNotification = async (pageNumber: number) => {
    try {
      handleLoading(pageNumber, true);
      const res = await notificationService.getNotifications(pageNumber);
      dispatch(updateTotalCount(res?.data?.count ?? 0));
      dispatch(addNotification(res.data.notifications));
      dispatch(updateUnreadCount(res.data.unReadCount));
      handleLoading(pageNumber, false);
    } catch (e: any) {
      handleLoading(pageNumber, false);
    }
  };

  useEffect(() => {
    getNotification(page);
  }, [page]);
  const handleDeleteNotification = async (id: string) => {
    try {
      setIsDeleting(true);
      await notificationService.deleteNotification(id);
      dispatch(deleteNotification(id));
      setPage(1);
      setIsDeleting(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleNotificationClick = (data: any) => {
    let queryString = "";
    switch (data?.type) {
      case NotificationTypeEnum.TAGGED_IN_COMMENT:
      case NotificationTypeEnum.COMMENT_ADDED_TO_SECTION: {
        queryString =
          data?.payload?.commentId && data?.payload?.sectionId
            ? `?commentId=${data?.payload?.commentId}&sectionId=${data?.payload?.sectionId}`
            : "";
        break;
      }
      default: {
        queryString = "";
      }
    }
    navigate(`/dashboard/details/${data.projectId}${queryString}`);
  };
  const handleClearAllNotifications = async (e: any) => {
    try {
      dispatch(clearNotifications());
      await notificationService.deleteAllNotifications();
      setPage(1);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <div className={style.notification}>
      {isDeleting && <GlobalLoader />}
      <header
        className={`${style.notification__header} flex-wrap justify-between align-center`}
      >
        <h1>Notifications {totalCount ? <>({totalCount})</> : ""}</h1>
        {allNotifications.length>0&&<Button label="Clear all" onClick={handleClearAllNotifications} />}
      </header>
      <div className={style.notification__content} key={"notification wrapper"}>
        {loading ? (
          <ul>
            {Array(5)
              .fill(0)
              .map(() => {
                return (
                  <li className="flex align-center justify-between">
                    <picture>
                      <SkeletonLoader
                        width="100%"
                        height="100%"
                        fill={true}
                        circle={true}
                      />
                    </picture>
                    <aside>
                      <SkeletonLoader
                        width="90%"
                        height="10px"
                        margin="0 0 10px"
                      />
                      <SkeletonLoader width="180px" height="8px" />
                    </aside>
                    <button>
                      <SkeletonLoader width="20px" height="20px" />
                    </button>
                  </li>
                );
              })}
          </ul>
        ) : (
          <InfiniteScroll
            dataLength={allNotifications.length}
            next={() => {
              if (loading) {
                return;
              }
              setPage((prev) => prev + 1);
            }}
            hasMore={true}
            loader={
              paginationLoading ? (
                <ul>
                  <li className="flex align-center justify-between">
                    <picture>
                      <SkeletonLoader
                        width="100%"
                        height="100%"
                        fill={true}
                        circle={true}
                      />
                    </picture>
                    <aside>
                      <SkeletonLoader
                        width="90%"
                        height="10px"
                        margin="0 0 10px"
                      />
                      <SkeletonLoader width="180px" height="8px" />
                    </aside>
                    <button>
                      <SkeletonLoader width="20px" height="20px" />
                    </button>
                  </li>
                </ul>
              ) : (
                <></>
              )
            }
            scrollableTarget="notificationBody"
          >
            <ul>
              {allNotifications.map((el: any) => {
                return (
                  <li key={el.id} className="flex align-center justify-between">
                    <picture>
                      <img src={BellIcon} alt="" />
                    </picture>
                    <aside
                      onClick={(e) => {
                        handleNotificationClick(el);
                      }}
                    >
                      <p>{el.message}</p>
                      <span>{timeConverter(el?.createdAt)}</span>
                    </aside>
                    <button
                    disabled={isDeleting}
                      onClick={(e) => {
                        e.stopPropagation();
                        if(isDeleting){
                          return;
                        }
                        handleDeleteNotification(el.id);
                      }}
                    >
                      <img src={CloseIcon} alt="X" />
                    </button>
                  </li>
                );
              })}
              {allNotifications.length === 0 && (
                <li className={style.notification__nodata}>
                  No New Notifications
                </li>
              )}
            </ul>
          </InfiniteScroll>
        )}
      </div>
    </div>
  );
};
export default Notifications;
