import { jwtDecode } from "jwt-decode";
import {
  LoaderFunctionArgs,
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import UnAuthHeader from "../components/Header/UnAuthHeader";
import ProjectWrapper from "../components/Projects/ProjectWrapper";
import { StorageEnums } from "../enums/storageEnums";
import AuthGuard from "../guards/Auth.guard";
import UnAuthGuard from "../guards/UnAuth.guard";
import ConfirmCode from "../pages/ConfirmCode/ConfirmCode";
import ConfirmEmail from "../pages/ConfirmEmail/ConfirmEmail";
import Dashboard from "../pages/Dashboard/Dashboard";
import Home from "../pages/Home/Home";
import Login from "../pages/Login/Login";
import MyAccount from "../pages/MyAccount/MyAccount";
import NotFoundPage from "../pages/NotFoundPage/NotFoundPage";
import Notifications from "../pages/Notifications/Notifications";
import RecentActivities from "../pages/RecentActivities/RecentActivities";
import ResetPassword from "../pages/ResetPassword/ResetPassword";
import SignUp from "../pages/SignUp/Signup";
import { AuthService } from "../services/authService";
import { StorageService } from "../services/storage.service";
import Section from "../components/Section/Section";
import { TOKEN_REFRESH_TIME_IN_MILLI_SEC } from "../constants/constants";

const authService = new AuthService();
const storageService = new StorageService();

export async function refreshLoader(data: LoaderFunctionArgs<any>) {
  try {
    const { accessToken, refreshToken } = storageService.getKey(
      StorageEnums.CREDENTIALS
    )?.data;
    const currentTime = Date.now();
    const expiry = jwtDecode(accessToken).exp;
    if (!accessToken || !expiry || !refreshToken||(expiry * 1000 - currentTime)<0) {
      storageService.removeKey(StorageEnums.CREDENTIALS);
      return null;
    }
    if (expiry * 1000 - currentTime < TOKEN_REFRESH_TIME_IN_MILLI_SEC) {
      const res = await authService.handleRefreshToken(refreshToken);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
    }
  } catch (error) {
    storageService.removeKey(StorageEnums.CREDENTIALS);
  }
  return null;
}

const RouterPath = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <Navigate to="login" />
        </UnAuthGuard>
      ),
    },
    {
      path: "/login",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <Login />
        </UnAuthGuard>
      ),
    },
    {
      path: "/dashboard",
      element: (
        <AuthGuard>
          <Dashboard />
        </AuthGuard>
      ),
      children: [
        {
          path: "",
          element: <Navigate to="projects" replace={true} />,
        },
        {
          path: "projects",
          element: <AuthGuard><ProjectWrapper /></AuthGuard>,
          loader: refreshLoader,
          errorElement: (
            <>
              <Navigate to="/" />
            </>
          ),
        },
        {
          path: "details/:id",
          element: <AuthGuard> <Section /></AuthGuard>,
          loader: refreshLoader,
          errorElement: (
            <>
              <Navigate to="/" />
            </>
          ),
        },
         {
          path: "details/:id/section/:sectionId",
          element: <AuthGuard> <Section /></AuthGuard>,
          loader: refreshLoader,
          errorElement: (
            <>
              <Navigate to="/" />
            </>
          ),
        },
        {
          path: "home",
          element: <AuthGuard><Home /></AuthGuard>,
        },
        {
          path: "activities",
          element: <AuthGuard><RecentActivities /></AuthGuard>,
        },
        {
          path: "account",
          element:<AuthGuard><MyAccount /></AuthGuard> ,
        },
        { path: "notifications", element: <Notifications /> },
      ],
    },

    {
      path: "/signup",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <SignUp />
        </UnAuthGuard>
      ),
    },
    {
      path: "/signup/:email/:company/:code",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <SignUp />
        </UnAuthGuard>
      ),
    },
    {
      path: "/reset-password",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <ResetPassword />
        </UnAuthGuard>
      ),
    },
    {
      path: "/confirm-email/:email",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <ConfirmEmail />
        </UnAuthGuard>
      ),
    },
    {
      path: "/confirm-code/:email",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <ConfirmCode />
        </UnAuthGuard>
      ),
    },
    {
      path: "*",
      element: (
        <UnAuthGuard>
          <UnAuthHeader />
          <NotFoundPage />
        </UnAuthGuard>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
};
export default RouterPath;
