import { yupResolver } from "@hookform/resolvers/yup";
import randomColor from "randomcolor";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import Button from "../../atoms/Button/Button";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import { ProjectUserRoleEnum, StorageEnums } from "../../enums/storageEnums";
import { ChatModel } from "../../models/chatModel";
import {
  SectionModel,
  ShortSectionModel,
} from "../../models/project_section.model";
import { OpenAiService } from "../../services/openAIService";
import { ProjectService } from "../../services/project.service";
import { SectionService } from "../../services/section.service";
import { StorageService } from "../../services/storage.service";
import { addChat, showChatModal } from "../../store/chatSlice";
import {
  getProjectById,
  getSectionList,
  setFirstEdited,
  setProject,
  setProjectById,
  setProjectLogsIsLoading,
  setSectionList,
} from "../../store/projectSlice";
import {
  getSectionDetails,
  resetSection,
  setSectionAssignees,
  setSectionDetails,
} from "../../store/sectionSlice";
import SkeletonLoader from "../Skeleton/Skeleton";
import AddEditAppendixModal from "./Components/AppendixSection/AddEditAppendixModal";
import FallbackModal from "./Components/FallbackModal/FallbackModal";
import SectionAnalytics from "./Components/SectionAnalytics/SectionAnalytics";
import SectionEditor from "./Components/SectionEditorWrapper/SectionEditor";
import SectionSideBar from "./Components/SectionSideBar/SectionSideBar";
import style from "./Section.module.scss";
import { SectionSchema } from "./section.schema";

const sectionService = new SectionService();
const projectService = new ProjectService();
const color = randomColor();
const storageService = new StorageService();
const openAiService = new OpenAiService();

const Section = () => {
  const { id, sectionId } = useParams();
  const { control, watch, setValue } = useForm<any>({
    resolver: yupResolver(SectionSchema),
  });

  const [combinedText, setCombinedText] = useState<string>("");

  const dispatch = useDispatch();
  const sectionList = useSelector((state: any) =>
    getSectionList(state, id ?? "")
  );

  const textInput = useRef<{ [key: string]: string }>({});
  const [openAppendixModal, setOpenAppendixModal] = useState(false);
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const sectionListOptions = useMemo(() => {
    const list =
      sectionList?.map((e: ShortSectionModel) => {
        return { label: e.name, value: e.id };
      }) ?? [];
    if (project?.role !== ProjectUserRoleEnum.VIEWER) {
      list.push({ label: "ADD APPENDIX", value: "ADD_APPENDIX" });
    }
    return list;
  }, [sectionList, project]);

  const navigate = useNavigate();
  const sectionDetails: SectionModel | undefined = useSelector((state: any) =>
    getSectionDetails(state, sectionId ?? "")
  );
  const [appendixTitle, setAppendixTitle] = useState("");

  const getProjectSectionList = useCallback(
    async (projectId: string) => {
      try {
        const res = await projectService.fetchProjectSectionList(projectId);
        let list = SectionService.formatSectionList(res?.data?.sections || []);
        dispatch(setSectionList({ id: id ?? "", list: list }));
      } catch (error) {
        console.error(error);
        navigate("/dashboard/projects");
      }
    },
    [dispatch, id, navigate]
  );

  useEffect(() => {
    if (!id) {
      return;
    }

    getProjectSectionList(id);
    return () => {
      dispatch(resetSection());
    };
  }, [id, getProjectSectionList, dispatch]);

  const getSectionById = useCallback(
    async (projectId: string, sectionId: string | number) => {
      try {
        const res = await sectionService.fetchSectionById(projectId, sectionId);
        dispatch(setSectionDetails(res.data.section));
        dispatch(
          setProjectById({ id: res.data.project.id, project: res.data.project })
        );
        textInput.current = {};
        textInput.current = {};
      } catch (error) {
        console.error(error);
        navigate("/dashboard/projects");
      }
    },
    [dispatch, navigate]
  );

  const updateSectionWithoutTemplate = useCallback(
    async (
      projectId: string,
      sectionId: string | number,
      parentId: string | number
    ) => {
      try {
        const res: any = await sectionService.fetchSectionById(
          projectId,
          sectionId
        );
        if (res && sectionDetails) {
          const section = SectionService.sectionDetailsWithoutTemplateUpdation(
            sectionId,
            parentId,
            res.data.section,
            sectionDetails
          );
          dispatch(setSectionDetails(section));
        }
      } catch (error) {
        console.error(error);
        navigate("/dashboard/projects");
      }
    },
    [dispatch, sectionDetails, navigate]
  );

  const fetchSectionAssignee = useCallback(
    async (id: string, sectionId: string) => {
      try {
        const users: any = await sectionService.getSectionAssignee(
          id,
          sectionId
        );
        dispatch(
          setSectionAssignees({
            sectionId: sectionId,
            assignees: users.data.sectionUsers,
          })
        );
      } catch (error) {}
    },
    [dispatch]
  );
  useEffect(() => {
    if (!sectionId && sectionList && sectionList?.length) {
      navigate(`/dashboard/details/${id}/section/${sectionList[0].id}`);
      return;
    }

    const selectedSection = sectionList?.find(
      (e: ShortSectionModel) => e.id === sectionId
    );

    if (!selectedSection) {
      return;
    }

    setValue("selectedSection", {
      value: selectedSection.id,
      label: selectedSection.name,
    });
  }, [id, navigate, sectionId, sectionList, setValue]);

  useEffect(() => {
    if (!id || !sectionId) {
      return;
    }

    getSectionById(id, sectionId);
    fetchSectionAssignee(id, sectionId);
  }, [id, sectionId, getSectionById, fetchSectionAssignee]);

  useEffect(() => {
    const subscription = watch((values) => {
      if (values.selectedSection?.value === "ADD_APPENDIX") {
        setOpenAppendixModal(true);
        return;
      }
      if (id && values.selectedSection?.value) {
        navigate(
          `/dashboard/details/${id}/section/${values.selectedSection?.value}`
        );
      }
    });
    return () => {
      subscription.unsubscribe();
      textInput.current = {};
    };
  }, [id, navigate, watch]);

  useEffect(() => {
    textInput.current = {};
  }, [sectionId]);

  const handleText = useCallback((id: string, text: string) => {
    textInput.current[id] = text;
    setCombinedText(Object.values(textInput.current).join(" "));
  }, []);

  const goBack = () => {
    const currentIndex = sectionListOptions.findIndex(
      (e) => e.value === sectionId
    );
    if (currentIndex > 0) {
      navigate(
        `/dashboard/details/${id}/section/${
          sectionListOptions[currentIndex - 1].value
        }`
      );
    }
  };

  const goNext = () => {
    const currentIndex = sectionListOptions.findIndex(
      (e) => e.value === sectionId
    );
    if (currentIndex < sectionListOptions.length - 2) {
      navigate(
        `/dashboard/details/${id}/section/${
          sectionListOptions[currentIndex + 1].value
        }`
      );
    }
  };

  const openChatBot = useCallback(
    async (name: string, location: string, methodology: string) => {
      try {
        if (
          (id && localStorage.getItem(id)) ||
          !storageService.getKey(StorageEnums.INVITE_CODE)
        ) {
          return;
        }
        const message = `Context: A new team member has just been added to our carbon offset project in ${location}, which follows methodology ${methodology}. They are new to the project and possibly to working with Verra's standards and processes.
      Prompt: Provide a brief, welcoming overview of the Verra registration process for carbon offset projects, including key steps and why it's important for our project's success. Be specific to our chosen methodology and country whenever possible.
      Output: Start your response with "Welcome to ${name}! It's great to have you on board." followed by a line break and then "You might be curious about how projects like ours get registered with Verra. Here’s a quick rundown:" followed by your bulleted response. Output fewer than 200 words. End your response with "Excited to have you with us as we navigate through this process! If you have any questions or need a deeper dive into any part, just let me know.""`;
        const payload = {
          message: message,
          prompt: message,
          isVisible: false,
        };
        const res = await openAiService.sendMessage(payload);
        storageService.removeKey(StorageEnums.INVITE_CODE);
        dispatch(showChatModal(true));
        const messages = (res.data.messages ?? []).map(
          (_: ChatModel) => new ChatModel(_)
        );
        dispatch(addChat(messages));
      } catch (error) {}
    },
    [dispatch, id]
  );

  const getProjectLogs = useCallback(
    async (id: string) => {
      try {
        dispatch(setProjectLogsIsLoading(true));
        const res: any = await projectService.getProjectLogs(id);
        dispatch(setFirstEdited(res.data?.log?.firstEdited));
        dispatch(setProjectLogsIsLoading(false));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );

  // useEffect(() => {
  //   if (id && project) {
  //     // openChatBot(project.name, project.location, project.methodology);
  //   }
  // }, [openChatBot, id, project]);

  useEffect(() => {
    if (id) {
      getProjectLogs(id);
    }
  }, [id, getProjectLogs]);

  useEffect(() => {
    if (project) {
      dispatch(setProject(project));
    }
    return () => {
      dispatch(setProject(undefined));
    };
  }, [project, dispatch]);

  useEffect(() => {
    document
      .getElementById(`section_${sectionDetails?.id}`)
      ?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [sectionDetails?.id]);

  const handleAppendixModalClose = async (sectionId: string) => {
    if (id) {
      const section =
        sectionListOptions.find((el) => sectionId === el.value) ??
        sectionListOptions[0];
      setValue("selectedSection", section);
      if (appendixTitle) {
        setAppendixTitle("");
      }
    }
    setOpenAppendixModal(false);
  };
  const handleAddSection = async (sectionId: string) => {
    await getProjectSectionList(id ?? "");
    navigate(`/dashboard/details/${id}/section/${sectionId}`);
    setOpenAppendixModal(false);
  };
  const handleEditSection = async () => {
    await getProjectSectionList(id ?? "");
    await getSectionById(id ?? "", sectionId ?? "");
    setAppendixTitle("");
    setOpenAppendixModal(false);
  };

  return (
    <div className={style.section}>
      <>
        <FallbackModal />
        {/* {sectionList ? (
          <div
            className={`${style.section__pagination} flex-wrap align-center justify-between`}
          >
            <h1 id={`section_${sectionDetails?.id}`}>
              {sectionDetails?.name ? (
                <>
                  <span>{sectionName}</span>
                  <picture>
                    {sectionAssignees.map((assignee) =>
                      assignee?.image ? (
                        <img src={assignee?.image} alt="" />
                      ) : (
                        <img src={UserImage} alt="" />
                      )
                    )}
                  </picture>

                  {(project.role === ProjectUserRoleEnum.OWNER ||
                    (sectionDetails.isAppendix &&
                      project?.role !== ProjectUserRoleEnum.VIEWER)) && (
                    <img
                      src={AddProfile}
                      alt="Setting"
                      onClick={() => setOpenSectionManagement(true)}
                    />
                  )}
                  {sectionDetails.isAppendix &&
                    project?.role !== ProjectUserRoleEnum.VIEWER && (
                      <img
                        src={EditImage}
                        alt=""
                        onClick={() => {
                          setOpenAppendixModal(true);
                          setAppendixTitle(sectionDetails.name);
                        }}
                      />
                    )}
                </>
              ) : (
                <SkeletonLoader width="50%" height="25px" margin="10px 0 0" />
              )}
            </h1>
            {openSectionManagement && (
              <SectionManagementModal
                open={openSectionManagement}
                onClose={() => {
                  setOpenSectionManagement(false);
                  if (id && sectionId) {
                    updateSectionWithoutTemplate(id, sectionId, sectionId);
                  }
                }}
                section={sectionDetails}
                onSectionDelete={handleSectionDelete}
              />
            )}
            <aside>
              <Dropdown
                isSearchable={false}
                optionsAlignRight={true}
                name="selectedSection"
                label="Project section"
                options={sectionListOptions}
                control={control}
                bottomSpace={false}
                defaultValue={{ label: "", value: "" }}
              />
            </aside>
          </div>
        ) : (
          <div className={`${style.section__pagination} flex justify-between`}>
            <h1 style={{ maxWidth: "300px" }}>
              <SkeletonLoader width="100%" height="25px" margin="10px 0 0" />
            </h1>
            <aside>
              <SkeletonLoader width="100%" height="55px" margin="0" />
            </aside>
          </div>
        )} */}
        {sectionDetails ? (
          <div className={style.section__content}>
            <SectionEditor
              section={sectionDetails}
              level={1}
              handleText={handleText}
              key={sectionDetails.id}
              color={color}
              updateSection={updateSectionWithoutTemplate}
            />
            <SectionAnalytics
              section={sectionDetails}
              text={combinedText}
              role={project?.role}
            />

            <div className={`${style.section__bottom} flex justify-end`}>
              <aside>
                <Dropdown
                  isSearchable={false}
                  name="selectedSection"
                  label="Project section"
                  options={sectionListOptions}
                  control={control}
                  bottomSpace={false}
                  prefixClassName={"is-wrap"}
                  defaultValue={{ label: "", value: "" }}
                />
              </aside>
              {!(
                sectionListOptions?.findIndex((e) => e.value === sectionId) <= 0
              ) && (
                <Button
                  buttonClass={"outline-gray"}
                  label="Back"
                  disabled={
                    sectionListOptions?.findIndex(
                      (e) => e.value === sectionId
                    ) <= 0
                  }
                  onClick={goBack}
                ></Button>
              )}
              {!(
                sectionListOptions?.findIndex((e) => e.value === sectionId) >=
                sectionListOptions.length - 2
              ) && (
                <Button
                  label="Next"
                  onClick={goNext}
                  disabled={
                    sectionListOptions?.findIndex(
                      (e) => e.value === sectionId
                    ) >=
                    sectionListOptions.length - 2
                  }
                ></Button>
              )}
            </div>
          </div>
        ) : (
          <div className={style.section__content}>
            <SkeletonLoader width="40%" height="15px" margin="0 0 10px" />
            <SkeletonLoader width="100%" height="150px" margin="0 0 35px" />
            <SkeletonLoader width="40%" height="15px" margin="0 0 10px" />
            <SkeletonLoader width="100%" height="150px" margin="0 0 35px" />
            <SkeletonLoader width="100%" height="150px" margin="0 0 35px" />
            <SkeletonLoader width="50%" height="15px" margin="0 0 10px" />
            <SkeletonLoader width="100%" height="180px" margin="0 0 35px" />
            <SkeletonLoader width="50%" height="15px" margin="0 0 10px" />
            <SkeletonLoader width="100%" height="180px" margin="0 0 35px" />
          </div>
        )}
        <SectionSideBar />
      </>
      {openAppendixModal && (
        <AddEditAppendixModal
          open={openAppendixModal}
          onClose={handleAppendixModalClose}
          onAddSection={handleAddSection}
          appendixTitle={appendixTitle}
          onEditSection={handleEditSection}
        />
      )}
    </div>
  );
};
export default Section;
