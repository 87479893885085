import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NotificationModel } from "../models/notification.model";
import { NotificationService } from "../services/notification.service";

interface NotificationState {
  allNotifications: NotificationModel[];
  headerNotifications: NotificationModel[];
  unreadCount: number;
  totalCount: number;
}

const initialState: NotificationState = {
  allNotifications: [],
  headerNotifications: [],
  unreadCount: 0,
  totalCount: 0,
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    addNotification: (state, action: PayloadAction<NotificationModel[]>) => {
      return {
        ...state,
        allNotifications: NotificationService.filterAndUpdateNotifications(
          action.payload,
          state.allNotifications
        ),
        headerNotifications: NotificationService.filterAndUpdateNotifications(
          action.payload,
          state.allNotifications
        ),
      };
    },
    deleteNotification: (state, action: PayloadAction<string>) => {
      const allNotifications = state.allNotifications.filter((notification) => {
        return notification.id !== action.payload;
      });
      const headerNotifications = state.headerNotifications.filter(
        (notification) => {
          return notification.id !== action.payload;
        }
      );
      return {
        ...state,
        allNotifications: allNotifications,
        headerNotifications: headerNotifications,
      };
    },
    updateNotification: (state, action: PayloadAction<NotificationModel>) => {
      return {
        ...state,
        allNotifications: NotificationService.filterAndUpdateNotifications(
          [action.payload],
          state.allNotifications
        ),
        headerNotifications: NotificationService.filterAndUpdateNotifications(
          [action.payload],
          state.allNotifications
        ),
      };
    },
    clearNotifications: (state) => {
      return {
        ...state,
        allNotifications: [],
        headerNotifications:[],
        unreadCount: 0,
        totalCount: 0,
      };
    },
    updateUnreadCount: (state, action: { payload: number }) => {
      return {
        ...state,
        unreadCount: action.payload,
      };
    },
    increaseUnReadCount: (state) => {
      return {
        ...state,
        unreadCount: state.unreadCount + 1,
      };
    },
    updateTotalCount: (state, action) => {
      return {
        ...state,
        totalCount: action.payload,
      };
    },
  },
});

export const {
  addNotification,
  deleteNotification,
  updateNotification,
  clearNotifications,
  updateUnreadCount,
  increaseUnReadCount,
  updateTotalCount,
} = notificationSlice.actions;

export default notificationSlice.reducer;
