import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss";
import ChatBot from "./components/ChatBot/ChatBot";
import useAuthenticated from "./hooks/useAuthenticated";
import useSocket from "./hooks/useSockets";
import interceptor from "./interceptors/Interceptors";
import RouterPath from "./routes/RouterPath";
import { selectUserDetails } from "./store/userDetailsSlice";
interceptor();
const App = () => {
  const userDetails = useSelector(selectUserDetails);
  const authenticated = useAuthenticated();
  useSocket();

  return authenticated ? (
    <div className="App">
      <RouterPath />
      <ToastContainer />
      {/* {userDetails.id ? <ChatBot id={userDetails.id} /> : ""} */}
    </div>
  ) : null;
};

export default App;
