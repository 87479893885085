import React, { useState } from "react";
import Input from "../../atoms/Input/Input";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import Button from "../../atoms/Button/Button";
import { logInSchema } from "./login.schema";
import style from "../../assets/scss/auth.module.scss";
import { ApiService } from "../../services/apiServices";
import { StorageService } from "../../services/storage.service";
import { StorageEnums } from "../../enums/storageEnums";
import { toast } from "react-toastify";
import { ErrorMessageEnum } from "../../enums/errorMessageEnums";
const apiService = new ApiService();
const storageService = new StorageService();
const Login = () => {
  const methods = useForm({
    resolver: yupResolver(logInSchema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
  } = methods;
  const navigate = useNavigate();

  const handleLogin = async (data: FieldValues) => {
    try {
      setLoading(true);
      let payload = {
        data,
        url: "auth/sign-in",
      };
      const res = await apiService.post(payload);
      storageService.setKey(StorageEnums.CREDENTIALS, res);
      navigate("/dashboard");
    } catch (error: any) {
      if (
        error?.response?.data?.message ===
        ErrorMessageEnum.VERIFY_ACCOUNT_TO_CONTINUE
      ) {
        toast.error(error?.response?.data?.message);
        navigate(`/confirm-email/${data?.email}`);
        return;
      }
      toast.error(error?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div className={style.auth}>
      <div className={style.auth__wrap}>
        <h1>Login</h1>
        <form onSubmit={handleSubmit(handleLogin)} noValidate>
          <Input
            type="text"
            placeholder="Email"
            register={register}
            registerName="email"
            errorMessage={errors?.email?.message}
          />
          <Input
            type="password"
            placeholder="Password"
            register={register}
            registerName="password"
            errorMessage={errors?.password?.message}
          />
          <div className={style.auth__forgot}>
            <a onClick={() => navigate("/reset-password")}>Forgot password?</a>
          </div>
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            label="Log in"
            buttonClass="isFull"
          />
        </form>

        <div className={style.auth__infoText}>
          No account?
          <a onClick={() => navigate("/signup")}> Sign up</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
