import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import style from "./Skeleton.module.scss";

const SkeletonLoader = ({
  width = "100%",
  height = "12px",
  margin = "0px",
  circle = false,
  radius = "10px",
  fill = false,
  baseColor = "#EBE8E8",
  highlightColor = "#FFFFFF",
}: {
  width?: string;
  height?: string;
  margin?: string;
  circle?: boolean;
  radius?: string;
  fill?: boolean;
  baseColor?: string;
  highlightColor?: string;
}) => {
  return (
    <div
      className={`${style.skeleton} ${fill && style.isFill}`}
      style={{ margin: margin }}
    >
      <Skeleton
        width={width}
        height={height}
        circle={circle}
        baseColor={baseColor}
        highlightColor={highlightColor}
        style={{ borderRadius: radius }}
      />
    </div>
  );
};
export default SkeletonLoader;
