import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import { avtivitiesList } from "../../atoms/Constants/dropdown.constant";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import Input from "../../atoms/Input/Input";
import { ApiService } from "../../services/apiServices";
import { signUpSchema } from "./signUp.schema";
import { StorageService } from "../../services/storage.service";
import { StorageEnums } from "../../enums/storageEnums";
import { COUNTRIES } from "../../constants/constants";
const apiService = new ApiService();
const storageService = new StorageService();
const SignUp = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const { email, company, code } = useParams();
  const methods = useForm({
    resolver: yupResolver(signUpSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    control,
    reset,
    setValue,
  } = methods;
  useEffect(() => {
    setValue("activity", avtivitiesList[0]);
  }, []);

  const navigate = useNavigate();

  const handleSignUp = async (data: FieldValues) => {
    let payload = {
      email: data.email,
      password: data.password,
      name: data.fullName,
      location: data.location.value,
      company: data.company,
      activity: data.activity.value,
      inviteCode: data.inviteCode ? data.inviteCode : null,
    };
    setLoading(true);
    try {
      let obj = {
        data: payload,
        url: "auth/register",
      };
      let res = await apiService.post(obj);
      navigate(`/confirm-email/${res?.data?.user?.email}`);
    } catch (e: any) {
      console.log(e);
      toast.error(e.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (email && company && code) {
      storageService.setKey(StorageEnums.INVITE_CODE, code);
      reset({
        email,
        company,
        inviteCode: code,
        activity: avtivitiesList[0],
      });
    }
  }, [email, company, code, reset]);
  return (
    <div className={style.auth}>
      <div className={style.auth__wrap}>
        <form onSubmit={handleSubmit(handleSignUp)} noValidate>
          <h1>Sign Up</h1>

          <h4>Personal Info</h4>
          <Input
            type="text"
            placeholder="Full Name"
            register={register}
            registerName="fullName"
            errorMessage={errors?.fullName?.message}
          />
          <Input
            type="text"
            placeholder="Email"
            register={register}
            registerName="email"
            readOnly={email ? true : false}
            errorMessage={errors?.email?.message}
          />
          <Input
            type="password"
            placeholder="Password"
            register={register}
            registerName="password"
            errorMessage={errors?.password?.message}
          />
          <Input
            type="password"
            placeholder="Confirm Password"
            register={register}
            registerName="confirmPassword"
            errorMessage={errors?.confirmPassword?.message}
          />

          <Dropdown
            name="location"
            label="Country"
            error={errors?.location?.message?.toString()}
            options={COUNTRIES}
            control={control}
          />

          <h4 className={style.isInfoTitle}>Company Info</h4>
          <Input
            type="text"
            placeholder="Company Name"
            register={register}
            registerName="company"
            errorMessage={errors?.company?.message}
          />
          <Dropdown
            name="activity"
            isSearchable={false}
            label="Primary activities"
            error={errors?.activity?.message?.toString()}
            options={avtivitiesList}
            control={control}
          />
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            label="Sign Up"
            buttonClass={"isFull"}
          />
        </form>

        <div className={style.auth__infoText}>
          Already have an account?&nbsp;
          <a onClick={() => navigate("/login")}>Log in</a>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
