import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SectionModel } from "../models/project_section.model";
import { BaseUserModel } from "../models/user.model";
interface SectionStateType {
  sections: { [key: string]: SectionModel | undefined };
  sectionsAssignees: { [key: string]: BaseUserModel[] | undefined };
}
const initialState: SectionStateType = {
  sections: {},
  sectionsAssignees: {},
};

const sectionSlice = createSlice({
  name: "section",
  initialState,
  reducers: {
    setSectionDetails: (
      state: SectionStateType,
      action: PayloadAction<SectionModel>
    ) => {
      return {
        ...state,
        sections: { ...state.sections, [action.payload.id]: action.payload },
      };
    },
    resetSection: (state: SectionStateType) => {
      return initialState;
    },
    setSectionAssignees: (
      state: SectionStateType,
      action: PayloadAction<{ sectionId: string; assignees: BaseUserModel[] }>
    ) => {
      return {
        ...state,
        sectionsAssignees: {
          ...state.sectionsAssignees,
          [action.payload.sectionId]: action.payload.assignees,
        },
      };
    },
  },
});

export const { setSectionDetails, resetSection, setSectionAssignees } =
  sectionSlice.actions;
export const getSectionDetails = (
  state: { section: SectionStateType },
  id: string
) => state.section.sections?.[id] ?? undefined;

export const getSectionAssignees = (
  state: { section: SectionStateType },
  id: string
) => state.section.sectionsAssignees?.[id] ?? [];

export default sectionSlice.reducer;
