import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
import style from "../../assets/scss/auth.module.scss";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import { ApiService } from "../../services/apiServices";
import { handleError } from "../../utils/utils";
import { confirmCodeSchema } from "./confirmCode.schema";

const ConfirmCode = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const apiService = new ApiService();
  const { email } = useParams();
  const methods = useForm({
    resolver: yupResolver(confirmCodeSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    clearErrors,
    setError,
  } = methods;

  useEffect(() => {
    if (watch("confirmPassword") === watch("newPassword")) {
      clearErrors("confirmPassword");
    }
  }, [watch("confirmPassword"), watch("newPassword"), setError]);

  const navigate = useNavigate();
  const handleConfirmCode = async (data: FieldValues) => {
    try {
      setLoading(true);
      let payload = {
        data: {
          newPassword: data.newPassword,
          otp: data.uniqueCode.toString(),
        },
        url: "auth/reset-password",
      };
      await apiService.post(payload);
      toast.success("Password Reset successfully");
      navigate("/login");
    } catch (error: any) {
      toast.error(handleError(error));
    } finally {
      setLoading(false);
    }
  };

  const resendCode = async () => {
    try {
      let payload = {
        data: { email },
        url: "auth/request-reset-password",
      };
      await apiService.post(payload);
      toast.success("A confirmation code has been resent.");
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={style.auth}>
      <div className={style.auth__wrap}>
        <h1>Reset password</h1>
        <p>
          A confirmation code has been sent to <strong>{email}</strong>
        </p>

        <form onSubmit={handleSubmit(handleConfirmCode)} noValidate>
          <Input
            type="number"
            placeholder="Confirmation code"
            register={register}
            registerName="uniqueCode"
            errorMessage={errors?.uniqueCode?.message}
          />
          <Input
            type="password"
            placeholder="New password"
            register={register}
            registerName="newPassword"
            errorMessage={errors?.newPassword?.message}
          />
          <Input
            type="password"
            placeholder="Confirm password"
            register={register}
            registerName="confirmPassword"
            errorMessage={errors?.confirmPassword?.message}
          />
          <Button
            loading={loading}
            disabled={loading}
            type="submit"
            buttonClass={"isFull"}
            label={"Reset Password"}
          />
        </form>
        <div className={style.auth__infoText}>
          <a onClick={resendCode}>Resend confirmation code</a>
        </div>
        <div className={style.auth__infoText}>
          <a onClick={() => navigate(-1)}>Back</a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmCode;
