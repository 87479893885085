import * as yup from "yup";

export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old password is required"),

  newPassword: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Password must contain at least 8 characters, a number, a special character, a lowercase character, and an uppercase character"
    ),

  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), ""], "Passwords must match")
    .required("Confirm Password is required"),
});

export const myAccountSchema = yup.object().shape({
  name: yup
    .string()
    .required("Name is required!")
    .transform((value) => value.trim()),
  email: yup.string(),
  activity: yup.object(),
});
