import { HocuspocusProvider } from "@hocuspocus/provider";
import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import Color from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import Paragraph from "@tiptap/extension-paragraph";
import Placeholder from "@tiptap/extension-placeholder";
import Table from "@tiptap/extension-table";
import TableRow from "@tiptap/extension-table-row";
import TaskItem from "@tiptap/extension-task-item";
import TaskList from "@tiptap/extension-task-list";
import TextStyle from "@tiptap/extension-text-style";
import Underline from "@tiptap/extension-underline";
import { EditorView } from "@tiptap/pm/view";
import { findParentNodeClosestToPos, useEditor } from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import * as Y from "yjs";
import { CustomBlock } from "../components/Section/Extensions/CustomBlock/CustomBlockExtension";
import { CustomDocument } from "../components/Section/Extensions/CustomDocument/CustomDocument";
import { CustomTableCell } from "../components/Section/Extensions/CustomTable/CustomTableCell";
import { CustomTableHeader } from "../components/Section/Extensions/CustomTable/CustomTableHeader";
import { ResizableMedia } from "../components/Section/Extensions/ImageBlock/resizableMedia";
import { TaskListBlock } from "../components/Section/Extensions/TaskListBlock/TaskListBlock";
import { TextBlock } from "../components/Section/Extensions/TextBlock/TextBlockExtension";
import { ModuleType } from "../components/Section/editor.enum";
import { ProjectUserRoleEnum, StorageEnums } from "../enums/storageEnums";
import { StorageService } from "../services/storage.service";
import { getProjectById } from "../store/projectSlice";
import { selectUserDetails } from "../store/userDetailsSlice";
import { getTablecell } from "../utils/utils";
const storageService = new StorageService();

const useEditorCustom = (
  handleTextChange: (text: string) => void,
  sectionId: string,
  template: Record<string, any> | null,
  color: string,
  handleStatusUpdate:(id: string) => void,
) => {
  const showMenu = useRef<boolean>(false);
  const [showLinkModal, setShowLinkModal] = useState<boolean>(false);
  const doc = useMemo(() => {
    return new Y.Doc();
  }, [sectionId]);
  const { id } = useParams();
  const userDetails = useSelector(selectUserDetails);
  const [connectionLoading, setConnectionLoading] = useState(true);
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const provider = useRef<any>(
    new HocuspocusProvider({
      url: process.env.REACT_APP_COLLABORATION_IP ?? "",
      name: `${id}_${sectionId}`,
      token: storageService.getKey(StorageEnums.CREDENTIALS)?.data?.accessToken,
      connect: false,
      preserveConnection: false,
      parameters: {
        projectId: id,
        sectionId: sectionId,
      },
      document: doc,
      onClose:()=>{setConnectionLoading(false)},
      onDestroy:()=>{setConnectionLoading(false)},
    })
  ).current;

  const editor = useEditor({
    extensions: [
      StarterKit,
      CustomDocument,
      CustomBlock,
      Paragraph.extend({
        addAttributes() {
          return {
            ...(project.role !== ProjectUserRoleEnum.VIEWER
              ? { contenteditable: { default: true } }
              : {}),
            selectable: { default: false },
          };
        },
      }),
      Link.extend({
        addOptions() {
          return {
            openOnClick: true,
            linkOnPaste: true,
            autolink: true,
            protocols: [],
            HTMLAttributes: {
              target: "_blank",
              rel: "noopener noreferrer nofollow",
              class: null,
            },
            validate: undefined,
            onModKPressed: () => setShowLinkModal && setShowLinkModal(true),
          };
        },
        addKeyboardShortcuts() {
          return {
            "Shift-Enter": () => {
              return true;
            },
            "Mod-Shift-x": () => {
              this.editor.chain().focus().toggleStrike().run();
              return false;
            },
            "Mod-a": (e) => {
              let res = true;
              e.editor.state.doc.descendants((node, pos) => {
                const to = node.content.size + pos;
                const from = pos + 1;
                if (
                  e.editor.state.selection.anchor <= to &&
                  from <= e.editor.state.selection.anchor
                ) {
                  if (node.attrs.childModule === "table") {
                    res = false;
                  } else {
                    e.editor.commands.setTextSelection({
                      from: from + 2,
                      to: to - 1,
                    });
                  }
                }
                return false;
              });
              return res;
            },
            // "Mod-c": (e) => {
            //   copyFunction();
            //   return false;
            // },
            "Mod-k": ({ editor }) => {
              if (!editor.state.selection.empty) {
                this.options?.onModKPressed?.();
              }
              return false;
            },
          };
        },
      }),
      Table.extend({
        addOptions() {
          return {
            resizable: true,
          };
        },
        addKeyboardShortcuts() {
          return {
            "Mod-a": ({ editor }) => {
              const { selection } = editor.state;
              let tableNodePosition = 0;
              editor.state.doc.descendants((node, pos) => {
                const to = node.content.size + pos;
                const from = pos + 1;
                if (
                  editor.state.selection.anchor <= to &&
                  from <= editor.state.selection.anchor
                ) {
                  tableNodePosition = pos;
                }
                return false;
              });
              const table = findParentNodeClosestToPos(
                selection.ranges[0].$from,
                (node) => {
                  return node.type.name === "customBlock";
                }
              );
              table?.node.descendants((node, pos) => {
                const to = node.content.size + pos + tableNodePosition + 1;
                const from = tableNodePosition + pos + 1;
                if (
                  editor.state.selection.anchor <= to &&
                  from <= editor.state.selection.anchor
                ) {
                  if (["tableCell", "tableHeader"].includes(node.type.name)) {
                    if (node.content.size > 2) {
                      editor.commands.setTextSelection({
                        from: from + 2,
                        to: node.content.toString().endsWith("paragraph>")
                          ? to - 1
                          : to,
                      });
                    }
                  }
                }
              });
              return true;
            },
            Backspace: ({ editor }) => {
              const { selection } = editor.state;
              let tableNodePosition = 0;
              editor.state.doc.descendants((node, pos) => {
                const to = node.content.size + pos;
                const from = pos + 1;
                if (
                  editor.state.selection.anchor <= to &&
                  from <= editor.state.selection.anchor
                ) {
                  tableNodePosition = pos;
                }
                return false;
              });
              const table = findParentNodeClosestToPos(
                selection.ranges[0].$from,
                (node) => {
                  return node.type.name === "customBlock";
                }
              );
              let res = false;
              table?.node.descendants((node, pos) => {
                const to = node.content.size + pos + tableNodePosition + 1;
                const from = tableNodePosition + pos + 1;
                if (
                  editor.state.selection.anchor <= to &&
                  from <= editor.state.selection.anchor
                ) {
                  if (["tableCell", "tableHeader"].includes(node.type.name)) {
                    res = node.attrs.contenteditable === false;
                    if (!res) {
                      if (!editor.state.selection.empty) {
                        editor.commands.deleteSelection();
                        editor.commands.focus(from + 1);
                      }
                    }
                  }
                }
              });
              return res;
            },
          };
        },
        parseHTML() {
          return [{ tag: "table" }];
        },
        addAttributes() {
          return {
            class: { default: "table-block" },
          };
        },
      }),
      TableRow,
      CustomTableHeader,
      CustomTableCell,
      TextStyle,
      Color,
      TextBlock,
      TaskListBlock,
      TaskList.extend({
        selectable: false,
        addAttributes() {
          return { contenteditable: { default: true } };
        },
        addKeyboardShortcuts() {
          return {
            "Shift-Enter": () => {
              this.editor.chain().focus().toggleTaskList().run();
              return false;
            },
          };
        },
      }),
      TaskItem.configure({
        nested: true,
      }).extend({ selectable: false }),
      Underline,
      ResizableMedia,
      Collaboration.configure({
        document: provider.document,
      }),
      CollaborationCursor.configure({
        provider: provider,
        user: { name: userDetails?.name, color: color },
      }),
      Placeholder.configure({
        placeholder: "Start typing here…",
        emptyEditorClass: "is-editor-empty",
        considerAnyAsEmpty: true,
        showOnlyWhenEditable: true,
        showOnlyCurrent: false,
        includeChildren: true,
      }),
    ],

    editorProps: {
      handleKeyPress(view: EditorView, event: any) {
        const contentJson = view?.state.selection.content().content.toJSON();
        if (
          (contentJson?.[0]?.type !== "tableRow" &&
            (contentJson?.length > 1 ||
              (contentJson?.[0]?.type === ModuleType.IMAGE &&
                event.key !== "v" &&
                !(event.metaKey || event.ctrlKey)))) ||
          (contentJson?.[0]?.type === "taskItem" &&
            !contentJson[0].content[0].attrs.contenteditable) ||
          contentJson?.[0]?.attrs?.contenteditable === false ||
          contentJson?.[0]?.content?.[0]?.attrs?.contenteditable === false
        ) {
          return true;
        }
        return false;
      },
      handleKeyDown(view, event) {
        const contentJson = view?.state.selection.content().content.toJSON();
        if (
          (contentJson?.[0]?.type !== "tableRow" &&
            (contentJson?.length > 1 ||
              (contentJson?.[0]?.type === ModuleType.IMAGE &&
                event.key !== "v" &&
                !(event.metaKey || event.ctrlKey)))) ||
          (contentJson?.[0]?.type === "taskItem" &&
            !contentJson?.[0]?.content?.[0]?.attrs?.contenteditable) ||
          contentJson?.[0]?.attrs?.contenteditable === false ||
          contentJson?.[0]?.content?.[0]?.attrs?.contenteditable === false
        ) {
          return true;
        }
        return false;
      },
      handlePaste(view: EditorView, event: ClipboardEvent) {
        const contentJson = view?.state.selection.content().content.toJSON();
        if (
          contentJson?.[0]?.type === ModuleType.IMAGE ||
          contentJson?.[0]?.attrs?.childModule === ModuleType.IMAGE
        ) {
          return true;
        }
      },
    },

    onSelectionUpdate({ editor, transaction }) {
      const type =
        transaction.selection.content().content.toJSON()?.[0]?.content?.[0]
          ?.type ?? transaction.selection.content().content.toJSON()?.[0]?.type;
      let tabelCell = undefined;
      if (
        transaction?.selection?.content?.()?.content.toJSON()?.[0]?.attrs
          ?.childModule === "table"
      ) {
        tabelCell = getTablecell(
          transaction?.selection?.content()?.content?.toJSON()?.[0]
        );
      }
      if (
        transaction.selection.content().content.toJSON()?.length > 1 ||
        type === ModuleType.IMAGE ||
        type === ModuleType.SURVEY ||
        (type === "paragraph" &&
          transaction?.selection?.content?.()?.content?.toJSON()?.[0]
            ?.content?.[0]?.attrs?.contenteditable === false) ||
        transaction?.selection?.content?.()?.content?.toJSON()?.[0]
          ?.content?.[0]?.content?.[0]?.attrs?.contenteditable === false ||
        (tabelCell && tabelCell.attrs?.contenteditable === false)
      ) {
        showMenu.current = false;
      } else {
        showMenu.current = true;
      }
    },

    onUpdate: ({ editor }) => {
      if(!connectionLoading&&project.role !== ProjectUserRoleEnum.VIEWER){
        handleStatusUpdate(sectionId);
      }
      handleTextChange(editor.getText() || "");
    },
    editable: project.role !== ProjectUserRoleEnum.VIEWER,
  });
  // const copyFunction = async () => {
  //   const text = await navigator.clipboard.readText();
  //   await navigator.clipboard.writeText(text);
  // };
  useEffect(() => {
    if (editor && provider) {
      setConnectionLoading(true);
      provider.connect();
      provider.on("synced", () => {
        editor?.commands.setContent(template);
        handleTextChange(editor?.getText() || "");
        setConnectionLoading(false);
      });
    }
    return () => {
      provider?.disconnect();
    };
  }, [editor, template]);

  return {
    editor,
    showMenu,
    showLinkModal,
    setShowLinkModal,
    connectionLoading,
  };
};
export default useEditorCustom;
