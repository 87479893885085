import { useState } from "react";
import Button from "../../atoms/Button/Button";
import TagInput from "../../atoms/TagInput/TagInput";
import Modal from "../../components/Modal/Modal";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { teamMemberSchema } from "./teamMember.schema";
import { toast } from "react-toastify";
import { ProjectUserRoleEnum } from "../../enums/storageEnums";
import { ApiService } from "../../services/apiServices";
const apiService = new ApiService();
const AddMemberModal = ({ open, setOpen, projectId, getTeamMembers}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<{ tag: string; dropdownValue: string }[]>(
    []
  );
  const { control } = useForm({
    resolver: yupResolver(teamMemberSchema),
  });

  const handleInviteUser = async () => {
    if (tags.length === 0) {
      toast.error("Please enter the email to invite.");
      return;
    }
    try {
      setLoading(true);
      const invitations = {
        invitations: tags.map(({ tag, dropdownValue }) => ({
          email: tag,
          role:
            dropdownValue === ""
              ? ProjectUserRoleEnum.EDITOR
              : dropdownValue?.toLowerCase(),
        })),
      };

      await apiService.post({
        data: invitations,
        url: `projects/${projectId}/users`,
      });
      await getTeamMembers();
      toast.success("Invite sent successfully.");
    } catch (error) {
      console.error(error);
    } finally {
      setOpen(false);
      setLoading(false);
    }
  };
  return (
    <Modal
      showModal={open}
      showModalFunction={setOpen}
      title="Add team member"
      footer={
        <>
          <Button
            label="Cancel"
            buttonClass="outline"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Button loading={loading} label="Invite" onClick={handleInviteUser} />
        </>
      }
    >
      {open && (
        <TagInput
          control={control}
          name="inviteEmails"
          onTagsChange={setTags}
        />
      )}
    </Modal>
  );
};
export default AddMemberModal;
