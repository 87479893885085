import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button/Button";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import Input from "../../atoms/Input/Input";
import style from "./AddProject.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import {
  methodologyList,
  registryList,
  templateList,
} from "../../atoms/Constants/dropdown.constant";
import TagInput from "../../atoms/TagInput/TagInput";
import { COUNTRIES } from "../../constants/constants";
import { ProjectUserRoleEnum } from "../../enums/storageEnums";
import { ChatModel } from "../../models/chatModel";
import { ApiService } from "../../services/apiServices";
import { OpenAiService } from "../../services/openAIService";
import { StorageService } from "../../services/storage.service";
import { addChat, showChatModal } from "../../store/chatSlice";
import { selectUserDetails } from "../../store/userDetailsSlice";
import { addProjectSchema1, addProjectSchema2 } from "./addProject.schema";

// api service
const openAiService = new OpenAiService();
const apiService = new ApiService();
const storageService = new StorageService();
const AddProject = ({
  close,
  setGetProjects,
}: {
  close?: any;
  setGetProjects?: any;
}) => {
  // states

  const [next, setNext] = useState(false);
  const [addProjectLoading, setAddProjectLoading] = useState<boolean>(false);
  const [tags, setTags] = useState<{ tag: string; dropdownValue: string }[]>(
    []
  );
  const dispatch = useDispatch();

  const userDetails = useSelector(selectUserDetails);
  // useForm
  const addProjectForm1 = useForm({
    resolver: yupResolver(addProjectSchema1),
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const {
    handleSubmit: addProjectForm1HandleSubmit,
    formState: {
      errors: addProjectForm1Errors,
      isValid: addProjectForm1isvalid,
    },
    register: addProjectForm1Register,
    control: addProjectForm1Control,
    getValues: addProjectForm1GetValues,
    setValue,
  } = addProjectForm1;
  useEffect(() => {
    setValue("template", templateList[0]);
  }, []);
  const addProjectForm2 = useForm({
    resolver: yupResolver(addProjectSchema2),
  });

  const {
    handleSubmit: addProjectForm2HandleSubmit,
    control: addProjectForm2Control,
  } = addProjectForm2;

  //functions
  const addProjectStep1 = () => {
    setNext(true);
  };
  const addProjectStep2 = async (collectedEmails: any) => {
    try {
      const projectDetails = await addProjectSubmit();
      const inviteUserData = collectedEmails.map((tag: any) => ({
        email: tag.tag,
        role: tag.dropdownValue?.toLowerCase(),
      }));

      const invitations = {
        invitations: inviteUserData,
      };
      setGetProjects(true);
      await inviteUserByEmail(invitations, projectDetails?.data?.project?.id);
      toast.success("Project added successfully.");
      navigate(`/dashboard/details/${projectDetails?.data?.project?.id}`);
    } catch (error) {
      console.error("Error in addProjectStep2:", error);
    } finally {
      setGetProjects(false);
      close(false);
    }
  };

  const inviteUserByEmail = async (data: any, id: string) => {
    try {
      setAddProjectLoading(true);
      let obj = {
        data: data,
        url: `projects/${id}/users`,
      };
      await apiService.post(obj);
      closeDropDown();
    } catch (e) {
      console.log(e);
    } finally {
      setAddProjectLoading(false);
    }
  };

  const addProjectSubmit = async () => {
    setAddProjectLoading(true);
    const {
      name,
      methodology,
      registry,
      location,
      description,
      template,
    }: any = addProjectForm1GetValues();

    try {
      let payload = {
        name: name,
        methodology: methodology?.value,
        registry: registry?.value,
        location: location?.label,
        description: description == "" ? null : description,
        template: template.value,
      };
      let obj = {
        data: payload,
        url: "projects",
      };

      // openChatBot(location.label, location.methodology);
      let response = await apiService.post(obj);
      return response;
    } catch (e: any) {
      toast.error(e.response.data.message[0]);
      setAddProjectLoading(false);
    }
  };

  const openChatBot = async (location: string, methodology: string) => {
    try {
      const message = `Context: I'm developing a carbon offset 
      project in ${location}. 
      
      Prompt: Please provide 3 fun facts about ${location}. 
      These facts should be related to the environment or sustainability 
      and should be lighthearted and optimistc. 
      
      Output: start your response with "Congrats on starting a new 
      project in beautiful ${location}!" Followed by a 
      line break and then "Did you know..." followed by your bulleted 
      response.

      Output fewer than 250 words. End your response with "Hope you
      found these as interesting as I did!" followed by a line
      break and then "Feel free to ask me anything about your
      project. I'm here to help!`;
      const payload = {
        message: message,
        prompt: message,
        isVisible: false,
      };
      const res = await openAiService.sendMessage(payload);
      const messages = (res.data.messages ?? []).map(
        (_: ChatModel) => new ChatModel(_)
      );
      dispatch(showChatModal(true));
      dispatch(addChat(messages));
    } catch (error) {}
  };

  const addProjectHandler = async (e: any) => {
    e.preventDefault();
    try {
      if (!next) {
        addProjectForm1HandleSubmit(addProjectStep1)();
      } else {
        const selectedValues = tags.map((tag) =>
          tag.dropdownValue === ""
            ? { ...tag, dropdownValue: ProjectUserRoleEnum.EDITOR }
            : tag
        );
        addProjectForm2HandleSubmit(() => addProjectStep2(selectedValues))();
      }
    } catch (e: any) {
      console.log(e.response?.data?.message ?? e?.message);
    }
  };

  const closeDropDown = () => {
    if (next) {
      setNext(false);
    } else {
      close(false);
    }
  };

  return (
    <form className={style.addProject}>
      <div className={`${style.addProject__step} flex align-center`}>
        <div
          className={`${style.addProject__stepItem} ${!next && style.active} ${
            next && style.previous
          } flex align-center`}
        >
          <span>1</span>
          <p>Project Info</p>
        </div>
        <div className={style.addProject__stepDivider}></div>
        <div
          className={`${style.addProject__stepItem} ${
            next && style.active
          } flex align-center`}
        >
          <span>2</span>
          <p>Team Members</p>
        </div>
      </div>
      {!next && (
        <>
          <div className={`${style.addProject__row} flex-wrap`}>
            <div className={style.addProject__col}>
              <Input
                type="text"
                placeholder="Project Name"
                register={addProjectForm1Register}
                registerName="name"
                errorMessage={addProjectForm1Errors?.name?.message}
              />
            </div>
            <div className={style.addProject__col}>
              <Dropdown
                name="location"
                label="Project Location"
                options={COUNTRIES}
                control={addProjectForm1Control}
                error={addProjectForm1Errors?.location?.message}
              />
            </div>
            <div className={style.addProject__col}>
              <Dropdown
                name="registry"
                label="Project Registry"
                options={registryList}
                control={addProjectForm1Control}
                error={addProjectForm1Errors?.registry?.message}
              />
            </div>
            <div className={style.addProject__col}>
              <Dropdown
                name="methodology"
                label="Project Methodology"
                options={methodologyList}
                control={addProjectForm1Control}
                error={addProjectForm1Errors?.methodology?.message}
              />
            </div>
          </div>
          <Dropdown
            name="template"
            label="Template"
            options={templateList}
            control={addProjectForm1Control}
            error={addProjectForm1Errors?.template?.message}
          />

          <Input
            type="textArea"
            placeholder="Project Description (optional)"
            register={addProjectForm1Register}
            registerName="description"
          />
        </>
      )}

      {next && (
        <>
          <TagInput
            control={addProjectForm2Control}
            name="inviteEmails"
            onTagsChange={setTags}
          />
        </>
      )}
      <div className={`${style.addProject__footer} flex`}>
        <div className={style.addProject__cencel}>
          <Button
            label={!next ? "Cancel" : "Back"}
            buttonClass="isFull outline"
            onClick={closeDropDown}
          />
        </div>
        <div className={style.addProject__continue}>
          <Button
            label={!next ? "Continue" : "Create Project"}
            loading={addProjectLoading}
            onClick={(e: any) => {
              addProjectHandler(e);
            }}
            type="submit"
            buttonClass="isFull"
          />
        </div>
      </div>
    </form>
  );
};
export default AddProject;
