import TableHeader from "@tiptap/extension-table-header";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { TableCellNodeView } from "./TableCellNodeView";

export const CustomTableHeader = TableHeader.extend({
  addNodeView() {
    return (props) => {
      return ReactNodeViewRenderer(TableCellNodeView, {
        as: "th",
        className: "relative",
        attrs: { ...props.node.attrs },
        update: (prop) => {
          return JSON.stringify(prop.oldNode)===JSON.stringify(prop.newNode);
        }
      })(props);
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "th",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      ...this.parent?.(),
      contenteditable: { default: true },
      class: { default: null },
    };
  },
  isolating: true,
});
