import { ApiDataObject } from "../interfaces/interface";
import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class ProjectService {
  getProject(id: string) {
    const obj = {
      url: `projects/${id}`,
    };
    return apiService.get(obj);
  }
  getProjectLogs(id: string) {
    const obj = {
      url: `projects/${id}/logs`,
    };
    return apiService.get(obj);
  }
  fetchProjectSectionList(projectId: string) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/list`,
    };
    return apiService.get(obj);
  }
}
