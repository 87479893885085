import Tippy from "@tippyjs/react";
import debounce from "lodash.debounce";
import { useCallback, useEffect, useRef, useState } from "react";
import "../../../../assets/scss/tippy-box.scss";
import {
  SectionAnalyticsModel,
  SectionModel,
} from "../../../../models/project_section.model";
import { SectionService } from "../../../../services/section.service";
import style from "./SectionAnalytics.module.scss";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";

const sectionService = new SectionService();
interface SectionAnalyticsProps {
  section: SectionModel;
  text: string;
  role: string;
}

const SectionAnalytics = ({ text, section, role }: SectionAnalyticsProps) => {
  const debounceProcessText = useRef<any>();

  const [sectionAnalytics, setSectionAnalytics] =
    useState<SectionAnalyticsModel>(
      new SectionAnalyticsModel({
        dataDensity: +(section.dataDensity || 0),
        readability: +(section.readability ?? 0),
        sentimentCount: +(section.sentimentCount ?? 0),
        subjectivityCount: +(section.subjectivityCount ?? 0),
        wordCount: +(section.wordCount ?? 0),
        character_count: 0,
      })
    );

  const saveDataToBackend = useCallback(
    async (payload: SectionAnalyticsModel) => {
      try {
        await sectionService.updateSectionStatus(
          section.projectId,
          section.id,
          payload
        );
      } catch (error) {}
    },
    [section.id, section.projectId]
  );

  const processText = useCallback(
    async (text: string) => {
      try {
        const res = await sectionService.processText({ text });
        setSectionAnalytics(new SectionAnalyticsModel(res.data));
        if (role !== ProjectUserRoleEnum.VIEWER) {
          saveDataToBackend(new SectionAnalyticsModel(res.data));
        }
      } catch (error) {}
    },
    [saveDataToBackend]
  );

  useEffect(() => {
    debounceProcessText.current?.cancel();

    debounceProcessText.current = debounce(() => {
      processText(text);
    }, 1000);

    debounceProcessText.current();
  }, [text, processText]);
  return (
    <div className={style.analytics}>
      <div className={style.analytics__content}>
        <h4>
          Section{" "}
          {section?.isAppendix
            ? section.name
            : section.name.split(" ")?.[0] || ""}
          : Text Analysis
        </h4>
        <div className={style.analytics__scroll}>
          <div className={`${style.analytics__row} flex-wrap`}>
            {/* WORD COUNT */}
            <Tippy
              content={
                <article className="isShow">
                  <p>Word Count measures the total words in your section.</p>
                  <p>
                    Our recommended range for this section is +/- 20% from the
                    median word count of similar PDDs in the Verra Registry.
                  </p>
                  <p>
                    Adhering to this range ensures your document length aligns
                    with industry standards.
                  </p>
                </article>
              }
            >
              <div className={style.analytics__item}>
                <strong>
                  Word Count: {sectionAnalytics.wordCount.toLocaleString()}
                </strong>
                <p>Recommended: {section.recommendWordCount || "-"}</p>
              </div>
            </Tippy>

            {/* Readability Score */}
            <Tippy
              content={
                <article className="isShow">
                  <p>
                    Readability, calculated on the Flesch- Kincaid scale, is the
                    ease at which a reader can understand a written document.
                  </p>
                  <p>
                    Our recommended reading level for this section is the median
                    reading level of similar PDDs in the Verra Registry.
                  </p>
                  <p>
                    Aligning with the industry average ensures your document
                    balances readability and complexity effectively.
                  </p>
                </article>
              }
            >
              <div className={style.analytics__item}>
                <strong>
                  Readability:
                  {text.length > 0 ? (
                    <>
                      {" "}
                      Grade {Math.round(Number(sectionAnalytics.readability))}
                    </>
                  ) : (
                    " N/A"
                  )}
                </strong>
                <p>Recommended: {section.recommendReadability || "-"}</p>
              </div>
            </Tippy>

            {/* Data Density */}
            <Tippy
              content={
                <article className="isShow">
                  <p>
                    Data Density, calculated as digit characters / total
                    characters, quantifies the prevalence of data within your
                    text.
                  </p>
                  <p>
                    Our recommended range for this section is +/- one standard
                    deviation from the mean data density of similar PDDs in the
                    Verra Registry.
                  </p>
                  <p>
                    Adhering to this range helps ensure your document balances
                    narrative and data effectively.
                  </p>
                </article>
              }
            >
              <div className={style.analytics__item}>
                <strong>
                  Data Density:{" "}
                  {Number(sectionAnalytics.dataDensity).toFixed(1)}%
                </strong>
                <p>Recommended: {section.recommendDataDensity || "-"}</p>
              </div>
            </Tippy>

            {/* Sentiment Score */}
            <Tippy
              content={
                <article className="isShow">
                  <p>
                    Sentiment Score measures the emotional tone of your text,
                    ranging from -100 (negative sentiment) to +100 (positive
                    sentiment).
                  </p>
                  <p>
                    Our recommended range for this section is +/- one standard
                    deviation from the mean sentiment score of similar PDDs in
                    the Verra Registry.
                  </p>
                  <p>
                    Adhering to this range helps ensure that the tone of each
                    section is neither overly optimistic nor excessively
                    pessimistic. Keep in mind that a negative tone isn't
                    necessarily a bad thing and may be necessary to clearly
                    communicate the risks associated with your project.
                  </p>
                </article>
              }
            >
              <div className={style.analytics__item}>
                <strong>
                  Sentiment Score:{" "}
                  {text.length > 0 ? (
                    <>
                      {sectionAnalytics.sentimentCount > 0 ? "+" : ""}
                      {Math.round(
                        Number(sectionAnalytics.sentimentCount) * 100
                      )}
                    </>
                  ) : (
                    " N/A"
                  )}
                </strong>
                <p>Recommended: {section.recommendSentimentRange || "-"}</p>
              </div>
            </Tippy>

            {/* Subjectivity Score */}
            <Tippy
              content={
                <article className="isShow">
                  <p>
                    Subjectivity Score quantifies the presence of personal
                    opinions vs. factual information in your text, ranging from
                    0 (very objective) to 100 (very subjective).
                  </p>
                  <p>
                    Our recommended range for this section in +/- one standard
                    deviation from the mean subjectivity score of similar PDDs
                    in the Verra Registry.
                  </p>
                  <p>
                    Adhering to this range ensures a balanced presentation of
                    facts and opinions.
                  </p>
                </article>
              }
            >
              <div className={style.analytics__item}>
                <strong>
                  Subjectivity Score:{" "}
                  {text.length > 0 ? (
                    <>
                      {Math.round(
                        Number(sectionAnalytics.subjectivityCount) * 100
                      )}
                    </>
                  ) : (
                    " N/A"
                  )}
                </strong>
                <p>Recommended: {section.recommendSubjectivityRange || "-"}</p>
              </div>
            </Tippy>
          </div>
        </div>
      </div>
    </div>
  );
};
export default SectionAnalytics;
