import style from "./RightSideDrawer.module.scss";
import closeIcon from "../../assets/images/close.svg";

export interface Props {
  title: string;
  show: boolean;
  padding?: boolean;
  onClose?: () => void;
}

const RightSideDrawer = ({
  title,
  children,
  show = false,
  padding = true,
  setSelectedTab,
  onClose = () => {},
}: any) => {
  const handleClose = () => {
    setSelectedTab("");
    const el = document.getElementsByTagName("body")[0];
    el?.classList.remove("rightSideBarOpen");
    onClose && onClose();
  };
  return (
    <div
      className={`${style.rightDrawer} ${!padding && style.noPadding} ${
        show && style.active
      }`}
    >
      <header
        className={`${style.rightDrawer__header} flex align-center justify-between`}
      >
        <h2>{title}</h2>
        <img
          onClick={handleClose}
          className={style.isClose}
          src={closeIcon}
          alt="Close"
        />
      </header>
      <div className={style.rightDrawer__content}>{children}</div>
    </div>
  );
};
export default RightSideDrawer;
