import TableCell from "@tiptap/extension-table-cell";
import { ReactNodeViewRenderer, mergeAttributes } from "@tiptap/react";
import { TableCellNodeView } from "./TableCellNodeView";

export const CustomTableCell = TableCell.extend({
  tableRole: "cell",

  isolating: true,

  content: "block+",

  parseHTML() {
    return [{ tag: "td" }];
  },
  addAttributes() {
    return {
      colspan: {
        default: 1,
      },
      rowspan: {
        default: 1,
      },
      contenteditable: { default: true },
      ...this.parent?.(),
      colwidth: {
        default: [parseInt("120", 10)],
        parseHTML: (element) => {
          const colwidth = element.getAttribute("colwidth");
          const value = colwidth ? [parseInt(colwidth, 10)] : null;

          return value;
        },
      },
      class: { default: null },
      backgroundColor: {
        default: "#fff",
        parseHTML: (element) => element.getAttribute("data-background-color"),
        renderHTML: (attributes) => {
          return {
            "data-background-color": attributes.backgroundColor,
            style: `background-color: ${attributes.backgroundColor}`,
          };
        },
      },
    };
  },
  addNodeView() {
    return (props) => {
      return ReactNodeViewRenderer(TableCellNodeView, {
        as: "td",
        className: "relative",
        attrs: { ...props.node.attrs },
        update: (prop) => {
          return JSON.stringify(prop.oldNode)===JSON.stringify(prop.newNode);
        }
      })(props);
    };
  },
  renderHTML({ HTMLAttributes }) {
    return [
      "td",
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },
});
