export class ShortSectionModel {
  id: string;
  projectId: string;
  name: string;
  order: number | null;
  dueDate: string;
  status: string;
  recommendWordCount: string;
  recommendReadability: string;
  recommendDataDensity: string;
  recommendSentimentRange: string;
  recommendSubjectivityRange: string;
  wordCount: string;
  readability: string;
  dataDensity: string;
  sentimentCount: string;
  subjectivityCount: string;
  isAppendix:boolean;

  constructor(data: ShortSectionModel) {
    this.id = data.id;
    this.projectId = data.projectId;
    this.name = data.name;
    this.order = data.order;
    this.dueDate = data.dueDate;
    this.status = data.status;
    this.recommendWordCount = data.recommendWordCount || "";
    this.recommendReadability = data.recommendReadability || "";
    this.recommendDataDensity = data.recommendDataDensity || "";
    this.recommendSentimentRange = data.recommendSentimentRange || "";
    this.recommendSubjectivityRange = data.recommendSubjectivityRange || "";
    this.wordCount = data.wordCount;
    this.readability = data.readability;
    this.dataDensity = data.dataDensity;
    this.sentimentCount = data.sentimentCount;
    this.subjectivityCount = data.subjectivityCount;
    this.isAppendix=data.isAppendix;
  }
}

export class SectionModel extends ShortSectionModel {
  template?: Record<string, any> | null;
  children: SectionModel[];
  instruction?: Record<string, any> | null;
  chapter: {
    id: string;
    name: string;
  };

  constructor(data: SectionModel) {
    super(data);
    this.template = data.template;
    this.children = data.children.map((_) => new SectionModel(_));
    this.instruction = data.instruction;
    this.chapter = data.chapter;
  }
}

export class SectionAnalyticsModel {
  character_count: number = 0;
  dataDensity: number = 0;
  readability: number = 0;
  sentimentCount: number = 0;
  subjectivityCount: number = 0;
  wordCount: number = 0;
  constructor(obj: {
    character_count: number;
    dataDensity: number;
    readability: number;
    sentimentCount: number;
    subjectivityCount: number;
    wordCount: number;
  }) {
    this.character_count = obj.character_count;
    this.dataDensity = obj.dataDensity;
    this.readability = obj.readability;
    this.sentimentCount = obj.sentimentCount;
    this.subjectivityCount = obj.subjectivityCount;
    this.wordCount = obj.wordCount;
  }
}
