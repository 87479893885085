import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroll-component";
import { Mention, MentionsInput } from "react-mentions";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { toast } from "react-toastify";
import DeleteIcon from "../../assets/images/delete-outline.svg";
import editIcon from "../../assets/images/edit.png";
import more from "../../assets/images/more.svg";
import UserImage from "../../assets/images/profile-img.svg";
import sendIcon from "../../assets/images/send.svg";
import Button from "../../atoms/Button/Button";
import DropdownMenu from "../../atoms/DropdownMenu/DropdownMenu";
import CustomModal from "../../components/CustomModal/CustomModal";
import RightSideDrawer from "../../components/RightSideDrawer/RightSideDrawer";
import { TeamMemberDetails } from "../../components/Section/editor.enum";
import { ChatModel } from "../../models/chatModel";
import { CommentModel } from "../../models/comment.model";
import { ApiService } from "../../services/apiServices";
import { CommentsService } from "../../services/comments.service";
import { OpenAiService } from "../../services/openAIService";
import { addChat, showChatModal } from "../../store/chatSlice";
import {
  addComment,
  deleteCommentState,
  emitDeleteComment,
  getCommentsCount,
  getCommentsState,
  setComments,
  updateComment,
} from "../../store/commentsSlice";
import { getProjectById } from "../../store/projectSlice";
import { selectUserDetails } from "../../store/userDetailsSlice";
import { timeConverter } from "../../utils/utils";
import CommentContent from "./CommentContent";
import style from "./Comments.module.scss";
import CommentsLoader from "./CommentsLoader";
import { commentSchema } from "./comment.schema";

const apiService = new ApiService();
const commentsService = new CommentsService();
const openAiService = new OpenAiService();

const getFinalData = (data: FieldValues) => {
  const regex = /@\[([^\]]+)\]\((\d+)\)/g;
  const commentWithoutTags = data?.comment?.replace(regex, "$1");
  const tags = [];
  let match;
  while ((match = regex.exec(data.comment)) !== null) {
    tags.push({
      email: match[1],
      userId: match[2],
    });
  }

  const finalData = {
    comment: commentWithoutTags,
    tags: tags,
  };
  return finalData;
};

const Comments = ({
  show = false,
  setSelectedTab,
  sectionId = "",
  moduleId,
}: {
  show: boolean;
  setSelectedTab: any;
  sectionId: string;
  moduleId?: string;
}) => {
  const methods = useForm({
    resolver: yupResolver(commentSchema),
  });
  const methodsForEditComment = useForm({
    resolver: yupResolver(commentSchema),
  });
  const { handleSubmit, reset, control } = methods;

  const comments = useSelector(getCommentsState);
  const count = useSelector(getCommentsCount);
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [commentDetails, setCommentDetails] = useState<CommentModel>();
  const [isConfirmationModalVisible, setConfirmationModalVisible] =
    useState<boolean>(false);
  const [loading, setLoading] = useState({
    loadingForComments: false,
    loadingForbutton: false,
    loadingForDeleteButton: false,
  });

  const [editCommentId, setEditCommentId] = useState("-1");
  const [openMenuId, setOpenMenuId] = useState("");

  const { id } = useParams();
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const userDetails = useSelector(selectUserDetails);

  const deletedComment = useSelector(deleteCommentState);
  const [teamMembers, setTeamMembers] = useState<TeamMemberDetails[]>([]);
  const toggleEdit = (commentId: string, comment: string) => {
    let commentText = comment;
    mentions.forEach((el: any, index: number) => {
      commentText = commentText.replaceAll(
        el.display,
        `@[${el.display}](${el.id})`
      );
    });
    methodsForEditComment.reset({ comment: commentText });
    setEditCommentId(commentId);
  };

  const openChatBot = async (name: string) => {
    try {
      const message = `Context: A user has been tagged in a comment within the application for our carbon credit project located in ${
        project?.location || ""
      }, following methodology ${
        project?.methodology
      }. The comment may require a thoughtful response, clarification, or further discussion. Prompt: Based on the comment conversation below, suggest a professional and constructive response that the tagged user can use. The user is ${name}. Output: Begin with "Seeing you've been tagged in a discussion about ${
        project?.name || ""
      }, here's a suggested response:" followed by a line break and your suggested response in quotes. Keep it concise, aiming for fewer than 100 words. End with "Feel free to personalize this response. I'm here if you need further assistance!"`;
      const payload = {
        message: message,
        prompt: message,
        isVisible: false,
      };
      console.log(payload);
      const res = await openAiService.sendMessage(payload);
      dispatch(showChatModal(true));
      const messages = (res.data.messages ?? []).map(
        (_: ChatModel) => new ChatModel(_)
      );
      dispatch(addChat(messages));
    } catch (error) {}
  };

  const handleComment = async (data: FieldValues) => {
    let finalData = getFinalData(data);
    // if (finalData.tags.length > 0) {
    //   openChatBot(finalData.tags[0].email);
    // }

    setLoading((prev) => ({
      ...prev,
      loadingForbutton: true,
    }));
    try {
      const rest = await commentsService.addComments(
        id ?? "",
        sectionId,
        moduleId ?? "",
        finalData
      );
      reset({ comment: "" });
      dispatch(addComment({ comment: rest.data.comment }));
    } catch (e) {
      console.log(e);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingForbutton: false,
      }));
    }
  };

  async function getComments(pageNumber: number) {
    try {
      if (comments.length === 0)
        setLoading((prev) => ({
          ...prev,
          loadingForComments: true,
        }));
      let res = await commentsService.getComments(
        id ?? "",
        sectionId,
        moduleId ?? "",
        pageNumber
      );
      dispatch(
        setComments({
          comments:
            pageNumber > 1
              ? [...comments, ...res?.data.comments]
              : [...res?.data.comments],
          count: res?.data.count,
          sectionId: sectionId,
          moduleId: moduleId,
        })
      );
    } catch (e: any) {
      toast.error(e.response.data.message);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingForComments: false,
      }));
    }
  }

  useEffect(() => {
    resetCommentState();
    toggleEdit("-1", "");
    setEditCommentId("-1");
    methods.reset();
    methodsForEditComment.reset();
  }, [sectionId, moduleId, show]);

  useEffect(() => {
    if (show) {
      getComments(page);
    } else {
      dispatch(setComments({ comments: [], count: 0, sectionId: "" }));
    }
  }, [page, id, show, moduleId, sectionId]);

  useEffect(() => {
    if (deletedComment) {
      dispatch(emitDeleteComment(""));
      getComments(1);
      setPage(1);
    }
  }, [deletedComment]);

  const [mentions, setMentions] = useState<any[]>([]);

  useEffect(() => {
    const filter = teamMembers.filter(
      (member) => member.email !== userDetails.email
    );
    const menstion = filter?.map((member: any) => ({
      id: member?.userId,
      display: member?.email,
    }));
    setMentions(menstion);
  }, [userDetails, teamMembers]);

  const handleDeleteComment = async (comment: CommentModel) => {
    setCommentDetails(comment);
    setConfirmationModalVisible(true);
  };

  const handleConfirmDelete = async () => {
    try {
      setLoading((prev) => ({
        ...prev,
        loadingForDeleteButton: true,
      }));
      await commentsService.deleteComments(
        commentDetails?.projectId ?? "",
        commentDetails?.sectionId ?? "",
        commentDetails?.moduleId ?? "",
        commentDetails?.id ?? ""
      );
      setConfirmationModalVisible(false);
      setPage(1);
      getComments(1);
    } catch (e: any) {
      toast.error(e.response.data.message);
    } finally {
      setLoading((prev) => ({
        ...prev,
        loadingForDeleteButton: false,
      }));
    }
  };

  const handleEdit = async (data: FieldValues, comment: CommentModel) => {
    let finalData = getFinalData(data);
    setLoading((prev) => ({ ...prev, loadingForbutton: true }));
    try {
      const res = await commentsService.updateComment(
        comment?.projectId ?? "",
        comment?.sectionId ?? "",
        comment?.moduleId ?? "",
        editCommentId,
        finalData
      );
      dispatch(updateComment({ comment: res.data.comment }));
      toggleEdit("-1", "");
    } catch (e) {
      toast.error("Failed to update comment.");
    } finally {
      setLoading((prev) => ({ ...prev, loadingForbutton: false }));
    }
  };

  const resetCommentState = () => {
    dispatch(
      setComments({
        comments: [],
        count: 0,
        sectionId: "",
      })
    );
    setPage(1);
  };

  const getTeamMembers = useCallback(async () => {
    try {
      const obj = {
        url: `projects/${id}/users`,
      };
      const response = await apiService.get(obj);
      setTeamMembers(response.data?.projectUsers ?? []);
    } catch (e) {
      console.log(e);
    }
  }, [id]);
  useEffect(() => {
    if (show) {
      getTeamMembers();
    }
  }, [id, getTeamMembers, show]);

  return (
    <>
      <RightSideDrawer
        title="Comments"
        show={show}
        padding={false}
        setSelectedTab={setSelectedTab}
      >
        <div className={style.comments}>
          {sectionId && id && (
            <div className={style.comments__input}>
              <form onSubmit={handleSubmit(handleComment)} noValidate>
                <Controller
                  name={"comment"}
                  control={control}
                  render={({ field }) => {
                    return (
                      <MentionsInput
                        value={field?.value ?? ""}
                        onChange={field.onChange}
                        placeholder="Comment or tag others with @"
                        readOnly={loading.loadingForbutton}
                      >
                        <Mention
                          trigger="@"
                          data={mentions}
                          displayTransform={(id: any, display: any) =>
                            `@${display}`
                          }
                        />
                      </MentionsInput>
                    );
                  }}
                />

                <button
                  type="submit"
                  disabled={
                    loading.loadingForbutton ||
                    !!!methods.watch("comment")?.trim()
                  }
                >
                  <img src={sendIcon} alt="" />
                </button>
              </form>
            </div>
          )}
          {loading.loadingForComments ? (
            <div className={style.comments__scroll}>
              <div className={style.comments__content}>
                {Array(5)
                  .fill(0)
                  .map((item, index) => {
                    return (
                      <div key={index}>
                        <CommentsLoader />
                      </div>
                    );
                  })}
              </div>
            </div>
          ) : (
            <div className={style.comments__scroll} id="commentsScroll">
              <InfiniteScroll
                dataLength={comments?.length}
                next={() => setPage((prev) => prev + 1)}
                hasMore={comments?.length < count}
                loader={<CommentsLoader />}
                scrollableTarget="commentsScroll"
              >
                {comments.length == 0 ? (
                  <div className="flex justify-center">No Comments yet</div>
                ) : (
                  <div className={style.comments__content}>
                    {comments?.map((comment: any, i: number) => {
                      return (
                        <div
                          key={comment.id}
                          onClick={() => {
                            // const elementId = comment?.moduleId
                            //   ? `module_${comment?.moduleId}`
                            //   : `section_${comment?.sectionId}`;
                            // document.getElementById(elementId)?.scrollIntoView({
                            //   block: "center",
                            //   behavior: "smooth",
                            // });
                          }}
                          className={`${style.comments__item} flex`}
                        >
                          <img
                            src={comment?.user?.image || UserImage}
                            alt="Profie"
                          />
                          <div className={style.comments__itemContent}>
                            <header className="flex">
                              <div className={`${style.comments__profile}`}>
                                <strong>
                                  {comment?.user?.name ?? comment?.user?.email}
                                </strong>
                                <p>
                                  <span>Developer</span> |{" "}
                                  {timeConverter(comment.createdAt)}
                                </p>
                              </div>

                              <div className={style.comments__more}>
                                <img
                                  onClick={() =>
                                    setOpenMenuId(comment?.id || "")
                                  }
                                  src={more}
                                  alt="..."
                                />
                                {openMenuId === comment?.id && (
                                  <DropdownMenu
                                    onClose={() => setOpenMenuId("")}
                                  >
                                    <ul>
                                      {comment.user?.id ==
                                        userDetails?.id.toString() && (
                                        <>
                                          {editCommentId !== comment?.id && (
                                            <li
                                              onClick={() => {
                                                toggleEdit(
                                                  comment?.id,
                                                  comment.comment
                                                );
                                                setOpenMenuId("");
                                              }}
                                            >
                                              <img src={editIcon} alt="Edit" />
                                              <span>Edit</span>
                                            </li>
                                          )}
                                          <li
                                            className={style.isDelete}
                                            onClick={() => {
                                              handleDeleteComment(comment);
                                              setOpenMenuId("");
                                            }}
                                          >
                                            <img
                                              src={DeleteIcon}
                                              alt="Delete"
                                            />
                                            <span>Delete</span>
                                          </li>
                                        </>
                                      )}
                                    </ul>
                                  </DropdownMenu>
                                )}
                              </div>
                            </header>
                            {editCommentId === comment?.id ? (
                              <div
                                className={`${style.comments__input} ${style.isUpdate}`}
                              >
                                <form
                                  onSubmit={methodsForEditComment.handleSubmit(
                                    (e) => handleEdit(e, comment)
                                  )}
                                  noValidate
                                >
                                  <Controller
                                    name={"comment"}
                                    control={methodsForEditComment.control}
                                    render={({ field }) => {
                                      return (
                                        <MentionsInput
                                          value={field?.value ?? ""}
                                          onChange={field.onChange}
                                          placeholder="Edit comment"
                                        >
                                          <Mention
                                            trigger="@"
                                            data={mentions}
                                            displayTransform={(
                                              id: any,
                                              display: any
                                            ) => `@${display}`}
                                          />
                                        </MentionsInput>
                                      );
                                    }}
                                  ></Controller>
                                  <aside className="flex justify-end">
                                    <button
                                      type="button"
                                      onClick={() => toggleEdit("-1", "")}
                                    >
                                      Cancel
                                    </button>
                                    <button
                                      type="submit"
                                      disabled={
                                        loading.loadingForbutton ||
                                        methodsForEditComment
                                          .watch("comment")
                                          ?.trim().length === 0
                                      }
                                    >
                                      Save
                                    </button>
                                  </aside>
                                </form>
                              </div>
                            ) : (
                              <p>
                                <CommentContent
                                  comment={comment.comment}
                                  tags={comment.commentTags}
                                ></CommentContent>
                              </p>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </InfiniteScroll>
            </div>
          )}

          {/* <ExternalReviews /> */}
        </div>
      </RightSideDrawer>

      <CustomModal
        maxWidth="480px"
        title="Delete Comment"
        modalVisible={isConfirmationModalVisible}
        onClose={() => setConfirmationModalVisible(false)}
        footerNode={
          <div className={`${style.projects__action} flex justify-center`}>
            <Button
              buttonClass="sm outline"
              onClick={() => setConfirmationModalVisible(false)}
            >
              Cancel
            </Button>
            <Button
              buttonClass="sm"
              onClick={handleConfirmDelete}
              loading={loading.loadingForDeleteButton}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <div className={style.projects__info}>
          <p>Are you sure you want to delete this comment?</p>
        </div>
      </CustomModal>
    </>
  );
};

export default Comments;
