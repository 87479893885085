import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ProjectFallbackType } from "../components/Section/editor.enum";
import { ProjectModel } from "../models/project.model";
import { ShortSectionModel } from "../models/project_section.model";

interface ProjectStateType {
  project: ProjectModel | undefined;
  newRole: string;
  firstEdited: string | null | undefined;
  projectLogsIsLoading: boolean;
  projectSections: { [key: string]: ShortSectionModel[] };
  projects: { [key: string]: ProjectModel };
  socketFallBack: {
    fallbackType: ProjectFallbackType;
    showFallback: boolean;
    sectionId: string | undefined;
  };
}
const initialState: ProjectStateType = {
  project: undefined,
  newRole: "",
  firstEdited: undefined,
  projectLogsIsLoading: false,
  projectSections: {},
  projects: {},
  socketFallBack: {
    showFallback: false,
    fallbackType: ProjectFallbackType.NONE,
    sectionId: undefined,
  },
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setFallbackByProjectId: (
      state: ProjectStateType,
      action: PayloadAction<any>
    ) => {
      if (state.projects[action.payload.projectId]) {
        return {
          ...state,
          socketFallBack: {
            showFallback: action.payload.show,
            fallbackType: action.payload.type,
            sectionId: action?.payload?.sectionId ?? undefined,
          },
        };
      } else {
        return { ...state };
      }
    },
    setProject: (
      state: ProjectStateType,
      action: PayloadAction<ProjectModel | undefined>
    ) => {
      return {
        ...state,
        project: action.payload,
      };
    },
    setFirstEdited: (
      state: ProjectStateType,
      action: PayloadAction<string | null | undefined>
    ) => {
      return {
        ...state,
        firstEdited: action.payload,
      };
    },
    setProjectLogsIsLoading: (
      state: ProjectStateType,
      action: PayloadAction<boolean>
    ) => {
      return {
        ...state,
        projectLogsIsLoading: action.payload,
      };
    },
    setSectionList: (
      state: ProjectStateType,
      action: PayloadAction<{ id: string; list: ShortSectionModel[] }>
    ) => {
      return {
        ...state,
        projectSections: {
          ...state.projectSections,
          [action.payload.id]: action.payload.list,
        },
      };
    },
    setProjectById: (
      state: ProjectStateType,
      action: PayloadAction<{ id: string; project: ProjectModel }>
    ) => {
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.id]: action.payload.project,
        },
      };
    },
    resetFallbackState: (
      state: ProjectStateType,
      action: PayloadAction<any>
    ) => {
      return { ...state, socketFallBack: { ...initialState.socketFallBack } };
    },
  },
});

export const {
  setProject,
  setFallbackByProjectId,
  setFirstEdited,
  setProjectLogsIsLoading,
  setSectionList,
  setProjectById,
  resetFallbackState,
} = projectSlice.actions;
export const getSocketFallBack = (state: {
  project: ProjectStateType;
}) => state.project.socketFallBack;

export const getProject = (state: {
  project: { project: ProjectModel | undefined };
}) => state.project.project;

export const getFirstEdited = (state: {
  project: { firstEdited: string | null | undefined };
}) => state.project.firstEdited;

export const getProjectLogsIsLoading = (state: {
  project: { projectLogsIsLoading: boolean };
}) => state.project.projectLogsIsLoading;

export const getSectionList = (
  state: { project: ProjectStateType },
  id: string
) => state.project.projectSections?.[id] ?? undefined;
export const getProjectById = (
  state: { project: ProjectStateType },
  id: string
) => state.project.projects?.[id] ?? undefined;
export default projectSlice.reducer;
