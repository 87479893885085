import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { Outlet, useLocation } from "react-router";
import Header from "../../components/Header/Header";
import { StorageEnums } from "../../enums/storageEnums";
import { ChatModel } from "../../models/chatModel";
import { ApiService } from "../../services/apiServices";
import { OpenAiService } from "../../services/openAIService";
import { StorageService } from "../../services/storage.service";
import { setChat } from "../../store/chatSlice";
import { setUserDetails } from "../../store/userDetailsSlice";
import style from "./Dashboard.module.scss";
const openAiService = new OpenAiService();
const storageService = new StorageService();
const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const pathsWithHeader = useMemo(
    () => [
      "/dashboard/projects",
      "/dashboard/account",
      "/dashboard/notifications",
    ],
    []
  );

  const getProfile = useCallback(async () => {
    try {
      const user = storageService.getKey(StorageEnums.CREDENTIALS);
      if (!user) {
        return;
      }

      let data = {
        url: "users/profile",
        headerToken: user?.data?.accessToken,
      };
      let userDetail = await new ApiService().get(data);
      dispatch(setUserDetails(userDetail.data?.user));
      updateChat(userDetail.data?.user);
    } finally {
    }
  }, [dispatch]);

  const updateChat = async (user: any) => {
    // const res = await openAiService.getInitialChat();
    // const messages = res.data.messages.map((e: ChatModel) => new ChatModel(e));
    // dispatch(setChat(messages));
  };
  useEffect(() => {
    getProfile();
  }, [getProfile]);

  //   ${style.rightSlide} ${style.rightEnabled}
  return (
    <div className={`${style.dashboard} dashboard`}>
      {pathsWithHeader.includes(location?.pathname) && <Header />}
      <Outlet />
    </div>
  );
};

export default Dashboard;
