import { FC, useEffect, useState } from "react";
import { NodeViewContent, NodeViewWrapper, NodeViewProps } from "@tiptap/react";
import { Editor } from "@tiptap/core";
import Tippy from "@tippyjs/react";
import style from "./Table.module.scss";
import arrowMore from "../../../../assets/images/arrow-more.svg";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import { getProjectById } from "../../../../store/projectSlice";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";

interface CellButton {
  name: string;
  action: (editor: Editor) => boolean;
  icon?: string;
}

const cellButtonsConfig: CellButton[] = [
  {
    name: "Add row above",
    action: (editor) => editor.chain().focus().addRowBefore().run(),
    icon: "+",
  },
  {
    name: "Add row below",
    action: (editor) => editor.chain().focus().addRowAfter().run(),
    icon: "+",
  },
  {
    name: "Add column before",
    action: (editor) => editor.chain().focus().addColumnBefore().run(),
    icon: "+",
  },
  {
    name: "Add column after",
    action: (editor) => editor.chain().focus().addColumnAfter().run(),
    icon: "+",
  },
  {
    name: "Merge cells",
    action: (editor) => editor.chain().focus().mergeCells().run(),
    icon: "+",
  },
  {
    name: "Split cells",
    action: (editor) => editor.chain().focus().splitCell().run(),
    icon: "-",
  },
  {
    name: "Remove row",
    action: (editor) => editor.chain().focus().deleteRow().run(),
    icon: "-",
  },
  {
    name: "Remove column",
    action: (editor) => editor.chain().focus().deleteColumn().run(),
    icon: "-",
  },
  {
    name: "Toggle header cell",
    action: (editor) => editor.chain().focus().toggleHeaderCell().run(),
    icon: "-",
  },
];

export const TableCellNodeView: FC<NodeViewProps> = ({
  node,
  getPos,
  selected,
  editor,
}) => {
  const [isCurrentCellActive, setIsCurrentCellActive] = useState(false);
  const { id } = useParams();
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const calculateActiveSateOfCurrentCell = () => {
    const { from, to } = editor.state.selection;

    const nodeFrom = getPos();
    const nodeTo = nodeFrom + node.nodeSize;
    setIsCurrentCellActive(nodeFrom <= from && to <= nodeTo);
  };

  useEffect(() => {
    editor.on("selectionUpdate", calculateActiveSateOfCurrentCell);

    setTimeout(calculateActiveSateOfCurrentCell, 100);

    return () => {
      editor.off("selectionUpdate", calculateActiveSateOfCurrentCell);
    };
  });

  return (
    <>
      <NodeViewWrapper
        style={{ backgroundColor: node.attrs.backgroundColor }}
        className={
          project.role === ProjectUserRoleEnum.VIEWER ? "isViewer" : ""
        }
        contentEditable={
          node.attrs.contenteditable &&
          project.role !== ProjectUserRoleEnum.VIEWER
        }
        suppressContentEditableWarning={true}
      >
        <NodeViewContent as="span" />
      </NodeViewWrapper>
      {project.role !== ProjectUserRoleEnum.VIEWER &&
        (isCurrentCellActive || selected) &&
        node.attrs.contenteditable && (
          <Tippy
            appendTo={document.body}
            trigger="click"
            interactive
            animation="shift-toward-subtle"
            placement="right-start"
            content={
              <div className={style.table__optionMenu} contentEditable={false}>
                <ul
                  tabIndex={0}
                  className="dropdown-content fixed menu menu-compact p-2 shadow bg-base-100 rounded-box w-56"
                >
                  {cellButtonsConfig.map((btn) => {
                    return (
                      <li key={btn.name}>
                        <a onClick={() => btn.action(editor)}>
                          <span>{btn.icon}</span>

                          {btn.name}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            }
          >
            <button className={`${style.table__options} trigger-button`}>
              <img src={arrowMore} alt="..." />
            </button>
          </Tippy>
        )}
    </>
  );
};
