import { NotificationModel } from "../models/notification.model";
import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class NotificationService {
  getNotifications(page: number) {
    const obj = {
      url: `notifications?page=${page}&limit=25`,
    };
    return apiService.get(obj);
  }

  deleteNotification(id: string) {
    let obj = {
      url: `notifications/${id}`,
    };
    return apiService.delete(obj);
  }
  deleteAllNotifications() {
    let obj = {
      url: "notifications"
    };
    return apiService.delete(obj);
  }

  readNotifications(notificationIds: string[]) {
    let obj = {
      url: "notifications/reads",
      data: { notificationIds: notificationIds }
    };
    return apiService.put(obj);
  }

  static filterAndUpdateNotifications(
    newComments: NotificationModel[],
    comments: NotificationModel[]
  ) {
    const oldCommentMap: any = {};
    comments.forEach((comment, index) => {
      oldCommentMap[comment.id] = { comment, index };
    });

    let newCommentsArray: NotificationModel[] = [];
    newComments.forEach((comment) => {
      if (oldCommentMap[comment.id]) {
        // replace existing comment
        comments[oldCommentMap[comment.id].index] = comment;
      } else {
        // add new comment
        newCommentsArray.push(comment);
      }
    });
    const updatedComments = [...newCommentsArray, ...comments];
    updatedComments.sort(
      (a, b) =>
        new Date(b?.createdAt ?? 0).getTime() -
        new Date(a?.createdAt ?? 0).getTime()
    );
    return updatedComments;
  }
}
