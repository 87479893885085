import axios from "axios";
import { jwtDecode } from "jwt-decode";
import {
  SKIP_TOKEN_URL,
  TOKEN_REFRESH_TIME_IN_MILLI_SEC,
  UNAUTHORIZED_STATUS_CODE,
} from "../constants/constants";
import { StorageEnums } from "../enums/storageEnums";
import { AuthService } from "../services/authService";
import { StorageService } from "../services/storage.service";
const storageService = new StorageService();
const authService = new AuthService();

const refreshToken = async (url: string) => {
  const user = storageService.getKey(StorageEnums.CREDENTIALS);
  if (url?.includes("auth/refresh") || !user) {
    return;
  }
  const { accessToken, refreshToken } = user.data;
  const currentTime = Date.now();
  const expiry = jwtDecode(accessToken).exp;
  if (
    !accessToken ||
    !expiry ||
    !refreshToken ||
    expiry * 1000 - currentTime < 0
  ) {
    return;
  }
  if (expiry * 1000 - currentTime < TOKEN_REFRESH_TIME_IN_MILLI_SEC) {
    const res = await authService.handleRefreshToken(refreshToken);
    storageService.setKey(StorageEnums.CREDENTIALS, res);
  }
};

const interceptor = () => {
  axios.interceptors.request.use(async (req) => {
    let JWTToken: any = storageService.getKey(StorageEnums.CREDENTIALS)?.data
      ?.accessToken;
    await refreshToken(req.url ?? "");
    if (
      JWTToken != null &&
      !SKIP_TOKEN_URL.find((url) => req.url?.includes(url))
    ) {
      req.headers.Authorization = `${JWTToken}`;
    }
    return {
      ...req,
    };
  });

  axios.interceptors.response.use(
    (res: any) => {
      return res;
    },
    (error: any) => {
      if (error?.response?.status === UNAUTHORIZED_STATUS_CODE) {
        storageService.clearLocalStorage();
        window.location.href = "/";
      }
      return Promise.reject(error);
    }
  );
};

export default interceptor;
