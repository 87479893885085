import React, { useState } from "react";
import Input from "../../atoms/Input/Input";
import { FieldValues, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router";
import Button from "../../atoms/Button/Button";
import { resetPasswordSchema } from "./resetPassword.schema";
import style from "../../assets/scss/auth.module.scss";
import { toast } from "react-toastify";
import { ApiService } from "../../services/apiServices";
const ResetPassword = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const apiService = new ApiService();

  const methods = useForm({
    resolver: yupResolver(resetPasswordSchema),
  });
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = methods;
  const navigate = useNavigate();

  const handleResetPassword = async (data: FieldValues) => {
    try {
      setLoading(true);
      let payload = {
        data,
        url: "auth/request-reset-password",
      };
      await apiService.post(payload);
      navigate(`/confirm-code/${data.email}`);
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={style.auth}>
      <div className={style.auth__wrap}>
        <h1>Enter your email to reset password</h1>
        <strong></strong>
        <form onSubmit={handleSubmit(handleResetPassword)} noValidate>
          <Input
            type="text"
            placeholder="Email"
            register={register}
            registerName="email"
            errorMessage={errors?.email?.message}
          />
          <Button
            type="submit"
            loading={loading}
            disabled={loading}
            label="Reset password"
            buttonClass="isFull"
          />
        </form>
        <div className={style.auth__infoText}>
          <a onClick={() => navigate("/login")}>Cancel</a>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
