import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../atoms/Button/Button";
import Input from "../../atoms/Input/Input";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { ApiService } from "../../services/apiServices";
import {
  selectUserDetails,
  setUserDetails,
} from "../../store/userDetailsSlice";
import { myAccountSchema } from "./myAccount.schema";
import style from "./MyAccount.module.scss";
import UserImage from "../../assets/images/profile-img.svg";
import DeleteIcon from "../../assets/images/delete-white.svg";
import cameraIcon from "../../assets/images/camera.svg";
import ChangePassword from "./ChangePassword";
import Dropdown from "../../atoms/Dropdown/Dropdown";
import { avtivitiesList } from "../../atoms/Constants/dropdown.constant";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { MediaType } from "../../enums/Enums";
import { ProfileService } from "../../services/profileService";
import SkeletonLoader from "../../components/Skeleton/Skeleton";
import { ALLOWED_IMAGE_TYPE } from "../../constants/constants";
import { UploadFileService } from "../../services/uploadFileService";

const apiService = new ApiService();
const profileService = new ProfileService();
const uploadFileService = new UploadFileService();

const MyAccount = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [showChangePasswordModal, setShowChangePasswordModal] =
    useState<boolean>(false);
  const userDetails = useSelector(selectUserDetails);
  const [fileUploading, setFileUploading] = useState(false);
  const [profileImage, setProfileImage] = useState<string | null>(
    userDetails.image
  );
  const {
    handleSubmit,
    control,
    formState: { errors, isDirty },
    register,
    reset,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(myAccountSchema),
    defaultValues: {
      name: userDetails?.name ?? "",
      activity:
        avtivitiesList.find((el) => userDetails?.activity === el.value) ??
        avtivitiesList[0],
      email: userDetails?.email ?? "",
    },
  });

  const dispatch = useDispatch();

  const handleModal = () => {
    reset();
    setShowChangePasswordModal(true);
  };

  useEffect(() => {
    if (userDetails?.email) {
      reset({
        name: userDetails?.name,
        activity:
          avtivitiesList.find((el) => userDetails?.activity === el.value) ??
          avtivitiesList[0],
        email: userDetails?.email,
      });
      setProfileImage(userDetails?.image);
    }
  }, [userDetails]);

  const validateImage = (file: File | null | undefined) => {
    if (file && 20 < file.size / (1024 * 1024)) {
      toast.error("Please upload file below 20MB");
      return false;
    }
    if (file && !ALLOWED_IMAGE_TYPE.includes(file.type)) {
      toast.error("Invalid Image!");
      return false;
    }
    return true;
  };

  const onFileSelction = async (file?: File | null) => {
    if (!file || !validateImage(file)) {
      return false;
    }
    try {
      setFileUploading(true);
      const res = await apiService.post({
        url: "media/signed-url",
        data: {
          fileName: file.name,
          contentType: file.type,
          resourceId: userDetails?.id,
          category: MediaType.PROFILE_IMAGE,
        },
      });
      await uploadFileService.uploadFile(res?.data?.uploadUrl, file);
      await updateImage(res?.data?.contentUrl, res?.data?.key ?? "");
      setFileUploading(false);
    } catch (error) {
      console.log(error);
      setFileUploading(false);
    }
  };
  const updateImage = async (url: string | null, key: string) => {
    try {
      const user = await profileService.updateUserProfile({
        image: key,
      });
      setProfileImage(url);
      dispatch(setUserDetails(user?.data?.user));
    } catch (error) {}
  };

  const deleteImage = async () => {
    try {
      setFileUploading(true);
      await updateImage(null, "");
      setFileUploading(false);
    } catch (error) {
      setFileUploading(false);
    }
  };
  const updateProfile = async (data: any) => {
    try {
      setLoading(true);
      const payload = {
        name: data?.name ?? "",
        activity: data?.activity?.value ?? "",
      };
      const res = await profileService.updateUserProfile(payload);
      dispatch(setUserDetails(res?.data?.user));
      setLoading(false);
      toast.success("Account details updated successfully");
    } catch (error: any) {
      setLoading(false);
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className={`${style.account} flex-wrap`}>
      <div className={style.account__card}>
        <picture>
          {profileImage && (
            <div className={style.account__remove}>
              <img src={DeleteIcon} alt="X" onClick={deleteImage} />
            </div>
          )}
          {fileUploading ? (
            <div className={style.account__loader}>
              <SkeletonLoader
                circle={true}
                fill={true}
                width="100%"
                height="100%"
              />
            </div>
          ) : (
            <img src={profileImage || UserImage} alt="DP" />
          )}
          <label>
            <img src={cameraIcon} alt="Camera" />
            <input
              type="file"
              onChange={(e) => onFileSelction(e?.target?.files?.[0])}
              accept={ALLOWED_IMAGE_TYPE.join(",")}
            />
          </label>
        </picture>
        <strong>{userDetails?.name}</strong>
        <span>
          {userDetails?.activity === "Project Developer"
            ? "Developer"
            : userDetails?.activity}
        </span>
      </div>

      <div className={style.account__content}>
        <h4>Account Details</h4>

        <div className={style.account__item}>
          <label>Name</label>
          <Input
            type="text"
            register={register}
            registerName="name"
            errorMessage={errors?.name?.message}
          />
        </div>

        <div className={style.account__item}>
          <label>Email</label>
          <Input
            type="email"
            register={register}
            registerName="email"
            disabled={true}
          />
        </div>

        <div className={style.account__item}>
          <label>Role</label>
          <Dropdown
            name="activity"
            isSearchable={false}
            label="Primary activities"
            options={avtivitiesList}
            control={control}
            error={errors?.activity?.message}
          />
        </div>

        <div className={`${style.account__bottom} flex-wrap justify-between`}>
          <Button
            label={"Change password"}
            onClick={handleModal}
            buttonClass="outline sm"
          />
          <Button
            label={"Save Changes"}
            onClick={handleSubmit(updateProfile)}
            loading={loading}
            buttonClass="sm"
            disabled={fileUploading || !isDirty}
          />
        </div>
      </div>
      {showChangePasswordModal && (
        <ChangePassword
          setShowChangePasswordModal={setShowChangePasswordModal}
          showChangePasswordModal={showChangePasswordModal}
        ></ChangePassword>
      )}
    </div>
  );
};

export default MyAccount;
