import { useCallback, useEffect, useMemo, useState } from "react";
import Table from "../Table/Table";
import { TASKS_HEADINGS } from "./tasks.constants";
import { ApiService } from "../../services/apiServices";
import { toast } from "react-toastify";
import SkeletonLoader from "../Skeleton/Skeleton";
import TableLoader from "../Table/TableLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router";
import { convertTaskDueDate } from "../../utils/utils";

interface Tasks {
  id: string;
  name: string;
  dueDate: string;
  status: string;
  template: object;
}
interface TaskData {
  projectSections: Tasks[];
}

const Tasks = () => {
  const [tasksData, setTasksData] = useState<{
    tasks: TaskData[];
    count: number;
    page: number;
  }>({ tasks: [], count: 0, page: 1 });
  const [loading, setLoading] = useState<boolean>(false);
  const apiService = new ApiService();
  const navigate = useNavigate();

  const handleProjectDetails = useCallback(
    (data: any) => {
      navigate(`/dashboard/details/${data.id}/section/${data?.sectionId}`);
    },
    [navigate]
  );
  const taskHeadings = useMemo(() => {
    return TASKS_HEADINGS.map((el) => {
      el.onClick = handleProjectDetails;
      return el;
    });
  }, [handleProjectDetails]);

  async function getTasks() {
    try {
      if (tasksData.tasks.length === 0) setLoading(true);
      let obj = {
        url: `projects/tasks?page=${tasksData.page}&limit=10`,
      };
      let res = await apiService.get(obj);
      let updatedResponse = res?.data.project.map((el: any) => {
        return {
          projectName: el.name ?? "-",
          sectionName: el?.section?.name ?? "-",
          status: el?.section?.status ?? "-",
          dueDate: el?.section?.dueDate
            ? convertTaskDueDate(el?.section?.dueDate)
            : "-",
          id: el?.id ?? "",
          sectionId: el?.section?.id ?? "",
        };
      });
      setTasksData((prevData) => ({
        tasks: [...prevData.tasks, ...updatedResponse],
        count: res?.data.count,
        page: prevData.page + 1,
      }));
    } catch (e: any) {
      console.log(
        "error in fetch fetch projects",
        e?.response?.message?.data ?? e?.message
      );
      toast.error(e?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (tasksData.page === 1 && tasksData.tasks.length === 0) {
      getTasks();
    }
  }, [tasksData.page]);
  useEffect(() => {
    setTasksData({
      tasks: [],
      count: 0,
      page: 1,
    });
  }, []);
  return (
    <div>
      {loading ? (
        <div>
          <TableLoader
            rows={10}
            headings={TASKS_HEADINGS}
            columns={TASKS_HEADINGS.length}
          />
        </div>
      ) : (
        <InfiniteScroll
          dataLength={tasksData?.tasks.length}
          next={getTasks}
          hasMore={tasksData?.tasks.length < tasksData?.count}
          loader={<SkeletonLoader />}
        >
          <Table
            headings={taskHeadings}
            tableData={tasksData.tasks}
            dataType="tasks"
            onClick={handleProjectDetails}
          />
        </InfiniteScroll>
      )}
    </div>
  );
};

export default Tasks;
