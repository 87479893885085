import { createSlice } from "@reduxjs/toolkit";

export interface UserState {
  userId: number | null;
  userName: string | null;
}

let initialState = {
  userId: null,
  userName: null,
} as UserState;

const userReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    editProfile: editProfileHandler,
  },
});

function editProfileHandler(
  state: UserState,
  action: {
    payload: { userId: number; userName: string };
    type: string;
  }
) {
  return {
    ...state,
    userId: action.payload.userId,
    userName: action.payload.userName,
  };
}

export const { editProfile } = userReducer.actions;
export default userReducer.reducer;
