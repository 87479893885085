export enum RightSidebarTabsEnum {
  COMMENTS = "Comments",
  TEAM_MEMBERS = "Team Members",
  DOWNLOADS = "Downloads",
}
export enum MediaType {
  SECTION_IMAGE = "section/image",
  PROFILE_IMAGE = "profile/image",
}
export enum NotificationTypeEnum {
  ADDED_USER_TO_PROJECT = "PROJECT.USER.ADDED",
  REMOVED_USER_FROM_PROJECT = "PROJECT.USER.REMOVED",
  UPDATE_PROJECT_USER_ROLE = "UPDATE.PROJECT.USER.ROLE",
  ADDED_USER_TO_SECTION = "SECTION.USER.ADDED",
  REMOVED_USER_FROM_SECTION = "SECTION.USER.REMOVED",
  COMMENT_ADDED_TO_SECTION = "COMMENT.ADDED.SECTION",
  TAGGED_IN_COMMENT = "COMMENT.USER.TAGGED",
}
 export enum SectionSocketEvent {
   ADD_SECTION = "add-section",
   REMOVE_SECTION = "remove-section",
   UPDATE_SECTION = "update-section",
 }