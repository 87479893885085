import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UserDetails {
  cognitoId: string;
  email: string;
  name: string;
  id: number;
  activity: string;
  image: string | null;
}

interface UserDetailsState {
  details: UserDetails;
}

const initialState: UserDetailsState = {
  details: {
    cognitoId: "",
    email: "",
    name: "",
    id: 0,
    activity: "",
    image: null,
  },
};

const userDetailsSlice = createSlice({
  name: "userDetails",
  initialState,
  reducers: {
    setUserDetails: (state, action: PayloadAction<UserDetails>) => {
      state.details = action.payload;
    },
    resetUserDetails: (state) => {
      return { ...initialState };
    },
  },
});

export const { setUserDetails, resetUserDetails } = userDetailsSlice.actions;
export const selectUserDetails = (state: { userDetails: UserDetailsState }) =>
  state.userDetails.details;

export default userDetailsSlice.reducer;
