import React, { useEffect, useState } from "react";
import toggleIcon from "../../assets/images/chevron-left.svg";
import useHideSidebar from "../../hooks/useHideSidebar";
import { SidebarTab } from "../../interfaces/interface";
import style from "./RightSideBar.module.scss";
import { RIGHT_SIDEBAR_TABS } from "./rightSideBar.constants";
interface RightSideBarProps {
  onTabClick: Function;
}

const RightSideBar: React.FC<RightSideBarProps> = ({ onTabClick }) => {
  let location = useHideSidebar();
  useEffect(() => {
    const el = document.getElementsByTagName("body")[0];
    if (location) {
      el?.classList.add("rightSide");
    }
    return () => {
      el?.classList.remove("rightSide");
    };
  }, [location]);

  const [viewToggle, setViewToggle] = useState(false);
  return (
    <aside className={`${style.rightSideBar} ${viewToggle && style.active}`}>
      <picture onClick={() => setViewToggle(!viewToggle)}>
        <img src={toggleIcon} alt="View Toggle" />
      </picture>
      <div className={style.rightSideBar__inner}>
        <ul>
          {RIGHT_SIDEBAR_TABS.map((tab: SidebarTab) => {
            return (
              <li onClick={() => onTabClick(tab.name)} key={tab.name}>
                <img src={tab.icon} alt="icon" />
              </li>
            );
          })}
        </ul>
      </div>
    </aside>
  );
};

export default RightSideBar;
