import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UserState {
  role: string;
}

const initialState: UserState = {
  role: '',
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserRole: (state, action: PayloadAction<string>) => {
      state.role = action.payload;
    },
  },
});

export const { setUserRole } = userSlice.actions;
export const selectUserRole = (state: {userRole:UserState}) => state.userRole;
export default userSlice.reducer;
