import React, { useCallback, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import CloseIcon from "../../assets/images/close.svg";
import LogoutIcon from "../../assets/images/logout.svg";
import NotificationIcon from "../../assets/images/notification.svg";
import UserImage from "../../assets/images/profile-img.svg";
import ProfileIcon from "../../assets/images/profile.svg";
import QuestionIcon from "../../assets/images/question.svg";
import TaskIcon from "../../assets/images/task.svg";
import DropdownMenu from "../../atoms/DropdownMenu/DropdownMenu";
import { NotificationTypeEnum } from "../../enums/Enums";
import { StorageEnums } from "../../enums/storageEnums";
import { NotificationModel } from "../../models/notification.model";
import { NotificationService } from "../../services/notification.service";
import { StorageService } from "../../services/storage.service";
import { setChat, showChatModal } from "../../store/chatSlice";
import {
  addNotification,
  clearNotifications,
  deleteNotification,
  updateUnreadCount,
} from "../../store/notificationSlice";
import {
  resetUserDetails,
  selectUserDetails,
} from "../../store/userDetailsSlice";
import SkeletonLoader from "../Skeleton/Skeleton";
import SectionHeader from "./Components/SectionHeader";
import style from "./Header.module.scss";
export interface headerProp {
  bottomBorder?: boolean;
  fullWidth?: boolean;
  showNav?: boolean;
  setShowNav: React.Dispatch<React.SetStateAction<boolean>>;
}

const notificationService = new NotificationService();
const storageService = new StorageService();

const Header = () => {
  const [isDropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const user = localStorage.getItem(StorageEnums.CREDENTIALS);
  const userDetails = useSelector(selectUserDetails);
  const [showNav, setShowNav] = useState<boolean>(false);
  const [showNotificationBar, setShowNotificationBar] =
    useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(1);
  const { headerNotifications, unreadCount } = useSelector(
    (store: any) => store.notifications
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const toggleDropdown = () => {
    setDropdownVisible(!isDropdownVisible);
  };
  const { id, sectionId } = useParams();
  const { pathname } = useLocation();
  const toggleDropdownForNotification = () => {
    setShowNotificationBar(!showNotificationBar);
  };

  const handleLogout = () => {
    storageService.clearLocalStorage();
    dispatch(resetUserDetails());
    dispatch(clearNotifications());
    dispatch(setChat([]));
    dispatch(showChatModal(false));
    navigate("/login");
  };

  const getNotification = useCallback(
    async (pageNumber: number) => {
      try {
        setLoading(true);
        const res = await notificationService.getNotifications(pageNumber);
        dispatch(addNotification(res.data.notifications));
        dispatch(updateUnreadCount(res.data.unReadCount));
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
      }
    },
    [dispatch]
  );

  const handleClearAllNotifications = async (e: any) => {
    e.stopPropagation();
    try {
      dispatch(clearNotifications());
      await notificationService.deleteAllNotifications();
      setPage(1);
      setShowNotificationBar(false);
    } catch (e) {
      console.log(e);
    }
  };

  const handleDeleteNotification = async (id: string) => {
    try {
      setIsDeleting(true);
      await notificationService.deleteNotification(id);
      dispatch(deleteNotification(id));
      setPage(1);
      setIsDeleting(false);
    } catch (e) {
      console.log(e);
    }
  };
  const handleNotificationClick = (data: any) => {
    let queryString = "";
    switch (data?.type) {
      case NotificationTypeEnum.TAGGED_IN_COMMENT:
      case NotificationTypeEnum.COMMENT_ADDED_TO_SECTION: {
        queryString =
          data?.payload?.commentId && data?.payload?.sectionId
            ? `/section/${data?.payload?.sectionId}?commentId=${
                data?.payload?.commentId
              }${
                data?.payload?.moduleId
                  ? "&moduleId=" + data?.payload?.moduleId
                  : ""
              }`
            : "";
        break;
      }
      case NotificationTypeEnum.ADDED_USER_TO_SECTION: {
        queryString = data?.payload?.sectionId
          ? `/section/${data?.payload?.sectionId}`
          : "";
        break;
      }
      default: {
        queryString = "";
      }
    }
    navigate(`/dashboard/details/${data.projectId}${queryString}`);
    setShowNotificationBar(false);
  };

  const updateReadCount = async (e: any) => {
    try {
      const index = Math.ceil(((e.target as HTMLElement).scrollTop + 300) / 60);
      const readNotifications = headerNotifications.filter(
        (e: NotificationModel, i: number) => i <= index && e.isRead === false
      );
      if (readNotifications.length === 0) return;
      await notificationService.readNotifications(
        readNotifications.map((e: NotificationModel) => e.id)
      );
      readNotifications.forEach((e: NotificationModel) => {
        e.isRead = true;
      });
      dispatch(addNotification(readNotifications));
    } catch (error) {}
  };

  const readInitialNotifications = useCallback(async () => {
    if (!headerNotifications || !headerNotifications.length) {
      return;
    }
    const readNotifications = headerNotifications.filter(
      (e: NotificationModel, i: number) => i <= 6 && e.isRead === false
    );
    if (readNotifications.length === 0) return;
    await notificationService.readNotifications(
      readNotifications.map((e: NotificationModel) => e.id)
    );
  }, [headerNotifications]);

  useEffect(() => {
    if (showNotificationBar) {
      readInitialNotifications();
    }
  }, [showNotificationBar, readInitialNotifications]);

  useEffect(() => {
    getNotification(page);
  }, [getNotification, page]);

  return (
    <>
      <header
        className={`${style.header} ${
          true && style.borderBottom
        } flex align-center justify-between ${
          "/dashboard/projects" === pathname ? style.isGray : ""
        }`}
      >
        {/* <Link className={style.header__logo} to="/dashboard/projects">
          <img src={logo} alt="Bonzai" />
        </Link>
        <SectionHeader></SectionHeader>
        </Link> */}

        {!(id || sectionId) && (
          <div className={style.header__title}>Bonzai Projects</div>
        )}

        <SectionHeader></SectionHeader>
        {user && (
          <DropdownMenu onClose={() => setShowNotificationBar(false)}>
            <>
              <div
                className={style.header__notification}
                onClick={toggleDropdownForNotification}
              >
                <img src={NotificationIcon} alt="notification" />
                {unreadCount > 0 && (
                  <cite className="flex align-center justify-center">
                    {unreadCount > 99 ? `99+` : unreadCount}
                  </cite>
                )}

                {showNotificationBar && (
                  <div className={style.header__notificationList}>
                    <div
                      className={`${style.header__notificationHeader} flex justify-between`}
                    >
                      Notifications
                      {headerNotifications.length > 0 && (
                        <a onClick={(e) => handleClearAllNotifications(e)}>
                          Clear All
                        </a>
                      )}
                    </div>

                    {headerNotifications.length > 0 ? (
                      <>
                        <div
                          id="notificationBody"
                          className={style.header__scroll}
                        >
                          <InfiniteScroll
                            dataLength={headerNotifications.length}
                            next={() => {
                              if (loading) {
                                return;
                              }
                              setPage((prev) => prev + 1);
                            }}
                            onScroll={updateReadCount}
                            hasMore={true}
                            loader={
                              loading ? (
                                <div
                                  className={style.header__notificationLoading}
                                >
                                  Loading...
                                </div>
                              ) : (
                                <></>
                              )
                            }
                            scrollableTarget="notificationBody"
                          >
                            <ul>
                              {headerNotifications.map(
                                (el: NotificationModel) => {
                                  return (
                                    <li
                                      key={el.id}
                                      onClick={() =>
                                        handleNotificationClick(el)
                                      }
                                    >
                                      <p className={style.isRead}>
                                        {el.message}
                                      </p>
                                      <img
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          if (isDeleting) {
                                            return;
                                          }
                                          handleDeleteNotification(el.id);
                                        }}
                                        src={CloseIcon}
                                        alt="X"
                                      />
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </InfiniteScroll>
                        </div>
                      </>
                    ) : (
                      <div className={style.header__notificationLoading}>
                        No New Notifications
                      </div>
                    )}
                    <div
                      className={`${style.header__notificationClear} flex justify-center`}
                    >
                      <Link to="/dashboard/notifications">See All</Link>
                    </div>
                  </div>
                )}
              </div>
            </>
          </DropdownMenu>
        )}
        {!userDetails.id ? (
          <div className={style.header__profile}>
            <div className={`${style.header__profileHolder} flex align-center`}>
              <SkeletonLoader width="32px" height="32px" circle={true} />
              <aside>
                <SkeletonLoader width="80px" height="10px" margin="0 0 3px" />
                <SkeletonLoader width="60px" height="8px" />
              </aside>
            </div>
          </div>
        ) : (
          <DropdownMenu onClose={() => setDropdownVisible(false)}>
            <div className={style.header__profile}>
              <div
                className={`${style.header__profileHolder} flex align-center`}
                onClick={toggleDropdown}
              >
                {userDetails?.image ? (
                  <img src={userDetails?.image} alt="Profie" />
                ) : (
                  <img src={UserImage} alt="Profie" />
                )}

                <aside>
                  <strong>{userDetails?.name}</strong>
                  <span>
                    {userDetails?.activity === "Project Developer"
                      ? "Developer"
                      : userDetails.activity}
                  </span>
                </aside>
              </div>
              <ul
                onClick={() => setDropdownVisible(false)}
                className={isDropdownVisible ? style.active : ""}
              >
                <li onClick={() => navigate("/dashboard/account")}>
                  <img src={ProfileIcon} alt="Profile" />
                  My Account
                </li>
                <li onClick={() => navigate("/dashboard/projects?tab=tasks")}>
                  <img src={TaskIcon} alt="Profile" />
                  My Tasks
                </li>
                <li>
                  <img src={QuestionIcon} alt="?" />
                  Help Center
                </li>
                <li onClick={handleLogout}>
                  <img src={LogoutIcon} alt="Logout" />
                  Logout
                </li>
              </ul>
            </div>
          </DropdownMenu>
        )}
        {/* <a
          onClick={() => {
            setShowNav(!showNav);
            setDropdownVisible(false);
          }}
          className={`${style.header__hamburger} ${showNav && style.active}`}
        >
          <span></span>
        </a> */}
      </header>
      <div className={style.header__holder}></div>
      {/* 
      <Sidebar
        SidebarTabData={SIDEBAR_TABS}
        showNav={showNav}
        setShowNav={setShowNav}
      /> */}
    </>
  );
};

export default Header;
