import { useEffect, useRef } from "react";
import CloseIcon from "../../assets/images/close.svg";
import style from "./CustomModal.module.scss";

export interface CustomModalProps {
  title?: string;
  children: JSX.Element;
  footerNode?: JSX.Element;
  maxWidth?: string;
  modalVisible: boolean;
  transition?: boolean;
  spacingAround?: boolean;
  onClose?: () => void;
  scrollEnable?: boolean;
  headerSeparator?: boolean;
  titlePostFixNode?: JSX.Element;
}

const CustomModal = ({
  title = "",
  children,
  footerNode,
  maxWidth = "700px",
  modalVisible = false,
  transition = true,
  spacingAround = true,
  onClose,
  scrollEnable = true,
  headerSeparator = false,
  titlePostFixNode,
}: CustomModalProps) => {
  const myElementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (myElementRef.current && modalVisible) {
      myElementRef.current.scrollTo(0, 0);
    }
  }, [modalVisible]);

  useEffect(() => {
    const body = document.body;
    if (modalVisible) body.classList.add("scroll-disabled");
    return () => {
      body.classList.remove("scroll-disabled");
    };
  }, [modalVisible]);
  return (
    <div
      className={`${style.customModal} ${modalVisible && style.active} ${
        !transition && style.transitionDisable
      } ${!scrollEnable && style.isScroll} ${
        headerSeparator && style.bottomBorder
      }`}
    >
      <div className={style.customModal__backdrop} onClick={onClose}></div>
      <div
        className={style.customModal__content}
        style={{ maxWidth: maxWidth }}
      >
        {title && (
          <header
            className={`${style.customModal__header} flex align-center justify-between`}
          >
            <h3>
              {title} {titlePostFixNode ? titlePostFixNode : <></>}
            </h3>
            <img onClick={onClose} src={CloseIcon} alt="x" />
          </header>
        )}

        <div
          className={`${style.customModal__inner} ${
            !spacingAround && style.noSpacing
          } ${!scrollEnable && style.noScroll}`}
          ref={myElementRef}
        >
          {children}
        </div>

        {footerNode && (
          <footer className={`${style.customModal__footer} flex justify-end`}>
            {footerNode}
          </footer>
        )}
      </div>
    </div>
  );
};
export default CustomModal;
