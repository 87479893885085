import React, { useState } from "react";
import eyeClose from "../../assets/images/eye-close.svg";
import eyeOpen from "../../assets/images/eye-open.svg";
import editIcon from "../../assets/images/edit.svg";
import { CustomInputProps } from "../../interfaces/interface";
import style from "./Input.module.scss";

const Input: React.FC<CustomInputProps> = ({
  label,
  type,
  value,
  placeholder,
  errorMessage = "",
  register,
  registerName = "",
  disabled = false,
  min = "",
  marginBottom = true,
  readOnly = false,
  prefixIcon = "",
  editableIcon = false,
  maxlength
}: CustomInputProps) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible((prev) => !prev);
  };

  return (
    <div
      className={`${style.inputField} ${
        (type === "password" || editableIcon) && style.inputPassword
      } ${!marginBottom && style.noSpacing}`}
    >
      <div className={style.inputField__inner}>
        {type === "textArea" ? (
          <textarea
            placeholder={placeholder}
            value={value}
            name={registerName}
            {...register(registerName)}
            rows="4"
            cols="83"
          />
        ) : (
          <>
            <input
              type={type === "password" && isPasswordVisible ? "text" : type}
              placeholder={placeholder}
              value={value}
              name={registerName}
              disabled={disabled}
              {...register(registerName)}
              onWheelCapture={(e) => {
                e.currentTarget.blur();
              }}
              min={min}
              readOnly={readOnly}
              maxlength={maxlength}
            />
            {prefixIcon && (
              <div className={`${style.inputField__icon}`}>
                <img src={prefixIcon} alt="icon" />
              </div>
            )}
            {editableIcon && (
              <div className={`${style.inputField__edit}`}>
                <img src={editIcon} alt="edit" />
              </div>
            )}
            {type === "password" && (
              <div className={`${style.inputField__visibility}`}>
                <img
                  src={isPasswordVisible ? eyeOpen : eyeClose}
                  alt="eye"
                  onClick={togglePasswordVisibility}
                />
              </div>
            )}
          </>
        )}
      </div>
      {errorMessage && <span>{errorMessage}</span>}
    </div>
  );
};

export default Input;
