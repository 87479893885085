import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

const useHideSidebar = () => {
  const location = useLocation();
  const { id } = useParams();

  const hideSideBar = useMemo(() => {
    const { pathname } = location;
    return pathname.toLowerCase().includes(`/dashboard/details/${id}`);
  }, [location]);
  return hideSideBar;
};
export default useHideSidebar;
