import { useMemo } from "react";

const CommentContent = ({
  comment,
  tags,
}: {
  comment: string;
  tags: { id: string; tag: string }[];
}) => {
  const commentTags = useMemo(() => tags?.map((el: any) => el.tag), [tags]);
  const commentArray = useMemo(() => {
    let formattedComment = comment;
    commentTags.forEach((el) => {
      formattedComment = formattedComment.replaceAll(el, `<>${el}<>`);
    });
    return formattedComment.split("<>");
  }, [comment, commentTags]);

  return (
    <>
      {commentArray.map((el: string) =>
        commentTags.includes(el) ? <strong> {el}</strong> : ` ${el}`
      )}
    </>
  );
};
export default CommentContent;
