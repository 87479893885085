import { useEffect, useState } from "react";
import style from "./ProjectWrapper.module.scss";
import Button from "../../atoms/Button/Button";
import AddIcon from "../../assets/images/plus.svg";
import TabButtons from "../TabButtons/TabButtons";
import Projects from "./Projects";
import Tasks from "../Tasks/Tasks";
import AddProject from "../AddProject/AddProject";
import Modal from "../Modal/Modal";
import { useSearchParams } from "react-router-dom";

const ProjectWrapper = () => {
  const [activeTab, setActiveTab] = useState<string>("");
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    setActiveTab(searchParams?.get("tab") ?? "projects");
  }, [searchParams.get("tab")]);

  const addProjectModalClose = async (state: boolean) => {
    setModalShow(state);
  };

  useEffect(() => {
    if (activeTab === "projects") {
      setSearchParams({});
    }
  }, [activeTab]);

  const [getProjects, setGetProjects] = useState<boolean>(false);
  return (
    <div>
      <div className={style.dashboard__content}>
        <header
          className={`${style.dashboard__header} flex-wrap align-center justify-between`}
        >
          <div className={style.dashboard__tab}>
            <TabButtons activeTab={activeTab} setActiveTab={setActiveTab} />
          </div>
          <Button
            label="New Project"
            onClick={() => {
              setModalShow(true);
            }}
            buttonClass="md"
          >
            <img src={AddIcon} alt="+" />
          </Button>
        </header>

        {activeTab === "projects" && (
          <Projects
            setModalShow={setModalShow}
            modalShow={modalShow}
            getProjects={getProjects}
          />
        )}
        {activeTab === "tasks" && <Tasks />}
      </div>
      {modalShow && (
        <Modal
          showModal={modalShow}
          showModalFunction={setModalShow}
          title="Add New Project"
        >
          <AddProject
            close={addProjectModalClose}
            setGetProjects={setGetProjects}
          />
        </Modal>
      )}
    </div>
  );
};

export default ProjectWrapper;
