import { RightSidebarTabsEnum } from "../../enums/Enums";
import { SidebarTab } from "../../interfaces/interface";
import message from "../../assets/images/messageWhite.svg";
import team from "../../assets/images/teamWhite.svg";
import documentDownload from "../../assets/images/document-download.svg";

export const RIGHT_SIDEBAR_TABS: SidebarTab[] = [
  {
    name: RightSidebarTabsEnum.COMMENTS,
    icon: message,
    path: "",
  },
  {
    name: RightSidebarTabsEnum.TEAM_MEMBERS,
    icon: team,
    path: "",
  },
  // {
  //   name: RightSidebarTabsEnum.DOWNLOADS,
  //   icon: documentDownload,
  //   path: "",
  // },
];
