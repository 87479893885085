import React, { useCallback, useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import Select from "react-select";
import Button from "../../atoms/Button/Button";
import { projectUserRolesListWithRemove } from "../../atoms/Constants/dropdown.constant";
import InputSearch from "../../atoms/Input/InputSearch";
import { ProjectUserRoleEnum } from "../../enums/storageEnums";
import { ApiService } from "../../services/apiServices";
import { capitalizeFirstLetter } from "../../utils/utils";
import { toast } from "react-toastify";
import AddIcon from "../../assets/images/add-white.svg";
import RightSideDrawer from "../../components/RightSideDrawer/RightSideDrawer";
import style from "./TeamMembers.module.scss";
import debounce from "lodash.debounce";
import SkeletonLoader from "../../components/Skeleton/Skeleton";
import { selectUserDetails } from "../../store/userDetailsSlice";
import DeleteMemberConfirmationModal from "./DeleteMemberConfirmationModal";
import { teamMemberSchema } from "./teamMember.schema";
import AddMemberModal from "./AddMemberModal";
import { TeamMemberDetails } from "../../components/Section/editor.enum";
import { getProjectById } from "../../store/projectSlice";

const apiService = new ApiService();
const TeamMembers = ({
  show = false,
  setSelectedTab,
}: {
  show: boolean;
  setSelectedTab: any;
}) => {
  const { id } = useParams();
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const userDetails = useSelector(selectUserDetails);
  const methods = useForm({
    resolver: yupResolver(teamMemberSchema),
  });
  const { control } = methods;
  const [teamMembers, setTeamMembers] = useState<TeamMemberDetails[]>([]);

  const [removeUserId, setRemoveUserId] = useState<string | number>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [openConfirmationModalVisible, setOpenConfirmationModalVisible] =
    useState<boolean>(false);

  const debouncedFetchTeamMembers = useRef<any>();
  const [searchText, setSearchText] = useState("");

  const getTeamMembers = useCallback(
    async (searchQuery: string = "") => {
      setLoading(true);
      try {
        const obj = {
          url: `projects/${id}/users`,
          data: {
            search: searchQuery ? searchQuery : undefined,
          },
        };
        const response = await apiService.get(obj);
        setTeamMembers(response.data?.projectUsers ?? []);
      } catch (e) {
        console.log(e);
      } finally {
        setLoading(false);
      }
    },
    [id]
  );

  useEffect(() => {
    getTeamMembers(searchText);
  }, [id, getTeamMembers]);

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 0 || e.target.value.trim()) {
      setSearchText(e.target.value);
    }
  };

  const updateTeamMemberRole = async (
    selectedOption: any,
    teamMember: TeamMemberDetails
  ) => {
    try {
      setLoading(true);
      let data = {
        role: selectedOption.value.toLowerCase(),
      };

      await apiService.put({
        data,
        url: `projects/${id}/users/${teamMember.userId}`,
      });
      getTeamMembers(searchText);
      toast.success("Role change successfully.");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleDropdownChange = async (
    selectedOption: any,
    teamMember: TeamMemberDetails
  ) => {
    if (selectedOption.value === ProjectUserRoleEnum.Remove) {
      setOpenConfirmationModalVisible(true);
      setRemoveUserId(teamMember.userId);
    } else {
      await updateTeamMemberRole(selectedOption, teamMember);
    }
  };

  useEffect(() => {
    debouncedFetchTeamMembers.current?.cancel();
    debouncedFetchTeamMembers.current = debounce(() => {
      getTeamMembers(searchText);
    }, 1000);
    debouncedFetchTeamMembers.current();
  }, [searchText]);

  return (
    <>
      <RightSideDrawer
        title="Team Members"
        show={show}
        setSelectedTab={setSelectedTab}
      >
        <div className={style.teamMember}>
          <div
            className={`${style.teamMember__header} flex-wrap align-center justify-between`}
          >
            <aside className={project?.role !== "owner" ? style.isFull : ""}>
              <InputSearch
                placeholder="Search..."
                onInputChange={handleSearchInputChange}
              />
            </aside>

            {project?.role === "owner" && (
              <aside>
                <a onClick={() => setModalShow(true)}>
                  <img src={AddIcon} alt="+" />
                </a>
                <Button
                  label="+ Add new member"
                  onClick={() => setModalShow(true)}
                />
              </aside>
            )}
          </div>

          {loading ? (
            <>
              <br />
              {Array(6)
                .fill(0)
                .map((v, i) => {
                  return (
                    <div
                      className={`${style.teamMember__item} flex align-center justify-between`}
                      key={i}
                    >
                      <SkeletonLoader width="180px" height="10px" />
                      <SkeletonLoader width="50px" height="10px" />
                    </div>
                  );
                })}
            </>
          ) : teamMembers?.length ? (
            <div className={style.teamMember__list}>
              {teamMembers?.map((teamMember: TeamMemberDetails) => {
                const defaultValue = {
                  label: capitalizeFirstLetter(teamMember.role),
                };
                const handleMemberDropdownChange = (selectedOption: any) => {
                  handleDropdownChange(selectedOption, teamMember);
                };
                const remainingRoles = projectUserRolesListWithRemove.filter(
                  (option) => option.value !== teamMember.role
                );
                const filteredOptions = [
                  {
                    label: capitalizeFirstLetter(teamMember.role),
                    value: teamMember.role,
                    isDisabled: true,
                  },
                  ...remainingRoles.map((option) => ({
                    ...option,
                    isDisabled: option.value === teamMember.role,
                  })),
                ];
                return (
                  <div
                    className={`${style.teamMember__item} flex align-center justify-between`}
                    key={teamMember.userId}
                  >
                    <p>{teamMember.email}</p>
                    {teamMember.role !== "owner" && (
                      <aside>
                        <Controller
                          name="role"
                          control={control}
                          render={({ field }) => (
                            <Select
                              value={defaultValue}
                              isSearchable={false}
                              className={"select-dropdown isSimple"}
                              defaultValue={{
                                label: capitalizeFirstLetter(teamMember.role),
                              }}
                              classNamePrefix={`select-menu`}
                              isDisabled={project?.role !== "owner"}
                              menuPortalTarget={document.body}
                              placeholder={"Select Role"}
                              options={filteredOptions}
                              onChange={handleMemberDropdownChange}
                            />
                          )}
                        />
                      </aside>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            <div className={style.teamMember__nodata}>No team member</div>
          )}
        </div>
      </RightSideDrawer>
      {modalShow && (
        <AddMemberModal
          open={modalShow}
          setOpen={setModalShow}
          projectId={id}
          getTeamMembers={() => getTeamMembers(searchText)}
        />
      )}
      <DeleteMemberConfirmationModal
        open={openConfirmationModalVisible}
        handleClose={() => {
          setOpenConfirmationModalVisible(false);
          setRemoveUserId("");
        }}
        projectId={id}
        getTeamMembers={() => getTeamMembers(searchText)}
        userId={removeUserId}
      />
    </>
  );
};

export default TeamMembers;
