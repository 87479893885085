import React, { ChangeEventHandler } from "react";
import searchIcon from "../../assets/images/search.svg";

import style from "./InputSearch.module.scss";
interface SearchInputProps {
  onInputChange?: ChangeEventHandler | undefined;
  placeholder?: string;
}
const InputSearch: React.FC<SearchInputProps> = ({
  placeholder,
  onInputChange,
}: SearchInputProps) => {
  return (
    <div className={style.inputSearch}>
      <input type="text" placeholder={placeholder} onChange={onInputChange} />
      <img src={searchIcon} alt="Search" />
    </div>
  );
};

export default InputSearch;
