import { useEffect } from "react";
import CloseIcon from "../../assets/images/close.svg";
import style from "./Modal.module.scss";

const Modal = ({
  title = "",
  children,
  footer,
  showModal = false,
  showModalFunction,
}: {
  title?: string;
  children: any;
  footer?: any;
  showModal: boolean;
  showModalFunction: Function;
}) => {
  useEffect(() => {
    const body = document.body;
    if (showModal) body.classList.add("scroll-disabled");
    return () => {
      body.classList.remove("scroll-disabled");
    };
  }, [showModal]);

  return (
    <div className={`${style.modal} ${showModal && style.active}`}>
      <div className={style.modal__backdrop}></div>
      <div className={style.modal__wrap}>
        <header className={style.modal__header}>
          {title && <h4>{title}</h4>}
          <img
            onClick={() => showModalFunction(false)}
            src={CloseIcon}
            alt="X"
          />
        </header>
        <div className={style.modal__content}>{children}</div>
        {footer && (
          <div className={`${style.modal__footer} flex`}>{footer}</div>
        )}
      </div>
    </div>
  );
};
export default Modal;
