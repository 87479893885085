import axios from "axios";
import { ApiDataObject } from "../interfaces/interface";
import { ApiService } from "./apiServices";
import {
  SectionModel,
  ShortSectionModel,
} from "../models/project_section.model";

const apiService = new ApiService();
export class SectionService {
  updateSectionAssignee(projectId: string, sectionId: string, payload: any) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}/users`,
      data: payload,
    };
    return apiService.post(obj);
  }

  getSectionAssignee(projectId: string, sectionId: string) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}/users`,
    };
    return apiService.get(obj);
  }

  updateSectionStatus(projectId: string, sectionId: string, payload: any) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}/stats`,
      data: payload,
    };
    return apiService.put(obj);
  }

  deleteSectionUser(projectId: string, sectionId: string, userId: string) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}/users/${userId}`,
    };
    return apiService.delete(obj);
  }

  processText(payload: any) {
    return axios.post(
      `https://mba0uyz8xl.execute-api.us-east-2.amazonaws.com/Prod/process-text`,
      payload,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
  }

  getSectionStats(projectId: string, sectionId: string) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}`,
    };
    return apiService.get(obj);
  }

  fetchSectionById(projectId: string, sectionId: string | number) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}`,
    };
    return apiService.get(obj);
  }
  createAppendixSection(projectId: string, payload: any) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections`,
      data: payload,
    };
    return apiService.post(obj);
  }
  deleteAppendixSection(projectId: string, sectionId: string) {
    const obj: ApiDataObject = {
      url: `projects/${projectId}/sections/${sectionId}`,
    };
    return apiService.delete(obj);
  }
  static formatSectionList(sections: ShortSectionModel[]) {
    let appendixCount = 0;
    return sections.map((section: ShortSectionModel) => {
      if (section.isAppendix) {
        appendixCount++;
        return {
          ...new ShortSectionModel({
            ...section,
            name: `APPENDIX ${appendixCount}: ${section?.name}`,
          }),
        };
      } else {
        return section;
      }
    });
  }
  static displaySectionName(
    section: SectionModel | undefined,
    sectionList: ShortSectionModel[]
  ) {
    if (section?.name) {
      if (section.isAppendix) {
        return `${sectionList?.find((el) => section.id === el.id)?.name ?? ""}`;
      }
      let nameArray = section?.name.split(" ");
      let sectionNumber = nameArray.shift();
      return `${sectionNumber} - ${nameArray.join(" ")}`;
    }
    return "";
  }

  static sectionDetailsWithoutTemplateUpdation(
    sectionId: string | number,
    parentId: string | number,
    newData: SectionModel,
    oldData: SectionModel
  ): SectionModel {
    if (sectionId === parentId) {
      return { ...newData, template: oldData.template ,children:oldData.children};
    } else {
      const children = oldData.children;
      const updatedData = children.map((el) =>
        el.id === sectionId ? { ...newData, template: el.template } : el
      );
      return { ...oldData, children: updatedData };
    }
  }
}
