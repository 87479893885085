import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class AuthService {
  handleRefreshToken = async (refreshToken: string) => {
    const obj = {
      url: "auth/refresh",
      data: { refreshToken: refreshToken },
    };
    return apiService.post(obj);
  };
}
