import { ActionType, TableDataType, TableHeaderProps } from "../Table/Table";

export const PROJECTS_HEADINGS: TableHeaderProps[] = [
  {
    name: "name",
    title: "Name ",
    onClick: (rowData: any) => {},
    type: TableDataType.TEXT,
  },
  {
    name: "location",
    title: "Location",
    type: TableDataType.TEXT,
  },
  {
    name: "registry",
    title: "Registry ",
    type: TableDataType.TEXT,
  },
  {
    name: "methodology",
    title: "Methodology",
    type: TableDataType.TEXT,
  },

  {
    name: "status",
    title: "Status",
    type: TableDataType.TEXT,
  },
  {
    name: "role",
    title: "My Role",
    type: TableDataType.TEXT,
  },
  {
    name: "action",
    title: "Action",
    type: TableDataType.ACTIONS,
    actionType: [
      {
        icon: "",
        name: ActionType.DELETE,
        onClick: (rowData: any) => {},
        customClass: "",
        customStyle: {},
      },
    ],
  },
];
