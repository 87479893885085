import * as yup from "yup";

export const confirmCodeSchema = yup.object().shape({
  uniqueCode: yup.lazy((value) =>
    value === ""
      ? yup.string().required("Code must be a number")
      : yup
          .number()
          .required("Code is required")
          .typeError("Code must be a number")
  ),
  newPassword: yup
    .string()
    .required("Password is required")
    .matches(
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^A-Za-z0-9]).{8,}$/,
      "Password must contain at least 8 characters, a number, a special character, a lowercase character, and an uppercase character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("newPassword"), ""], "Passwords must match")
    .required("Confirm Password is required"),
});
