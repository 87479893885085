import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, FieldValues, useForm } from "react-hook-form";
import Button from "../../../../atoms/Button/Button";
import style from "./TextBlockNodeView.module.scss";
import { AddLinkSchema } from "./add-link.schema";

export interface AddLinkProps {
  linkRef: any;
  addLinkToEditor: (url: string) => void;
}
const AddLink = ({ linkRef, addLinkToEditor }: AddLinkProps) => {
  const {
    formState: { isValid },
    handleSubmit,
    control,
  } = useForm({
    resolver: yupResolver(AddLinkSchema),
  });

  const addLink = (values: FieldValues) => {
    let completeLink = values.link;
    if (
      !(completeLink.includes("http://") || completeLink.includes("https://"))
    ) {
      completeLink = `https://${completeLink}`;
    }
    addLinkToEditor(completeLink);
  };

  return (
    <>
      <form
        className={`${style.richTextEditor__link} isShow flex align-center`}
      >
        <Controller
          name={"link"}
          control={control}
          render={({ field: { onChange, value } }) => (
            <input
              type="text"
              onChange={onChange}
              value={value}
              ref={linkRef}
            />
          )}
        />
        <Button
          type="submit"
          label="Apply"
          disabled={!isValid}
          onClick={handleSubmit(addLink)}
        />
      </form>
    </>
  );
};
export default AddLink;
