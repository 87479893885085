import { useEffect, useState } from "react";
import { getCookie, isValidTextApp } from "../utils/utils";

const useAuthenticated = () => {
  const [authenticated, setAuthenticated] = useState(false);
  const authenticateUser = () => {
    let isAuthenticated = false;

    while (!isAuthenticated) {
      let username = prompt("Username:");

      while (!username || username.trim() === "" || !isValidTextApp(username)) {
        username = prompt("Username:");
      }

      let password = prompt("Password:");

      while (!password || password.trim() === "" || !isValidTextApp(password)) {
        password = prompt("Password:");
      }
      if (
        username === process.env.REACT_APP_USER_NAME &&
        password === process.env.REACT_APP_PASSWORD
      ) {
        isAuthenticated = true;
        document.cookie = "authenticatedUser=true";
      } else {
        alert("Invalid! Please try again.");
      }
    }

    return true;
  };
  useEffect(() => {
    const cookieValue = getCookie("authenticatedUser");
    if (
      cookieValue === "true" ||
      process.env.REACT_APP_ENVIRONMENT === "PROD"
    ) {
      setAuthenticated(true);
    } else {
      setAuthenticated(authenticateUser());
    }
  }, []);

  return authenticated;
};

export default useAuthenticated;
