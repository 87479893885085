import { ProjectModel } from "../models/project.model";
import { ApiService } from "./apiServices";

const apiService = new ApiService();
export class OpenAiService {
  sendMessage(data: any) {
    const obj = {
      url: `chat-bot`,
      data: data,
    };
    return apiService.post(obj);
  }
  getInitialChat() {
    const obj = {
      url: `chat-bot`,
    };
    return apiService.get(obj);
  }

  convertToSmartPrompt(message: string, project: ProjectModel | undefined) {
    if (project) {
      return `General Context: I am a carbon credit project developer working on ${
        project?.name || ""
      }, a carbon offset project located in ${
        project.location
      }. The project follows methodology ${
        project.methodology
      } and will be submitted to ${
        project.registry
      } for registration. Currently, I am focusing on project, although my question may not necessarily pertain to this section and could encompass other aspects of the project or general inquiries.
      User Prompt: ${message}
      Output Instructions: Please provide a response no longer than 150 words. Your tone should be conversational yet professional. Ensure the response is concise, avoiding unnecessary jargon, but provides highly technical and specific information. Include short, concise examples when relevant. Include data and statistics with sources; if a source cannot be provided, omit the data point. Focus on solution-oriented responses, relevant to preparing project documentation for submission to third-party auditors and registries.`;
    }

    return `General Context: I am a carbon credit project developer working on my Project Design Description.
    User Prompt: ${message}
    Output Instructions: Please provide a response no longer than 150 words. Your tone should be conversational yet professional. Ensure the response is concise, avoiding unnecessary jargon, but provides highly technical and specific information. Include short, concise examples when relevant. Include data and statistics with sources; if a source cannot be provided, omit the data point. Focus on solution-oriented responses, relevant to preparing project documentation for submission to third-party auditors and registries.`;
  }
}
