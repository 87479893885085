import { ActionType, TableDataType, TableHeaderProps } from "../Table/Table";

export const TASKS_HEADINGS: TableHeaderProps[] = [
  {
    name: "projectName",
    title: "Project name ",
    type: TableDataType.TEXT,
  },
  {
    name: "sectionName",
    title: "Section name",
    type: TableDataType.TEXT,
  },
  {
    name: "status",
    title: "Section status ",
    type: TableDataType.TEXT,
  },
  {
    name: "dueDate",
    title: "Due date",
    type: TableDataType.TEXT,
  },

];

export const TaskData = [
  {
    projectName:"Keniya Forest Management",
    sectionName:"Data and Parameters Available at Validation",
    status:"completed",
    dueDate:"01.11.2023"
  },
  {
    projectName:"Keniya Forest Management",
    sectionName:"Data and Parameters Available at Validation",
    status:"inProgress",
    dueDate:"01.11.2023"
  },
  {
    projectName:"Keniya Forest Management",
    sectionName:"Data and Parameters Available at Validation",
    status:"open",
    dueDate:"01.11.2023"
  }
]
