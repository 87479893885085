import React from "react";
import { Control, Controller, useController } from "react-hook-form";
import Select from "react-select";
import style from "./dropdown.module.scss";

export interface DropDownDefaultType {
  value?: string;
  label?: string;
}
interface DropdownProps {
  label?: string;
  name?: string;
  options?: { value: string | number; label?: string }[];
  control?: Control<any>;
  lightMode?: boolean;
  multiSelection?: boolean;
  error?: string;
  isDisable?: boolean;
  defaultValue?: DropDownDefaultType;
  isSearchable?: boolean;
  className?: string;
  prefixClassName?: string;
  bottomSpace?: boolean;
  optionsAlignRight?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  label,
  name = "",
  options,
  multiSelection = false,
  lightMode = false,
  control,
  error,
  isDisable = false,
  defaultValue,
  isSearchable = true,
  className,
  prefixClassName,
  bottomSpace = true,
  optionsAlignRight = false,
}) => {
  const { field } = useController({
    name,
    control,
  });
  return (
    <div
      className={`${style.dropdownField} ${isDisable && style.isDisable} ${
        !bottomSpace && style.noSpace
      }`}
    >
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <Select
              menuPosition={"fixed"}
              defaultValue={defaultValue}
              menuPortalTarget={document.body}
              placeholder={label}
              className={`select-dropdown ${
                lightMode ? "is-light" : ""
              } ${className} ${field.value ? "is-active" : ""}`}
              classNamePrefix={`${prefixClassName} ${
                optionsAlignRight && "menuAlignRight"
              } select-dropdown`}
              isMulti={multiSelection}
              options={options}
              onChange={field.onChange}
              isSearchable={isSearchable}
              value={field.value}
              isOptionDisabled={(option) => option.disabled}
            />
          );
        }}
      ></Controller>
      {error && <span className={style.dropdownField__error}>{error}</span>}
    </div>
  );
};

export default Dropdown;
