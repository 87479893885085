import { useState } from "react";
import Button from "../../atoms/Button/Button";
import CustomModal from "../../components/CustomModal/CustomModal";
import { ApiService } from "../../services/apiServices";
import { toast } from "react-toastify";
const apiService = new ApiService();
const DeleteMemberConfirmationModal = ({
  open,
  handleClose,
  projectId,
  getTeamMembers,
  userId,
}: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const handleDeleteTeamMember = async () => {
    try {
      setLoading(true);
      await apiService.delete({
        url: `projects/${projectId}/users/${userId}`,
      });
      await getTeamMembers();
      toast.success("Team member deleted successfully.");
    } catch (error) {
      console.error(error);
    } finally {
      handleClose();
      setLoading(false);
    }
  };
  return (
    <CustomModal
      maxWidth="470px"
      title="Are you sure?"
      modalVisible={open}
      onClose={handleClose}
      footerNode={
        <>
          <Button buttonClass="sm outline" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            buttonClass="sm"
            onClick={handleDeleteTeamMember}
          >
            Remove
          </Button>
        </>
      }
    >
      <p>
        Are you sure you want to remove this member? They may not be able to
        access this file anymore.
      </p>
    </CustomModal>
  );
};
export default DeleteMemberConfirmationModal;
