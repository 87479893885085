import { ApiService } from "./apiServices";
const apiService = new ApiService();

export class CommentsService {
  getComments(
    projectId: string,
    sectionId: string,
    moduleId: string,
    page: number | string
  ) {
    let obj = {
      url: `projects/${projectId}${sectionId ? "/sections/" + sectionId : ""}${
        moduleId ? "/modules/" + moduleId : ""
      }/comments?page=${page}&limit=10`,
    };
    return apiService.get(obj);
  }
  addComments(
    projectId: string,
    sectionId: string,
    moduleId: string,
    payload: any
  ) {
    const obj = {
      data: payload,
      url: `projects/${projectId}/sections/${sectionId}${
        moduleId ? "/modules/" + moduleId : ""
      }/comments`,
    };
    return apiService.post(obj);
  }

  deleteComments(
    projectId: string,
    sectionId: string,
    moduleId: string,
    commentId: string
  ) {
    const obj = {
      url: `projects/${projectId}/sections/${sectionId}${
        moduleId ? "/modules/" + moduleId : ""
      }/comments/${commentId}`,
    };
    return apiService.delete(obj);
  }

  updateComment(
    projectId: string,
    sectionId: string,
    moduleId: string,
    commentId: string,
    payload: any
  ) {
    const obj = {
      data: payload,
      url: `projects/${projectId}/sections/${sectionId}${
        moduleId ? "/modules/" + moduleId : ""
      }/comments/${commentId}`,
    };
    return apiService.put(obj);
  }
}
