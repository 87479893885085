import * as yup from "yup";

export const addProjectSchema1 = yup.object().shape({
  name: yup.string().trim().required("Name is required"),
  location: yup.object().required("Location is required"),
  registry: yup.object().required("Registry is required"),
  methodology: yup.object().required("Methodology is required"),
  template: yup.object().required("Template is required"),
  description: yup.string(),
});

export const addProjectSchema2 = yup.object().shape({
  inviteEmail: yup.string(),
});
