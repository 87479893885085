import { createSlice } from '@reduxjs/toolkit';

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    authUser: null,
    cognitoUser: null,
    jwtToken: null,
    email: '',
  },
  reducers: {
    setAuthUser: (state, action) => {
      state.authUser = action.payload;
    },
    setCognitoUser: (state, action) => {
      state.cognitoUser = action.payload;
    },
    setJWTToken: (state, action) => {
      state.jwtToken = action.payload;
    },
    setEmail: (state, action) => {
      state.email = action.payload;
    },
  },
});

export const { setAuthUser, setCognitoUser, setJWTToken, setEmail } = authSlice.actions;

export default authSlice.reducer;
