import { useCallback, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import AddProfile from "../../../assets/images/profile-add.svg";
import UserImage from "../../../assets/images/profile-img.svg";
import { ProjectUserRoleEnum } from "../../../enums/storageEnums";
import { SectionModel } from "../../../models/project_section.model";
import { ProjectService } from "../../../services/project.service";
import { SectionService } from "../../../services/section.service";
import {
  getProjectById,
  getSectionList,
  setSectionList,
} from "../../../store/projectSlice";
import {
  getSectionAssignees,
  getSectionDetails,
  setSectionDetails,
} from "../../../store/sectionSlice";
import SectionManagementModal from "../../Section/Components/SectionManagement/SectionManagementModal";
import SkeletonLoader from "../../Skeleton/Skeleton";
import style from "./SectionHeader.module.scss";
const sectionService = new SectionService();
const projectService = new ProjectService();

const SectionHeader = () => {
  const { id, sectionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const sectionList = useSelector((state: any) =>
    getSectionList(state, id ?? "")
  );
  const sectionDetails: SectionModel | undefined = useSelector((state: any) =>
    getSectionDetails(state, sectionId ?? "")
  );
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const [openSectionManagement, setOpenSectionManagement] = useState(false);
  const sectionAssignees = useSelector((state: any) =>
    getSectionAssignees(state, sectionId ?? "")
  );
  const sectionName = useMemo(() => {
    return SectionService.displaySectionName(sectionDetails, sectionList);
  }, [sectionDetails, sectionList]);

  const handleSectionDelete = async () => {
    setOpenSectionManagement(false);
    await getProjectSectionList(id ?? "");
    navigate(`/dashboard/details/${id}/section/${sectionList[0].id}`);
  };
  const updateSectionWithoutTemplate = useCallback(
    async (
      projectId: string,
      sectionId: string | number,
      parentId: string | number
    ) => {
      try {
        const res: any = await sectionService.fetchSectionById(
          projectId,
          sectionId
        );
        if (res && sectionDetails) {
          const section = SectionService.sectionDetailsWithoutTemplateUpdation(
            sectionId,
            parentId,
            res.data.section,
            sectionDetails
          );
          dispatch(setSectionDetails(section));
        }
      } catch (error) {
        console.error(error);
        navigate("/dashboard/projects");
      }
    },
    [dispatch, sectionDetails, navigate]
  );

  const getProjectSectionList = useCallback(
    async (projectId: string) => {
      try {
        const res = await projectService.fetchProjectSectionList(projectId);
        let list = SectionService.formatSectionList(res?.data?.sections || []);
        dispatch(setSectionList({ id: id ?? "", list: list }));
      } catch (error) {
        console.error(error);
        navigate("/dashboard/projects");
      }
    },
    [dispatch, id, navigate]
  );
  return (
    <>
      <div className={style.headerCaption}>
        {id && (
          <>
            {sectionDetails ? (
              <>
                <div className={style.headerCaption__title}>
                  <span>{sectionName}</span>
                </div>
                <picture>
                  {sectionAssignees.map((assignee) =>
                    assignee?.image ? (
                      <img src={assignee?.image} alt="" />
                    ) : (
                      <img src={UserImage} alt="" />
                    )
                  )}
                </picture>
                {(project?.role === ProjectUserRoleEnum.OWNER ||
                  (sectionDetails?.isAppendix &&
                    project?.role !== ProjectUserRoleEnum.VIEWER)) &&
                  id && (
                    <img
                      src={AddProfile}
                      alt="Setting"
                      onClick={() => setOpenSectionManagement(true)}
                    />
                  )}
              </>
            ) : (
              <SkeletonLoader
                width="200px"
                height="20px"
                baseColor="#fff"
                highlightColor="#fef"
              />
            )}
          </>
        )}
      </div>
      {openSectionManagement && (
        <SectionManagementModal
          open={openSectionManagement}
          onClose={() => {
            setOpenSectionManagement(false);
            if (id && sectionId) {
              updateSectionWithoutTemplate(id, sectionId, sectionId);
            }
          }}
          section={sectionDetails}
          onSectionDelete={handleSectionDelete}
        />
      )}
    </>
  );
};
export default SectionHeader;
