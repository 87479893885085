import editorStyle from "../../../../assets/scss/editorMenu.module.scss";

export interface EditorButtonProps {
  onClick: () => void;
  disabled: boolean;
  icon: string;
  isActive: boolean;
  shortCutMac?: string;
  shortCutWindows?: string;
  name?: string;
}

const EditorButton = ({
  onClick,
  disabled,
  isActive,
  icon,
  shortCutMac,
  name,
  shortCutWindows,
}: EditorButtonProps) => {
  const isMac = navigator.platform.indexOf("Mac") > -1;

  return (
    <div className={editorStyle.editorMenu__groupItem}>
      <button
        onClick={onClick}
        disabled={disabled}
        className={isActive ? editorStyle.isActive : ""}
      >
        <img src={icon} alt="I" />
      </button>

      {name && (
        <div className={editorStyle.editorMenu__groupInfo}>
          {name && <strong>{name}</strong>}
          {shortCutMac && shortCutWindows ? (
            <span>{isMac ? shortCutMac : shortCutWindows}</span>
          ) : (
            <></>
          )}
        </div>
      )}
    </div>
  );
};
export default EditorButton;
