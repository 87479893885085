import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ChatModel, ChatModelEnum } from "../models/chatModel";

const chatSlice = createSlice({
  name: "chat",

  initialState: {
    isChatVisible: false,
    chats: [] as ChatModel[],
    isLoading: false,
  },
  reducers: {
    addChat(
      state,
      action: {
        payload: ChatModel[];
      }
    ) {
      state.chats.push(...action.payload);
    },
    setChat(
      state,
      action: {
        payload: ChatModel[];
      }
    ) {
      return {
        ...state,
        chats: [...action.payload],
      };
    },
    showChatModal(state, action: PayloadAction<boolean>) {
      state.isChatVisible = action.payload;
      return state;
    },
    showLoadingMessage(state, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
      return state;
    },
  },
});

export const { addChat, showChatModal, showLoadingMessage, setChat } =
  chatSlice.actions;

export default chatSlice.reducer;
