import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../../assets/images/close.svg";
import AddProfile from "../../../../assets/images/profile-add.svg";
import Dropdown from "../../../../atoms/Dropdown/Dropdown";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";
import { ChatModel } from "../../../../models/chatModel";
import { BaseUserModel } from "../../../../models/user.model";
import { OpenAiService } from "../../../../services/openAIService";
import { SectionService } from "../../../../services/section.service";
import { addChat, showChatModal } from "../../../../store/chatSlice";
import { getProject } from "../../../../store/projectSlice";
import { getFormattedDueDate } from "../../../../utils/utils";
import CustomModal from "../../../CustomModal/CustomModal";
import SkeletonLoader from "../../../Skeleton/Skeleton";
import { SECTION_STATUS, STATUS_COLOR } from "../../editor.constants";
import { ProjectSectionStatus } from "../../editor.enum";
import style from "./SectionManagement.module.scss";
import { useParams } from "react-router";
import { ApiService } from "../../../../services/apiServices";
import { SectionModel } from "../../../../models/project_section.model";
import DeleteIcon from "../../../../assets/images/delete-outline.svg";
import {
  getSectionAssignees,
  getSectionDetails,
  setSectionAssignees,
  setSectionDetails,
} from "../../../../store/sectionSlice";
import UserImage from "../../../../assets/images/profile-img.svg";
import Button from "../../../../atoms/Button/Button";
import GlobalLoader from "../../../GlobalLodaer/GlobalLoader";
const sectionService = new SectionService();
const openAiService = new OpenAiService();
const apiService = new ApiService();

interface SectionManagementInterfaceProps {
  open: boolean;
  onClose: () => void;
  section: SectionModel | undefined;
  onSectionDelete?: () => void;
}

const SectionManagementModal = ({
  open,
  onClose,
  section,
  onSectionDelete,
}: SectionManagementInterfaceProps) => {
  const { id ,sectionId} = useParams();
  const { control, reset, register, watch, setValue, trigger } = useForm<any>({
    defaultValues: {
      dueDate: "",
      status:
        SECTION_STATUS.find((option) => option.value === section?.status) ??
        SECTION_STATUS[2],
      user: null,
    },
  });
  const [dataLoaded, setDataLoaded] = useState(false);
  const selectedUser = useSelector((state: any) =>
    getSectionAssignees(state, section?.id ?? "")
  );
  const [teamMembers, setTeamMembers] = useState([]);
  const assigneeOptions = useMemo(() => {
    const selectedUserIds = selectedUser?.map((user: BaseUserModel) => user.id);
    const filterUser = teamMembers.filter(
      (el: any) =>
        el?.userId &&
        !selectedUserIds.includes(el?.userId?.toString()) &&
        el?.role !== ProjectUserRoleEnum.VIEWER
    );
    return (
      filterUser?.map((el: any) => ({
        value: el,
        label: `${el.name || "-"} (${el.email})`,
      })) ?? []
    );
  }, [selectedUser, teamMembers]);

  const dueDateRef = useRef<any>();
  const { ref, ...dueDateRest } = register("dueDate");
  const dispatch = useDispatch();
  const project = useSelector(getProject);
  const [isDeleting, setIsDeleting] = useState(false);
  const sectionDetails: SectionModel | undefined = useSelector((state: any) =>
  getSectionDetails(state, sectionId ?? "")
);
  const updateSectionAssignee = async (user: any) => {
    try {
      if (!id || !section?.id) {
        return;
      }
      const payload = {
        userIds: [user.userId],
      };
      dispatch(
        setSectionAssignees({
          sectionId: section?.id ?? "",
          assignees: [
            ...selectedUser,
            {
              id: user?.userId ?? "",
              name: user?.name ?? "",
              email: user?.email ?? "",
              image: user?.image ?? "",
            },
          ],
        })
      );
      await sectionService.updateSectionAssignee(id, section?.id, payload);
      setValue("user", null);
    } catch (error) {
      console.error(error);
    }
  };

  const getTeamMembers = useCallback(
    async (id: string) => {
      try {
        const obj = {
          url: `projects/${id}/users`,
        };
        const response = await apiService.get(obj);
        setTeamMembers(response.data.projectUsers);
      } catch (e) {
        console.log(e);
      }
    },
    [id]
  );

  const updateSectionStatus = async (dueDate: string, status: string) => {
    if (!id || !section?.id) {
      return;
    }
    try {
      const payload = {
        status: status ? status : undefined,
        dueDate: dueDate ? dueDate : undefined,
      };
      await sectionService.updateSectionStatus(id, section?.id, payload);
    } catch (error) {
      console.log(error);
    }
  };

  const getSectionValues = useCallback(
    async (id: string, sId: string) => {
      try {
        setDataLoaded(false);
        const res = await sectionService.getSectionStats(id, sId);
        if(sectionId&&sectionDetails){
          const section=SectionService.sectionDetailsWithoutTemplateUpdation(sId,sectionId,res.data.section,sectionDetails);
          dispatch(setSectionDetails(section));
        }
        reset({
          dueDate: res.data?.section?.dueDate || "",
          status:
            SECTION_STATUS.find((e) => e.value === res.data.section.status) ||
            SECTION_STATUS[0],
        });
        trigger();
        setDataLoaded(true);
      } catch (error) {
        setDataLoaded(false);
      }
    },
    [reset, trigger]
  );

  useEffect(() => {
    if (!id || !section?.id) {
      return;
    }
    getSectionValues(id, section?.id);
    getTeamMembers(id);
  }, [getSectionValues, id, section?.id]);

  useEffect(() => {
    if (watch("user")?.value) {
      updateSectionAssignee(watch("user")?.value);
    }
  }, [watch("user")]);

  useEffect(() => {
    if (dataLoaded) {
      updateSectionStatus(watch("dueDate"), watch("status")?.value);
    }
  }, [watch("dueDate"), watch("status"), dataLoaded]);

  // useEffect(() => {
  //   if (
  //     dataLoaded &&
  //     watch("status").value !== section?.status &&
  //     watch("status").value === ProjectSectionStatus.COMPLETED
  //   ) {
  //     openChatBot();
  //   }
  // }, [watch("status"), section]);

  const openChatBot = async () => {
    try {
      const message = `Context: A team member has marked a section of our carbon credit application as complete. This section is ready for a review to ensure clarity, conciseness, and alignment with project goals. The project is in ${
        project?.location || ""
      } and follows methodology ${project?.methodology || ""}.
      Prompt: Congratulate the user on completing ${
        section?.name
      } and provide constructive feedback on how to enhance the section, focusing on improving clarity and conciseness without losing any critical details.
      
      Output: Start with "Fantastic job on wrapping up the ${
        section?.name
      }" followed by a line break and "To make it even better, here are a few suggestions:" followed by your bulleted list of improvements. Limit your response to fewer than 250 words. Close with "Keep up the great work! Let me know if you need help implementing these suggestions."`;
      const payload = {
        message: message,
        prompt: message,
        isVisible: false,
      };
      const res = await openAiService.sendMessage(payload);
      dispatch(showChatModal(true));
      const messages = (res.data.messages ?? []).map(
        (_: ChatModel) => new ChatModel(_)
      );
      dispatch(addChat(messages));
    } catch (error) {}
  };

  const deleteAssignee = async (user: BaseUserModel) => {
    if (!id || !section?.id) {
      return;
    }
    const newUser = selectedUser.filter(
      (el: BaseUserModel) => el.id !== user.id
    );
    dispatch(
      setSectionAssignees({ sectionId: section.id, assignees: newUser })
    );
    await sectionService.deleteSectionUser(id, section?.id, user.id);
  };
  const handleDeleteSection = async () => {
    if (!id || !section?.id) {
      return;
    }
    try {
      setIsDeleting(true);
      await sectionService.deleteAppendixSection(id, section?.id);
      onSectionDelete?.();
    } catch (error) {
      console.error(error);
    } finally {
      setIsDeleting(false);
    }
  };

  if (isDeleting) {
    return <GlobalLoader />;
  }

  return (
    <CustomModal
      onClose={() => onClose()}
      modalVisible={open}
      headerSeparator={true}
      title="Section Management"
      maxWidth="633px"
      titlePostFixNode={
        section?.isAppendix ? (
          <img
            src={DeleteIcon}
            onClick={() => handleDeleteSection()}
            alt="Delete"
          />
        ) : undefined
      }
    >
      {dataLoaded ? (
        <div className={style.sectionManagement}>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Status</strong>
            <aside
              style={
                {
                  "--status-color": STATUS_COLOR?.[watch("status").value],
                } as React.CSSProperties
              }
            >
              <Dropdown
                name="status"
                isSearchable={false}
                bottomSpace={false}
                className={"isSimple isStatus"}
                options={SECTION_STATUS}
                control={control}
              />
            </aside>
          </div>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Due date</strong>
            <aside>
              <label htmlFor="dueDate">
                {getFormattedDueDate(watch("dueDate"))}
                <input
                  type="date"
                  id="dueDate"
                  {...dueDateRest}
                  ref={(e) => {
                    ref(e);
                    dueDateRef.current = e; // you can still assign to ref
                  }}
                  onClick={() => dueDateRef.current?.showPicker()}
                />
              </label>
            </aside>
          </div>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Assignee</strong>
            <aside>
              <div
                className={`${style.sectionManagement__userList} flex-wrap align-center`}
              >
                {selectedUser?.map((user: BaseUserModel) => (
                  <div
                    className={`${style.sectionManagement__user} flex align-center`}
                    key={user.id}
                  >
                    <picture>
                      {user.image ? (
                        <img src={user.image} alt="" />
                      ) : (
                        <img src={UserImage} alt="" />
                      )}
                    </picture>

                    <p>{user.name || user.email}</p>
                    <img
                      onClick={() => deleteAssignee(user)}
                      className={style.isClose}
                      src={CloseIcon}
                      alt="Close"
                    />
                  </div>
                ))}
                <div className={`${style.sectionManagement__user}`}>
                  <div className={style.sectionManagement__add}>
                    <button className="flex align-center">
                      <img src={AddProfile} alt="Profile" />
                      <span>Add new</span>
                    </button>
                    <Dropdown
                      name="user"
                      isSearchable={false}
                      className={"isSimple"}
                      bottomSpace={false}
                      options={assigneeOptions}
                      control={control}
                    ></Dropdown>
                  </div>
                </div>
              </div>
            </aside>
          </div>
        </div>
      ) : (
        <>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Status</strong>
            <aside>
              <SkeletonLoader width="150px" margin="5px 0 0" height="15px" />
            </aside>
          </div>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Due date</strong>
            <aside>
              <SkeletonLoader width="150px" margin="5px 0 0" height="15px" />
            </aside>
          </div>
          <div
            className={`${style.sectionManagement__item} flex-wrap align-center`}
          >
            <strong>Assignee</strong>
            <aside>
              <SkeletonLoader width="150px" margin="5px 0 0" height="15px" />
            </aside>
          </div>
        </>
      )}
    </CustomModal>
  );
};
export default SectionManagementModal;
