import { useForm, FieldValues } from "react-hook-form";
import Input from "../../atoms/Input/Input";
import Modal from "../../components/Modal/Modal";
import style from "./MyAccount.module.scss";
import { yupResolver } from "@hookform/resolvers/yup";
import { changePasswordSchema } from "./myAccount.schema";
import { ApiService } from "../../services/apiServices";
import Button from "../../atoms/Button/Button";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

const apiService = new ApiService();
const ChangePassword = ({
  showChangePasswordModal,
  setShowChangePasswordModal,
}: {
  showChangePasswordModal: boolean;
  setShowChangePasswordModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const methods = useForm({
    resolver: yupResolver(changePasswordSchema),
  });
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
    register,
    watch,
    clearErrors,
    setError,
    reset,
  } = methods;

  useEffect(() => {
    if (watch("confirmPassword") === watch("newPassword")) {
      clearErrors("confirmPassword");
    }
  }, [watch("confirmPassword"), watch("newPassword"), setError]);

  const handleChangePassword = async (data: FieldValues) => {
    try {
      setLoading(true);
      let payload = {
        data: {
          newPassword: data.newPassword,
          oldPassword: data.oldPassword.toString(),
        },
        url: "users/change-password",
      };
      await apiService.put(payload);
      toast.success("Password Reset successfully");
      setShowChangePasswordModal(false);
    } catch (error: any) {
      console.log(error);
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        title="Change password"
        showModal={showChangePasswordModal}
        showModalFunction={setShowChangePasswordModal}
      >
        <div className={`${style.auth} ${style.authChange}`}>
          <div className={style.auth__wrap}>
            <form onSubmit={handleSubmit(handleChangePassword)} noValidate>
              <Input
                type="password"
                placeholder="Old Password"
                register={register}
                registerName="oldPassword"
                errorMessage={errors?.oldPassword?.message}
              />
              <Input
                type="password"
                placeholder="New password"
                register={register}
                registerName="newPassword"
                errorMessage={errors?.newPassword?.message}
              />
              <Input
                type="password"
                placeholder="Confirm password"
                register={register}
                registerName="confirmPassword"
                errorMessage={errors?.confirmPassword?.message}
              />
              <Button
                loading={loading}
                disabled={loading}
                type="submit"
                buttonClass={"isFull"}
                label={"Change password"}
              />
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ChangePassword;
