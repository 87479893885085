import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "socket.io-client";

import {
  addComment,
  emitDeleteComment,
  updateComment,
} from "../store/commentsSlice";
import {
  addNotification,
  increaseUnReadCount,
  updateUnreadCount,
} from "../store/notificationSlice";
import { setFallbackByProjectId, setSectionList } from "../store/projectSlice";
import { selectUserDetails } from "../store/userDetailsSlice";
import { ProjectFallbackType } from "../components/Section/editor.enum";
import { SectionSocketEvent } from "../enums/Enums";
import { SectionService } from "../services/section.service";
import { ProjectService } from "../services/project.service";

const projectService = new ProjectService();

const useSocket = () => {
  const userDetails = useSelector(selectUserDetails);
  const dispatch = useDispatch();

  const socketNotificationRef = useRef<any>();
  const socketCommentRef = useRef<any>();
  const socketProjectRef = useRef<any>();
  const socketSectionRef = useRef<any>();
  const getProjectSectionList = useCallback(
    async (projectId: string) => {
      try {
        const res = await projectService.fetchProjectSectionList(projectId);
        let list = SectionService.formatSectionList(res?.data?.sections || []);
        dispatch(setSectionList({ id: projectId ?? "", list: list }));
      } catch (error) {
        console.error(error);
      }
    },
    [dispatch]
  );
  useEffect(() => {
    if (!userDetails?.id) {
      socketNotificationRef.current?.disconnect();
      socketCommentRef.current?.disconnect();
    }

    socketNotificationRef.current = (connect as any)(
      `${process.env.REACT_APP_BASE_URL}notifications`,
      {
        extraHeaders: {
          "x-user-id": userDetails?.id,
        },
      }
    );
    socketNotificationRef.current.on("get-notification", (data: any) => {
      dispatch(addNotification([data]));
      dispatch(increaseUnReadCount());
    });
    socketNotificationRef.current.on(
      "unread-count-notification",
      (data: any) => {
        dispatch(updateUnreadCount(data.count));
      }
    );
    socketCommentRef.current = (connect as any)(
      `${process.env.REACT_APP_BASE_URL}comments`,
      {
        extraHeaders: {
          "x-user-id": userDetails?.id,
        },
      }
    );
    socketCommentRef.current.on("add-comment", (data: any) => {
      dispatch(addComment({ comment: data }));
    });
    socketCommentRef.current.on("remove-comment", (data: any) => {
      dispatch(emitDeleteComment(data.commentId));
    });
    socketCommentRef.current.on("update-comment", (data: any) => {
      dispatch(updateComment({ comment: data }));
    });
    socketProjectRef.current = (connect as any)(
      `${process.env.REACT_APP_BASE_URL}projects`,
      {
        extraHeaders: {
          "x-user-id": userDetails?.id,
        },
      }
    );
    socketProjectRef.current.on("remove-user-from-project", (data: any) => {
      if (data.userId === userDetails.id) {
        dispatch(
          setFallbackByProjectId({
            show: true,
            type: ProjectFallbackType.USER_REMOVED_FROM_PROJECT,
            projectId: data.projectId,
          })
        );
      }
    });

    socketProjectRef.current.on("update-project-user-role", (data: any) => {
      if (data.userId === userDetails.id) {
        dispatch(
          setFallbackByProjectId({
            show: true,
            type: ProjectFallbackType.USER_ROLE_UPDATE,
            projectId: data.projectId,
          })
        );
      }
    });

    socketSectionRef.current = (connect as any)(
      `${process.env.REACT_APP_BASE_URL}section`,
      {
        extraHeaders: {
          "x-user-id": userDetails?.id,
        },
      }
    );

    socketSectionRef.current.on(
      SectionSocketEvent.REMOVE_SECTION,
      (data: any) => {
        if (userDetails?.id !== data?.userId) {
          dispatch(
            setFallbackByProjectId({
              show: true,
              type: ProjectFallbackType.SECTION_REMOVED,
              projectId: data.projectId,
              sectionId: data.sectionId,
            })
          );
        }
      }
    );
    socketSectionRef.current.on(SectionSocketEvent.ADD_SECTION, (data: any) => {
      if (userDetails?.id !== data?.userId) {
        getProjectSectionList(data.projectId);
      }
    });

    socketSectionRef.current.on(
      SectionSocketEvent.UPDATE_SECTION,
      (data: any) => {
        if (userDetails?.id !== data?.userId) {
          getProjectSectionList(data.projectId);
        }
      }
    );
    return () => {
      socketNotificationRef.current?.disconnect();
      socketCommentRef.current?.disconnect();
      socketProjectRef.current?.disconnect();
      socketSectionRef.current?.disconnect();
    };
  }, [userDetails?.id, dispatch]);

  return null;
};
export default useSocket;
