import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import SettingIcon from "../../../../assets/images/profile-add.svg";
import UserImage from "../../../../assets/images/profile-img.svg";
import { ProjectUserRoleEnum } from "../../../../enums/storageEnums";
import { SectionModel } from "../../../../models/project_section.model";
import { SectionService } from "../../../../services/section.service";
import { getProjectById } from "../../../../store/projectSlice";
import {
  getSectionAssignees,
  setSectionAssignees,
} from "../../../../store/sectionSlice";
import { ProjectSectionStatus } from "../../editor.enum";
import Editor from "../Editor/Editor";
import InfoBlock from "../InfoBlock/InfoBlock";
import SectionManagementModal from "../SectionManagement/SectionManagementModal";
import style from "./SectionEditor.module.scss";
const sectionService = new SectionService();
const SectionEditor = ({
  section,
  level,
  handleText,
  color,
  updateSection,
}: {
  section: SectionModel;
  level: number;
  handleText: (id: string, text: string) => void;
  color: string;
  updateSection: (
    projectId: string,
    sectionId: string,
    parentId: string
  ) => void;
}) => {
  const [openSectionManagement, setOpenSectionManagement] = useState(false);
  const { id, sectionId } = useParams();
  const project = useSelector((state: any) => getProjectById(state, id ?? ""));
  const dispatch = useDispatch();
  const sectionAssignees = useSelector((state: any) =>
    getSectionAssignees(state, section.id ?? "")
  );
  const [statusUpdating, setStatusUpdating] = useState(false);
  const sectionName = useMemo(() => {
    if (section?.name) {
      let nameArray = section?.name.split(" ");
      let sectionNumber = nameArray.shift();
      return `${sectionNumber} - ${nameArray.join(" ")}`;
    }
    return "";
  }, [section]);

  const fetchSectionAssignee = useCallback(
    async (id: string, sectionId: string) => {
      try {
        const users: any = await sectionService.getSectionAssignee(
          id,
          sectionId
        );
        dispatch(
          setSectionAssignees({
            sectionId: sectionId,
            assignees: users.data.sectionUsers,
          })
        );
      } catch (error) {}
    },
    []
  );
  const updateSectionStatus = async (sId: string) => {
    if (
      !id ||
      !sId ||
      section.status === ProjectSectionStatus.IN_PROGRESS ||
      statusUpdating
    ) {
      return;
    }
    try {
      setStatusUpdating(true);
      const payload = {
        status: ProjectSectionStatus.IN_PROGRESS,
      };
      await sectionService.updateSectionStatus(id, sId, payload);
      await updateSection(id, sId, sectionId ?? "");
      setStatusUpdating(false);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (!id || level === 1) {
      return;
    }
    fetchSectionAssignee(id, section.id);
  }, [fetchSectionAssignee, id, section]);

  return (
    <div className={style.sectionEditor}>
      <h3 className={`${level == 1 && style.level_1}`}>
        <span>{section?.isAppendix ? section.name : sectionName}</span>
        <picture>
          {sectionAssignees.map((assignee) =>
            assignee?.image ? (
              <img src={assignee?.image} alt="" />
            ) : (
              <img src={UserImage} alt="" />
            )
          )}
        </picture>
        {project.role === ProjectUserRoleEnum.OWNER && (
          <img
            src={SettingIcon}
            alt="Setting"
            onClick={async () => {
              setOpenSectionManagement(true);
              if (id && sectionId) {
                await updateSection(id, section.id, sectionId);
              }
            }}
          />
        )}
      </h3>

      {section?.instruction && <InfoBlock template={section?.instruction} />}

      {openSectionManagement && (
        <SectionManagementModal
          open={openSectionManagement}
          onClose={() => {
            setOpenSectionManagement(false);
            if (id && sectionId) {
              updateSection(id, section.id, sectionId);
            }
          }}
          section={section}
        />
      )}
      <Editor
        handleText={handleText}
        id={section.id}
        template={section.template}
        color={color}
        handleStatusUpdate={updateSectionStatus}
      />

      {section.children.map((child) => {
        return (
          <SectionEditor
            section={child}
            level={level + 1}
            key={child.id}
            handleText={handleText}
            color={color}
            updateSection={updateSection}
          />
        );
      })}
    </div>
  );
};
export default SectionEditor;
