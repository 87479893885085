import logo from "../../assets/images/logo.svg";
import style from "./Header.module.scss";

const UnAuthHeader = () => {
  return (
    <>
      <header className={`${style.header} flex align-center justify-between`}>
        <a className="logo">
          <img src={logo} alt="Bonzai" />
        </a>
      </header>
      <div className={style.header__holder}></div>
    </>
  );
};

export default UnAuthHeader;
