import React, { useState } from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { ValidationService } from "../../services/validationService";
import { projectUserRolesList } from "../Constants/dropdown.constant";
import style from "./TagInput.module.scss";

interface AppState {
  items: { tag: string; dropdownValue: string }[];
  value: string;
  error: string | null;
}

interface TagInputProps {
  name: string;
  control: any;
  onTagsChange: any;
}

const TagInput: React.FC<TagInputProps> = ({ name, control, onTagsChange }) => {
  const [state, setState] = useState<AppState>({
    items: [],
    value: "",
    error: null,
  });

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (["Enter", "Tab", ",", " "].includes(evt.key)) {
      evt.preventDefault();

      const trimmedValue = state.value.trim();
      if (trimmedValue && isValid(trimmedValue)) {
        const updatedTags = [
          ...state.items,
          { tag: state.value, dropdownValue: "" },
        ];
        onTagsChange(updatedTags);
        setState({
          ...state,
          items: updatedTags,
          value: "",
        });
      }
    }
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      ...state,
      value: evt.target.value,
      error: null,
    });
  };

  const handleDelete = (tag: string) => {
    const updatedItems = state.items.filter((item) => item.tag !== tag);
    setState({
      ...state,
      items: updatedItems,
    });
    onTagsChange(updatedItems);
  };

  const handlePaste = (evt: React.ClipboardEvent<HTMLInputElement>) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text");
    const tags = paste.match(/[^\s,]+@[^\s,]+/g);

    if (tags) {
      const toBeAdded = tags
        .filter((tag) => !isInList(tag) && ValidationService.isEmail(tag))
        .map((tag) => ({ tag, dropdownValue: "" }));

      setState({
        ...state,
        items: [...state.items, ...toBeAdded],
      });
      onTagsChange([...state.items, ...toBeAdded]);
    }
  };

  const isValid = (email: string): boolean => {
    let error = null;

    if (isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!ValidationService.isEmail(email)) {
      error = `Please enter a valid email address`;
    }

    if (error) {
      setState({ ...state, error });
      return false;
    }

    return true;
  };

  const isInList = (tag: string): boolean => {
    return state.items.some((item) => item.tag === tag);
  };

  const handleDropdownChange = (tag: string, dropdownValue: string) => {
    const updatedItems = state.items.map((item) =>
      item.tag === tag ? { ...item, dropdownValue } : item
    );
    onTagsChange(updatedItems);
    setState({
      ...state,
      items: updatedItems,
    });
  };

  return (
    <div className={style.tagInput}>
      <div className={style.tagInput__field}>
        {state.items.map((item, index) => (
          <div className={style.tagInput__button} key={index}>
            <cite>{item.tag}</cite>
            <span onClick={() => handleDelete(item.tag)}>&times;</span>
          </div>
        ))}
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <>
              <input
                className={"input" + (state.error ? " has-error" : "")}
                value={state.value}
                placeholder="Invite by email"
                onKeyDown={handleKeyDown}
                onChange={(e) => {
                  handleChange(e);
                  field.onChange(e);
                }}
                onPaste={handlePaste}
              />
            </>
          )}
        />
      </div>
      {state.error && (
        <span className={style.tagInput__error}>{state.error}</span>
      )}
      <>
        <div className={style.tagInput__list}>
          {state.items.length !== 0 && <h4>People with access</h4>}
          {state.items.map((item, index) => (
            <div
              className={`${style.tagInput__item} flex align-center justify-between`}
              key={index}
            >
              <div className={`${style.tagInput__email}`}>{item.tag}</div>
              <div className={style.tagInput__access}>
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select
                        isSearchable={false}
                        className={"select-dropdown isSimple"}
                        defaultValue={projectUserRolesList[0]}
                        menuPortalTarget={document.body}
                        placeholder={"Select Role"}
                        classNamePrefix={`select-menu`}
                        options={projectUserRolesList}
                        onChange={(selectedOption) =>
                          handleDropdownChange(
                            item.tag,
                            selectedOption?.label || ""
                          )
                        }
                      />
                    );
                  }}
                ></Controller>
              </div>
            </div>
          ))}
        </div>
      </>
    </div>
  );
};

export default TagInput;
