import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import CloseIcon from "../../../../assets/images/close.svg";
import uploadIcon from "../../../../assets/images/upload.svg";
import Button from "../../../../atoms/Button/Button";
import { ALLOWED_IMAGE_TYPE } from "../../../../constants/constants";
import { MediaType } from "../../../../enums/Enums";
import { ApiService } from "../../../../services/apiServices";
import { UploadFileService } from "../../../../services/uploadFileService";
import SkeletonLoader from "../../../Skeleton/Skeleton";
import style from "./AddImageModal.module.scss";
import { ImageUploadType } from "./enum";

const apiService = new ApiService();
const uploadFileService = new UploadFileService();

const AddImageModal = ({
  onAddImage,
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onAddImage: ({
    src,
    height,
    width,
  }: {
    src: string;
    height: number;
    width: number;
  }) => void;
  updateAttributes: any;
}) => {
  const isMac = navigator.platform.indexOf("Mac") > -1;
  const [imageUrl, setImageUrl] = useState("");
  const [showImageError, setShowImageError] = useState(false);
  const [uploadChoice, setUploadChoice] = useState<ImageUploadType>(
    ImageUploadType.STORAGE
  );
  const [fileUploading, setFileUploading] = useState(false);
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [loading, setLoading] = useState(false);
  const { sectionId } = useParams();

  const onFileSelction = useCallback(
    async (file?: File | null) => {
      if (file && !fileUploading) {
        try {
          setFileUploading(true);
          const res = await apiService.post({
            url: "media/signed-url",
            data: {
              fileName: file.name,
              contentType: file.type,
              resourceId: sectionId,
              category: MediaType.SECTION_IMAGE,
            },
          });
          await uploadFileService.uploadFile(res?.data?.uploadUrl, file);
          setFileUploading(false);
          setImageUrl(res?.data?.contentUrl);
        } catch (error) {
          console.log(error);
          setFileUploading(false);
        }
      }
    },
    [fileUploading, setFileUploading, setFileUploading]
  );

  useEffect(() => {
    function pasteFunction(e: any) {
      if (e?.clipboardData?.files) {
        e.preventDefault();
      }
      if (
        e.clipboardData.files.length > 0 &&
        ALLOWED_IMAGE_TYPE.includes(e?.clipboardData?.files[0]?.type)
      ) {
        setUploadChoice(ImageUploadType.STORAGE);
        onFileSelction(e.clipboardData.files[0]);
      }
    }
    if (uploadChoice === ImageUploadType.URL) {
      window.removeEventListener("paste", pasteFunction);
    } else {
      window.addEventListener("paste", pasteFunction);
    }

    return () => {
      window.removeEventListener("paste", pasteFunction);
    };
  }, [fileUploading, uploadChoice]);

  return (
    <div className={style.addImage}>
      <div className={style.addImage__outer}>
        {((!imageUrl && uploadChoice === ImageUploadType.STORAGE) ||
          uploadChoice === ImageUploadType.URL) &&
          !fileUploading && (
            <div className={`${style.addImage__tab} flex`}>
              <button
                className={
                  uploadChoice === ImageUploadType.STORAGE ? style.active : ""
                }
                onClick={() => {
                  if (
                    fileUploading ||
                    uploadChoice === ImageUploadType.STORAGE
                  ) {
                    return;
                  }
                  setUploadChoice(ImageUploadType.STORAGE);
                  setImageUrl("");
                }}
              >
                Upload from Storage
              </button>
              <button
                className={
                  uploadChoice === ImageUploadType.URL ? style.active : ""
                }
                onClick={() => {
                  if (fileUploading || uploadChoice === ImageUploadType.URL) {
                    return;
                  }
                  setUploadChoice(ImageUploadType.URL);
                  setImageUrl("");
                }}
              >
                Upload from URL
              </button>
            </div>
          )}

        <div className={style.addImage__content}>
          {uploadChoice === ImageUploadType.STORAGE &&
            !imageUrl &&
            !fileUploading && (
              <>
                <div
                  className={`${style.addImage__upload} ${
                    imageUrl && style.isUploaded
                  } flex flex-col align-center justify-center`}
                >
                  {!imageUrl && (
                    <>
                      <img src={uploadIcon} alt="upload" />
                      <span className={`${style.isInfo} ${style.isDesktop}`}>
                        {isMac ? "⌘+V" : "Ctrl+V"} to paste image or upload from
                        local storage
                      </span>
                      <span className={`${style.isInfo} ${style.isMobile}`}>
                        Upload from local storage
                      </span>
                      {/* <div className={style.addImage__info}>
                        The maximum size per file is 5 MB.
                      </div> */}
                      <input
                        type="file"
                        accept={ALLOWED_IMAGE_TYPE.join(",")}
                        id={"image_file_input"}
                        onDrop={(e) => e.preventDefault()}
                        onChange={(e) => onFileSelction(e?.target?.files?.[0])}
                      />
                    </>
                  )}
                </div>
              </>
            )}

          {uploadChoice === ImageUploadType.URL && (
            <div className={`${style.addImage__input}`}>
              <input
                type="text"
                value={imageUrl}
                placeholder="https://example.com/example-image"
                onChange={(e) => {
                  if (showImageError) {
                    setShowImageError(false);
                  }
                  if (invalidUrl) {
                    setInvalidUrl(false);
                  }
                  setImageUrl(e.target.value.trim());
                }}
              />
              {invalidUrl && imageUrl && (
                <div className={style.addImage__error}>
                  Please enter a valid image URL
                </div>
              )}
            </div>
          )}

          {imageUrl &&
            uploadChoice === ImageUploadType.STORAGE &&
            !fileUploading && (
              <div className={style.addImage__image}>
                <picture>
                  <img src={imageUrl} alt="preview" />

                  <button
                    className={style.addImage__cancel}
                    onClick={() => setImageUrl("")}
                    disabled={fileUploading}
                  >
                    <img src={CloseIcon} alt="X" />
                  </button>
                </picture>
              </div>
            )}
          {fileUploading && uploadChoice === ImageUploadType.STORAGE && (
            <div className="flex justify-center">
              <SkeletonLoader width="300px" height="200px" margin={"15px 0"} />
            </div>
          )}
          {showImageError && !imageUrl && !fileUploading && (
            <div
              className={`${style.addImage__error} ${
                uploadChoice === ImageUploadType.STORAGE && style.isCenter
              }`}
            >
              This field is required
            </div>
          )}

          <div className={`${style.addImage__footer} flex justify-end`}>
            <Button
              label="Upload"
              buttonClass="sm"
              onClick={() => {
                if (imageUrl) {
                  const img = document.createElement("img");
                  setLoading(true);
                  img.addEventListener("load", () => {
                    onAddImage({
                      src: imageUrl,
                      height: img.height,
                      width: img.width,
                    });
                    img.remove();
                    setLoading(false);
                  });
                  img.addEventListener("error", () => {
                    img.remove();
                    setTimeout(() => {
                      setInvalidUrl(true);
                      setLoading(false);
                    }, 500);
                  });
                  img.src = imageUrl;
                } else {
                  setShowImageError(true);
                }
              }}
              disabled={fileUploading}
              loading={loading}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddImageModal;
