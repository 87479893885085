import TeamMembers from "../../../../pages/TeamMembers/TeamMembers";
import { RightSidebarTabsEnum } from "../../../../enums/Enums";
import RightSideBar from "../../../RightSideBar/RightSideBar";
import {useEffect, useState } from "react";
import Comments from "../../../../pages/Comments/Comments";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";

const SectionSideBar = () => {
  const [selectedTab, setSelectedTab] = useState<string>("");
  const {sectionId}=useParams();
  const [searchParams] = useSearchParams();
  useEffect(() => {
    if (searchParams.get("commentId")) {
      setSelectedTab(RightSidebarTabsEnum.COMMENTS);
    }
  }, [searchParams]);
  const handleTabClick = (tabName: string) => {
    setSelectedTab(tabName);
  };
  useEffect(() => {
    const el = document.getElementsByTagName("body")[0];
    if (selectedTab) {
      el?.classList.add("rightSideBarOpen");
    }
    return () => {
      el?.classList.remove("rightSideBarOpen");
    };
  }, [selectedTab]);


  return (
    <>
      <RightSideBar onTabClick={handleTabClick} />
      <TeamMembers
        show={selectedTab === RightSidebarTabsEnum.TEAM_MEMBERS}
        setSelectedTab={setSelectedTab}
      />
      <Comments
        show={selectedTab === RightSidebarTabsEnum.COMMENTS}
        setSelectedTab={setSelectedTab}
        sectionId={sectionId??''}
        moduleId={''}
      />
    </>
  );
};
export default SectionSideBar;
