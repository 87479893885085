import { useState } from "react";
import DeleteIcon from "../../../../assets/images/delete.svg";
import DuplicateIcon from "../../../../assets/images/duplicate.svg";
import MoveIcon from "../../../../assets/images/move.svg";
import DropdownMenu from "../../../../atoms/DropdownMenu/DropdownMenu";
import style from "../../../../assets/scss/actionMenu.module.scss";

interface DragMenuProps {
  duplicateBlock: () => void;
  deleteBlock: () => void;
  showOption: any;
  handleClick: any;
  setShowOption: any;
}

export const DragMenu = ({
  duplicateBlock,
  deleteBlock,
  showOption,
  setShowOption,
  handleClick,
}: DragMenuProps) => {
  return (
    <>
      {showOption && (
        <DropdownMenu onClose={()=>setShowOption(false)}>
          <div
            className={style.actionMenu__dropwdown}
            onClick={() => setShowOption(false)}
            suppressContentEditableWarning={true}
          >
            <button type="button" onClick={deleteBlock}>
              <span>
                <img className={style.isTransh} src={DeleteIcon} alt="Delete" />
              </span>
              <aside>
                <strong>Delete</strong>
                <p>Remove module</p>
              </aside>
            </button>

            <button type="button" onClick={duplicateBlock}>
              <span>
                <img src={DuplicateIcon} alt="Tick" />
              </span>
              <aside>
                <strong>Duplicate</strong>
                <p>Duplicate module</p>
              </aside>
            </button>
          </div>
        </DropdownMenu>
      )}

      <div
        className={style.actionMenu__move}
        contentEditable={false}
        draggable
        data-drag-handle
        onClick={() => {
          setShowOption(!showOption);
          handleClick();
        }}
      >
        <img src={MoveIcon} alt="Move" />
        {!showOption && (
          <div className={style.actionMenu__info}>
            <p>
              <strong>Drag</strong>
              <span>to move</span>
            </p>
            <p>
              <strong>Click</strong>
              <span>to open menu</span>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

export default DragMenu;
