import { Node, mergeAttributes } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";
import { CustomNodeView } from "./CustomNodeView";
import { checkIsBlockEditable } from "../../../../utils/utils";

export const CustomBlock = Node.create({
  name: "customBlock",

  priority: 1000,

  group: "customBlock",

  content: "(textBlock|table|image|taskListBlock)+",

  draggable: true,

  selectable: false,

  inline: false,

  isolating: true,
  addAttributes() {
    return {
      defaultBlock: { default: false },
      childModule: { default: "textBlock" },
      prefixInstruction: { default: null },
      postfixInstruction: { default: null },
      contenteditable: { default: true },
    };
  },
  addKeyboardShortcuts: () => {
    return {
      "Mod-v": ({editor}) => {
        return checkIsBlockEditable(editor);
      },
       "Mod-Enter": ({editor}) => {
        return checkIsBlockEditable(editor);
      },
      Enter: ({ editor }) => {
      return checkIsBlockEditable(editor);
      },
      Tab: ({ editor }) => {
      return checkIsBlockEditable(editor);
      },
       "Shift-Enter": ({editor}) => {
        return checkIsBlockEditable(editor);
      },
      Space: ({editor}) => {
        return checkIsBlockEditable(editor);
      },
      'Mod-Backspace': ({editor}) => {
        return checkIsBlockEditable(editor);
      },
    };
  },
  addOptions: () => {
    return {
      HTMLAttributes: {},
    };
  },

  parseHTML() {
    return [{ tag: "div" }];
  },

  renderHTML({ HTMLAttributes }) {
    return ["div", mergeAttributes(HTMLAttributes), 0];
  },

  addNodeView() {
    return ReactNodeViewRenderer(CustomNodeView);
  },
});
